import {Component, OnInit} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserService} from './../services/user-service/user.service';
import {throwError, Observable} from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
 
@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
 
  /**
   * An object representing the user for the login form
   */
  public user: any;
 
  // Todo : change accessor userservice
  constructor(
        public _userService: UserService,
        private router: Router,
    ) { }
 
  ngOnInit() {
    this.user = {
      username: '',
      password: ''
    };
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler):Observable<HttpEvent<any>> {

    req = req.clone({ withCredentials: true });

    return next.handle(req);
  }
 
  login() {
    this._userService.login({'username': this.user.username,
                             'password': this.user.password});
    this.router.navigate(['/'], { queryParams: {  }});
  }
 
  refreshToken() {
    this._userService.refreshToken();
  }
 
  logout() {
    this._userService.logout();
  }
 
}