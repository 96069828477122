import { Component, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'app-schoolclasses',
  templateUrl: './schoolclasses.component.html',
  styleUrls: ['./schoolclasses.component.scss']
})
export class SchoolclassesComponent implements OnInit {

  public dataSource: any = {};

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.dataSource = this._dG.createStore(`api/dx-schoolclasses/`,'data');
  }

  onEditorPreparing (e) {
    if (e.dataField == "color" && e.parentType === "dataRow") {
        const defaultValueChangeHandler = e.editorOptions.onValueChanged;
        e.editorName = "dxColorBox"; // Change the editor's type
        e.editorOptions.onValueChanged = function (args) {  // Override the default handler
            // ...
            // Custom commands go here
            // ...
            // If you want to modify the editor value, call the setValue function:
            // e.setValue(newValue);
            // Otherwise, call the default handler:
            defaultValueChangeHandler(args);
        }
    }
}
}
