import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'chunk',
  templateUrl: './chunk.component.html',
  styleUrls: ['./chunk.component.scss']
})
export class ChunkComponent implements OnInit {

  @Input()
  public chunk: any;

  public content: any;

  constructor() { }

  ngOnInit(): void {
    this.content = this.buildContent();
  }

  public buildContent(){
    switch(this.chunk['question_detail_type']){
      case "questions réponses":
        return "question réponse";
      case "textes à trous":
        return "text à trou";
      case "choix multiples":
        return "choix multiples";
      default:
        return this.chunk["content"];
    }
  }
}
