<h1>Liste des élèves</h1>

<p>
    Si vous souhaitez importer plusieurs élèves depuis un fichier <button mat-raised-button routerLink="/schoolapp/upload-data">c'est par ici</button>.
</p>

<crud-table *ngIf="dataSource"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [parentComponent]="this"
            [modelName]="modelName">
</crud-table>