<div class="content-block">
    <h2>
        Liste des messages
    </h2>
    <dx-data-grid
        [dataSource]="studentworks"
        [remoteOperations]="false">

        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxo-paging [pageSize]="10"> </dxo-paging>
        <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[10,20,50,]"
            [showPageSizeSelector]="true"
            [showInfo]="true"
            [showNavigationButtons]="true">
        </dxo-pager>
        
        <dxi-column
            dataField="due_date"
            dataType="date"
            format="dd.MM.yyyy"
            caption="Date">
        </dxi-column>

        <dxi-column
            dataField="user_detail.username"
            dataType="string"
            caption="Élève">
        </dxi-column>

        <dxi-column
            dataField="exercise.full_name"
            dataType="string"
            caption="Exercice">
        </dxi-column>

        <dxi-column
            dataField="exercise.id"
            dataType="string"
            caption="Aperçu"
            cellTemplate="exerciseCellTemplate"
            [allowFiltering]="false"
            width="5%">
        </dxi-column>

        <dxi-column
            dataField="message"
            dataType="string"
            caption="Message"
            width="30%">
        </dxi-column>

        <dxi-column
            dataField="message_is_read"
            dataType="boolean"
            caption="Statut"
            cellTemplate="statusCellTemplate"
            width="10%"
            [filterValue]="false">
            <dxo-lookup
                [dataSource]="statusLookup"
                valueExpr="status"
                displayExpr="name">
            </dxo-lookup>
        </dxi-column>

        <dxi-column
            dataField="id"
            dataType="string"
            caption="Actions"
            cellTemplate="actionsCellTemplate"
            [allowFiltering]="false"
            width="5%">
        </dxi-column>

        <div *dxTemplate="let data of 'exerciseCellTemplate'">
            <mat-icon
                matTooltip="Aperçu"
                class="pointer"
                (click)="openExerciseDialog(data.data.exercise.id)">
                    visibility
            </mat-icon>
            <mat-icon
                aria-hidden="false"
                aria-label="Éditer l'exercice"
                class="pointer"
                [routerLink]="['/admin','questions',data.data.exercise.id]">
                    edit
            </mat-icon>
        </div>

        <div *dxTemplate="let data of 'statusCellTemplate'">
            <ng-container *ngIf="data.data.message_is_read">
                <span matTooltip="Le message a été traité" 
                    class="material-icons success">
                    check_circle_outline
                </span>
            </ng-container>
            <ng-container *ngIf="!data.data.message_is_read">
                <span matTooltip="Le message n'a pas encore été traité" 
                    class="material-icons warning">
                    autorenew
                </span>
            </ng-container>
        </div>

        <div *dxTemplate="let data of 'actionsCellTemplate'">
            <ng-container *ngIf="!data.data.message_is_read">
                <span matTooltip="Passer le message à 'traité'"
                    class="material-icons action success"
                    (click)="finish(data.data.id)">
                    done_outline
                </span>
            </ng-container>
        </div>
    </dx-data-grid>
</div>