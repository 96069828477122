<div class="left-title">
    <h1>Édition d'exercices</h1>
    <h2>Étape 3 - Éditer le détail de la question</h2>
</div>
<div class="right-actions">
    <button
        mat-flat-button
        color="primary"
        [routerLink]="['/admin','questions',questionId,'edit-content']">retour</button>
</div>

<div class="question-container">
    <question
        *ngIf="question"
        [question]="question"
        [admin]="true">
    </question>
</div>