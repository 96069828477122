<div class="mat-elevation-z8">
    
    <mat-form-field *ngIf="showFilter">
        <mat-label>{{ 'Filtrer' }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="">
    </mat-form-field>
    
    <div *ngIf="showCreateButton" class="right" (click)="openCreateDialog()">
        <button mat-raised-button
                disabled
                aria-label="Ouvre un formulaire de création d'objet"
                class="pointer">
          <mat-icon class="create pointer">add</mat-icon>
        </button>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>
        
        <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col.title }}</th>
            <td mat-cell *matCellDef="let row">
                <div [ngSwitch]="col.type">
                    <div *ngSwitchCase="'text'">
                        <span *ngIf="col.callback; else rawValue">
                            {{ this.parentComponent[col.callback](getProperty(row, col.dataBinding)) }}
                        </span>
                        <ng-template #rawValue>
                            {{ getProperty(row, col.dataBinding) }}
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'rich-text'">
                        <div [innerHtml]="getProperty(row, col.dataBinding)">
                        </div>
                    </div>
                    <div *ngSwitchCase="'date'" style="width:100px;">
                        <span *ngIf="col.callback; else rawValue">
                            {{ this.parentComponent[col.callback](getProperty(row, col.dataBinding)) }}
                        </span>
                        <ng-template #rawValue>
                            {{ getProperty(row, col.dataBinding) | date:'dd.MM.yyyy' }}
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'color'">
                        <div style="background-color: {{getProperty(row, col.dataBinding)}};">
                            &nbsp;&nbsp;&nbsp;{{getProperty(row, col.dataBinding)}}&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                    <div *ngSwitchCase="'url'">
                        <span *ngIf="col.callback; else rawValue">
                            <url [url]="getProperty(row, col.name)"
                                 [text]="getProperty(row, col.name)">
                            </url>
                        </span>
                        <ng-template #rawValue>
                            ...
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'r-link'">
                        <r-link [url]="this.parentComponent[col.callback](getProperty(row, col.dataBinding))"
                                [text]="getProperty(row, col.dataBinding)">
                        </r-link>
                    </div>
                    <div *ngSwitchCase="'update-delete'" style="width:100px;">
                        <update-delete [url]="this.parentComponent[col.callback](getProperty(row, col.dataBinding))"
                                       [elemId]="getProperty(row, col.dataBinding)"
                                       [text]="getProperty(row, col.dataBinding)"
                                       [modelName]="modelName"
                                       [parentComponent]="this">
                        </update-delete>
                    </div>
                    <div *ngSwitchDefault>...</div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames;"></tr>
    </table>
</div>