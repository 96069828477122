<h1>Importer des élèves depuis un fichier de données</h1>

<div>
    <label for="file">
        <h3>Choisissez un fichier</h3>
        Les types de fichiers supportés sont : .xls, .xlsx, .csv, .ods, .ods3
        <br>
    </label>
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)">
</div>

<br><br>

<div *ngIf="previewData">
    <file-preview [previewData]="previewData"
                  [dataFile]="fileToUpload"
                  [numberOfColumns]="numberOfColumns"></file-preview>
</div>