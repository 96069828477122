import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api-service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {

  public displayedColumns: any[] = [
    {name: 'name', dataBinding: 'name', title: 'Titre', type: 'text',},
    {name: 'content', dataBinding: 'content', title: 'Contenu', type: 'rich-text',},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ];
  public dataSource: any = new MatTableDataSource();

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getNotes();
  }

  getNotes(){
    this._apiService.getObjectsList('notes/').subscribe(
      data => {
        this.dataSource.data = data;
      }
    )
  }

  refresh(){
    this.getNotes();
  }

  updateDeleteUrl(pk: number){
    return `notes/${pk}`;
  }
}
