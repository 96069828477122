<h1 mat-dialog-title>Ajouter une punition</h1>
<div mat-dialog-content>
  <p>Choisissez un élève</p>
  <mat-form-field class="full-width">
    <mat-select [formControl]="studentCtrl" placeholder="Elève" #singleSelect>
      <!--<mat-option>
        <ngx-mat-select-search [formControl]="studentFilterCtrl"></ngx-mat-select-search>
      </mat-option>-->

      <mat-option *ngFor="let student of filteredStudents | async" [value]="student.id">
        {{student.username}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="full-width">
    <mat-select [formControl]="courseCtrl" placeholder="Cours">
        <mat-option *ngFor="let course of nextCourses" [value]="course.id">
            {{course.schoolclass_detail.name}} - {{days[course.date | date:'EEEE']}} {{course.date | date:'dd-MM-yyyy'}}
          </mat-option>
      </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="full-width">
    <mat-label>Commentaire</mat-label>
    <textarea
      matInput
      placeholder="Punition pour ..."
      [formControl]="commentCtrl"
      (keyup.enter)="save()">
    </textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="">Annuler</button>
  <button mat-raised-button color="accent" cdkFocusInitial (click)="save()">Ok</button>
</div>