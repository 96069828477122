import { Component, Input, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'commentaries-list',
  templateUrl: './commentaries-list.component.html',
  styleUrls: ['./commentaries-list.component.scss']
})
export class CommentariesListComponent implements OnInit {

  @Input()
  public student: any;

  public commentaries: any = {};

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.commentaries = this._dG.createStore(`/api/schoolapp/dx-commentaries/`);
  }

  insertRow(event:any){
    // we add student id to the data sent
    event['data']['student'] = this.student.id;
  }

  updateRow(event:any){
    event['newData']['student'] = this.student.id;
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;
    // Override implementation for the "between" filter operation
    if (selectedFilterOperation === "=") {
        const filterExpression = ["student__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }
}
