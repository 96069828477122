import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DatagridService {

  constructor(private _http: HttpClient,){}
  /**
   * Check if given value is not empty
   * @param value 
   */
  isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== "";
  }

  /**
   * Sort nested array by given key
   * @param array 
   * @param key 
   */
  sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  /**
   * Create default custome store for data binding
   * @param endPoint api request url
   * @param dataPath (optional) nested path to access data e.g. 'results.foo.bar'
   */
  createStore(endPoint: string, dataPath?: string, key?: string){
    const that = this;

    return new CustomStore({
      key:key,
      load: function (loadOptions: any) {
          let params: HttpParams = new HttpParams();
          [
              "skip",
              "take",
              "requireTotalCount",
              "requireGroupCount",
              "sort",
              "filter",
              "totalSummary",
              "group",
              "groupSummary"
          ].forEach(function(i) {
              if (i in loadOptions && that.isNotEmpty(loadOptions[i]))
                  params = params.set(i, JSON.stringify(loadOptions[i]));
          });

          return that._http.get(endPoint, { params: params })
              .toPromise()
              .then((data: any) => {
                if(dataPath){ 
                  return {data: _.get(data, dataPath), // thnx lodash for this feature
                          totalCount: data.totalCount,
                          summary: data.summary,
                          groupCount: data.groupCount }
                }
                return {
                  data: data.data,
                  totalCount: data.totalCount,
                  summary: data.summary,
                  groupCount: data.groupCount
              };
            })
              .catch(error => { throw 'Data Loading Error' });
      },
      insert: (values) => {
        return this._http.post(endPoint, values)
                         .toPromise()
                         .then(data => notify("Donnée insérée avec succès","success"))
                         .catch(error => notify("Une erreur est survenue.","error"));
      },
      update: (obj, values) => {
        return this._http.patch(`${endPoint}${obj.id}/`, values)
                         .toPromise()
                         .then(data => notify("Donnée mise à jour avec succès","success"))
                         .catch(error => notify("Une erreur est survenue.","error"));
      },
      remove: (obj) => {
        return this._http.delete(`${endPoint}${obj.id}/`)
                         .toPromise()
                         .then(data => notify("Donnée supprimée avec succès","success"))
                         .catch(error => notify("Une erreur est survenue.","error"));
      }
    })
  }

    /**
   * Process data grouped on 2 lvls to prepare it for a side-by-side bar chart
   * @param data Array of data
   * @param keyRelevantRange Range of the first group key. e.g. if the 
   * @returns dict with 'data' (processes data) and series (extracted series array)
   */
  processDataForDualGrouping(data: any, keyRelevantRange: number[]){
    let sortedData = this.sortByKey(data, 'key'); // sort data using loadash
    let dataObj    = {};
    let series     = [];

    for(let i=0; i<sortedData.length; i++){
      const keys            = sortedData[i].key.split(':::'); // split keys from groups received from db
      const firstGroupKey   = keys[0]; // the date (week or month group) is the first one
      const secondGroupKey  = keys[1]; // the sector (Arrondissement) is the second one

      if(!(firstGroupKey in dataObj)){
        dataObj[firstGroupKey] = {'firstGroupKey':firstGroupKey.substring(...keyRelevantRange)}; // if the obj doesn't contain the week, let's create it
      } 
      dataObj[firstGroupKey][secondGroupKey] = sortedData[i].count; // push the key:value pair for the given sector in the object

      if(!series.includes(secondGroupKey)){
        series.push(secondGroupKey); // push the sector key in the 'series' obj if not present in it
      }
    }

    return {'data': Object.values(dataObj), 'series':series}; // change dataObj to array, dropping the useless keys
  }
 }
