<div class="content-block">
    <div>
        <dx-data-grid 
            #gridContainer
            id="gridContainer"
            [dataSource]="dataSource"
            [showBorders]="true"
            [remoteOperations]="true"
            [dateSerializationFormat]="'yyyy-MM-dd'">
            <dxo-header-filter 
                [visible]="false">
            </dxo-header-filter>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="true"
                [visible]="true">
            </dxo-pager>
            <dxi-column
                dataField="exercise"
                dataType="object"
                caption="Exercice"
                [allowHeaderFiltering]="false"
                [allowFiltering]="false"
                cellTemplate="exerciseTemplate">
            </dxi-column>
            <dxi-column
                *ngIf="showYearSubject"
                dataField="exercise.yearsubject"
                dataType="string"
                cellTemplate="yearSubjectCellTemplate"
                [calculateFilterExpression]="calculateYearSubjectFilterExpression">
            </dxi-column>
            <dxi-column
                dataField="exercise.theme"
                dataType="string"
                cellTemplate="themeCellTemplate"
                [calculateFilterExpression]="calculateThemeFilterExpression">
            </dxi-column>
            <dxi-column
                dataField="due_date"
                dataType="date"
                format="dd.MM.yyyy à hh:mm"
                caption="Date limite"
                selectedFilterOperation="between"
                [allowHeaderFiltering]="true"
                [allowFiltering]="true"
                [filterValue]="[startDueDate,endDueDate,]">
            </dxi-column>
            <dxi-column
                dataField="status"
                dataType="number"
                caption="Statut"
                [allowHeaderFiltering]="true"
                [allowFiltering]="false"
                cellTemplate="statusTemplate"
                [filterValues]="status">
            </dxi-column>
            <dxi-column
                dataField="id"
                dataType="number"
                caption="Actions"
                [allowHeaderFiltering]="false"
                [allowFiltering]="false"
                cellTemplate="actionsTemplate">

            </dxi-column>

            <div *dxTemplate="let data of 'dueDateTemplate'">
                {{datetimeService.fromTimestampToDate(data.data.due_date)}}
            </div>
            
            <div *dxTemplate="let data of 'exerciseTemplate'">
                {{data.data.exercise.name}} ({{data.data.exercise.yearsubject.name}} - {{data.data.exercise.theme.name}})
            </div>

            <div *dxTemplate="let data of 'statusTemplate'">
                {{statusDetail[data.data.status]}}
            </div>

            <div *dxTemplate="let data of 'yearSubjectCellTemplate'">
                {{ data.data.exercise.yearsubject_details.name }}
            </div>

            <div *dxTemplate="let data of 'themeCellTemplate'">
                {{ data.data.exercise.theme_details.name }}
            </div>

            <div *dxTemplate="let data of 'actionsTemplate'">
                <ng-container *ngIf="data.data.status === 0 || data.data.status === 1; else noStudentwork">
                    <mat-icon aria-hidden="false"
                              class="pointer"
                              aria-label="Lien vers le devoir"
                              matTooltip="Liens vers le devoir"
                              matTooltipPosition="above"
                              [routerLink]="['/exercise',data.data.exercise.id,data.data.id,]">
                        double_arrow
                    </mat-icon>
                </ng-container>

                <ng-template #noStudentwork>
                    <mat-icon aria-hidden="false"
                              class="pointer"
                              aria-label="Lien vers l'exercice"
                              matTooltip="Lien vers l'exercice"
                              matTooltipPosition="above"
                              [routerLink]="['/exercise',data.data.exercise.id,]">
                        double_arrow
                    </mat-icon>
                </ng-template>
            </div>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
        </dx-data-grid>
    </div>
</div>
