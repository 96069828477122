<div *ngIf="!_userService.isAuthenticated()" class="mat-elevation-z8 login">
    <h2>Connexion</h2>
    <form class="form" >
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nom d'utilisateur</mat-label>
            <input matInput type="text" placeholder="Nom d'utilisateur" name="login-username" [(ngModel)]="user.username" md-autofocus>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Mot de passe</mat-label>
            <input matInput type="password" name="login-password" [(ngModel)]="user.password">
        </mat-form-field>
        <button (click)="login()" mat-raised-button color="primary">Log In</button>
    </form>
</div>

<div *ngIf="_userService.isAuthenticated()" class="mat-elevation-z8 login">
    <span>
        You are logged in as <b>{{ _userService.username }}</b>.<br><br>
        Token Expires: {{ _userService.token_expires }}<br><br>
        <button (click)="refreshToken()" mat-raised-button>Refresh Token</button><br><br>
        <button (click)="logout()" mat-raised-button color="primary">Log Out</button>
    </span>
</div>