<div class="content-block">
    <h2>
        Liste des exercices
    </h2>
    <div>
        <dx-data-grid 
            #gridContainer
            id="gridContainer"
            [dataSource]="dataSource"
            [showBorders]="true"
            [remoteOperations]="true"
            (onContentReady)="checkSelectedThemes()">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-paging [pageSize]="10"></dxo-paging>

            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="true"
                [visible]="true">
            </dxo-pager>

            <dxo-editing
                *ngIf="admin"
                [allowUpdating]="true"
                [allowAdding]="true"
                mode="row">
            </dxo-editing>

            <dxi-column
                dataField="yearsubject"
                dataType="object"
                caption="Branche/Année"
                cellTemplate="yearsubjectCellTemplate"
                [setCellValue]="setYearsubjectValue">
                <dxo-lookup
                    [dataSource]="yearsubjects"
                    valueExpr="id"
                    displayExpr="name">
                </dxo-lookup>
                <dxo-header-filter [dataSource]="yearSubjectsFilters"></dxo-header-filter>
            </dxi-column>
            <dxi-column
                dataField="theme"
                dataType="object"
                caption="Thème"
                cellTemplate="themeCellTemplate">
                <dxo-lookup
                    [dataSource]="getFilteredThemes"
                    valueExpr="id"
                    displayExpr="name">
                </dxo-lookup>
                <dxo-header-filter [dataSource]="themesFilters"></dxo-header-filter>
            </dxi-column>
            <!--<dxi-column
                dataField="theme"
                dataType="object"
                caption="Thème"
                cellTemplate="themeCellTemplate"
                [allowHeaderFiltering]="true"
                [allowFiltering]="false">
                <dxo-header-filter
                    [dataSource]="themesFilters"
                    [allowSearch]="true"
                    [width]="600"
                    [height]="600">
                </dxo-header-filter>
            </dxi-column>-->
            <dxi-column
                dataField="name"
                dataType="string"
                caption="NOM"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="statement"
                dataType="string"
                caption="Consigne"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column
                *ngIf="admin"
                dataField="source"
                dataType="string"
                caption="Source">
            </dxi-column>
            <!--<dxi-column
                *ngIf="admin"
                dataField="questions"
                dataType="number"
                caption="Éditer les questions"
                cellTemplate="questionsCellTemplate">
            </dxi-column>-->
            <dxi-column
                *ngIf="showTakeButton"
                dataField="is_given_as_homework_to"
                dataType="string"
                caption="Déjà donné à"
                cellTemplate="givenAsHomeworkCellTemplate"
                [allowHeaderFiltering]="false"
                [allowFiltering]="false"
                [allowSorting]="false">
            </dxi-column>
            <dxi-column
                dataField="id"
                dataType="number"
                caption="Actions"
                [allowHeaderFiltering]="false"
                cellTemplate="actionsTemplate">
            </dxi-column>
            
            <div *dxTemplate="let data of 'actionsTemplate'">
                <mat-icon
                    aria-hidden="false"
                    aria-label="Éditer l'exercice"
                    class="link"
                    [routerLink]="['/admin','questions',data.data.id]">
                        edit
                </mat-icon>
                <mat-icon *ngIf="showOverviewButton"
                          aria-hidden="false"
                          class="link"
                          aria-label="Aperçu de l'exercice"
                          (click)="openDialog(data.data)">
                    visibility
                </mat-icon>
                <mat-icon *ngIf="showLinkButton"
                          aria-hidden="false"
                          class="link"
                          aria-label="Lien vers l'exercice"
                          [routerLink]="['/exercise',data.data.id,]">
                    double_arrow
                </mat-icon>
                <mat-icon *ngIf="showTakeButton"
                          aria-hidden="false"
                          class="link"
                          aria-label="Ajouter l'exercice"
                          (click)="takeExercise(data.data)">
                    add_circle_outline
                </mat-icon>
            </div>

            <div *dxTemplate="let data of 'yearsubjectCellTemplate'">
                <ng-container *ngIf="data.data.yearsubject">
                    {{data.data.yearsubject_details.name}}
                </ng-container>
            </div>

            <div *dxTemplate="let data of 'themeCellTemplate'">
                <ng-container *ngIf="data.data.theme_details">
                    {{data.data.theme_details.name}} - {{data.data.yearsubject_details.name}}
                </ng-container>
            </div>

            <div *dxTemplate="let data of 'questionsCellTemplate'">
                <mat-icon
                    aria-hidden="false"
                    aria-label="Vers les questions"
                    [routerLink]="['/admin','questions',data.data.id]">
                        help
                </mat-icon>
            </div>

            <div *dxTemplate="let data of 'givenAsHomeworkCellTemplate'">
                
                <span *ngFor="let schoolclass of data.data.given_as_homework_to">
                    {{schoolclass}}&nbsp;&nbsp;
                </span>
            </div>
        </dx-data-grid>
    </div>
</div>
