import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatagridService } from '../services/datagrid-service/datagrid.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ExerciseDialogComponent } from '../_dialogs/exercise-dialog/exercise-dialog.component';
import { ApiService } from '../services/api-service/api.service';
import { DxDataGridComponent } from 'devextreme-angular';


@Component({
  selector: 'exercises-list',
  templateUrl: './exercises-list.component.html',
  styleUrls: ['./exercises-list.component.scss']
})
export class ExercisesListComponent implements OnInit {

  @Input()
  public showTakeButton: boolean = false;

  @Input()
  public showLinkButton: boolean = true;

  @Input()
  public showOverviewButton: boolean = false;

  @Input()
  public admin: boolean = false;

  @Output()
  public exerciseTaken = new EventEmitter();

  @ViewChild('gridContainer')
  public gridContainer: DxDataGridComponent;

  public dataSource: any = {};
  public yearSubjectsFilters: any[] = [];
  public themesFilters: any[] = [];
  public selectedThemesFilters: any[];

  public yearsubjects: any[];
  public themes: any[];

  constructor(private _dG: DatagridService,
              private _httpClient: HttpClient,
              private _apiService: ApiService,
              public matDialog: MatDialog,) { }

  ngOnInit(): void {
    this.dataSource = this._dG.createStore(`api/dx-exercises/`,'data');

    // WARNING !!!!! IMPORTANT !!!!! ESSENTIAL TO BE ABLE TO CALL
    // CURRENT COMPONENT FROM WITHIN getFilteredThemes Method !!!
    this.getFilteredThemes = this.getFilteredThemes.bind(this);

    this.loadYearSubjects();
    this.loadThemes();
  }

  /**
   * Return object's name
   * @param obj objects that... has a name property
   */
  public objName(obj){
    return obj.value.name;
  }

 /**
   * Load all yearsubjects from db and push them in yearSubjectsFilters attribute.
   */
  loadYearSubjects(){
    this._httpClient.get(`api/dx-yearsubjects/`).subscribe(
      data => {
        this.yearsubjects = data['data'];
        this.yearSubjectsFilters = [];
        data['data'].forEach(yearSubject => {
          this.yearSubjectsFilters.push(
            {
              text: yearSubject['name'],
              value: ["yearsubject__id", "=", yearSubject['id']]
            },
          )
        });
      }
    )
  }

  /**
   * Necessary for populating themes lookup everytime yearsubject lookup
   * value is changed. Bur really... what a piece of shit
   * @param rowData 
   * @param value 
   */
  setYearsubjectValue(rowData: any, value: any){
    rowData.yearsubject = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  /**
   * Defined datasource for themes header filters
   * @param options 
   */
  getFilteredThemes(options){
    return {
      store: this.themes,
      filter: options.data ? ["yearsubject", "=", options.data.yearsubject] : null
    };
  }

  /**
   * Method called "onContentReady"
   * It means that it's called at the very begining of datagrid instanciating, 
   * and every time the datagrid is modified
   */
  checkSelectedThemes(){
    let filterValues = this.gridContainer.instance.columnOption("yearsubject", "filterValues");

    // if the filterValues has change, we reload themes for themes header filters
    if(this.selectedThemesFilters != filterValues){
      this.selectedThemesFilters = filterValues;
      this.loadThemes();
    }
  }

  /**
   * Build Themes header filters, depending on selected yearsubjects filters
   * (to avoid displaying filters that have nothing to do with selected yearsubject(s))
   */
  buildThemesFilters(){
    let filters = [];
    if(this.selectedThemesFilters){
      this.selectedThemesFilters.forEach(element => {
        filters.push(`["${element[0]}","${element[1]}","${element[2]}"]`);
      });
      return filters.join(`,"or",`);
    }
    return undefined;
  }

  /**
   * Load all yearsubjects from db and push them in yearSubjectsFilters attribute.
   */
  loadThemes(yearsubjectId?: number){
    const filterByYerasubjectIds = this.buildThemesFilters();

    this.themesFilters = [];

    let endpoint = `api/dx-themes-for-exercises/`;

    if(filterByYerasubjectIds){
      endpoint += `?filter=[${filterByYerasubjectIds}]`;
    }

    this._httpClient.get(endpoint).subscribe(
      data => {
        this.themes = data['data'];
        this.themesFilters = [];
        data['data'].forEach(theme => {
          this.themesFilters.push(
            {
              text: `${theme['name']} - ${theme['yearsubject_details'].name}`,
              value: ["theme__id", "=", theme['id']]
            },
          )
        });
      }
    )
  }

  /**
   * Set the way a query is done usine this particular filter (on yearsubject_id param)
   * @param filterValue 
   * @param selectedFilterOperation 
   */
  yearsubjectFilterExpression(filterValue, selectedFilterOperation){
    let column = this as any;

    this.loadThemes(filterValue);

    if (selectedFilterOperation === "=") {
      var filterExpression = ['yearsubject__id', "=", filterValue];

      return filterExpression;
    }
    // Invokes the default filtering behavior
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  /**
   * Set the way a query is done usine this particular filter (on theme_id param)
   * @param filterValue 
   * @param selectedFilterOperation 
   */
  themeFilterExpression(filterValue, selectedFilterOperation){
    let column = this as any;

    if (selectedFilterOperation === "=") {
      var filterExpression = ['theme__id', "=", filterValue];

      return filterExpression;
    }
    // Invokes the default filtering behavior
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  /**
   * Send selected exercise to parent component
   * @param exercise 
   */
  public takeExercise(exercise:any){
    this.exerciseTaken.emit(exercise);
  }

  /**
   * Open a dialog with exercise overview in it
   */
  openDialog(exercise:any): void {
    const dialogRef = this.matDialog.open(ExerciseDialogComponent, {
      width: '100%',
      data: exercise
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
