import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'app-my-exercises-list',
  templateUrl: './my-exercises-list.component.html',
  styleUrls: ['./my-exercises-list.component.scss']
})
export class MyExercisesListComponent implements OnInit {

  public dataSource: any = {};

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.loadExercises();
  }

  loadExercises(){
    this._apiService.getObjectsList(`api/exercises-list/`).subscribe(
      data => {
        this.dataSource = data;
        console.log(this.dataSource);
      }
    );
  }

  removeExercise(exerciseId: number){
    this._apiService.deleteObject(`api/exercises-list/${exerciseId}/`).subscribe(
      data => {
        this.loadExercises();
      }
    )
  }
}
