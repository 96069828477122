<div>
    <ng-container *ngFor="let chunk of question.chunks; let i=index" [ngSwitch]="chunk.question_detail_type">
        <ng-container *ngSwitchCase="'questions réponses'">
            <question-detail-correction
                [exerciseCorrectionId]="exerciseCorrectionId"
                [questionDetail]="chunk">
            </question-detail-correction>
        </ng-container>
        <ng-container *ngSwitchCase="'textes à trous'">
            <question-detail-correction
                [exerciseCorrectionId]="exerciseCorrectionId"
                [questionDetail]="chunk">
            </question-detail-correction>
        </ng-container>
        <ng-container *ngSwitchCase="'choix multiples'">
            <question-detail-correction
                [exerciseCorrectionId]="exerciseCorrectionId"
                [questionDetail]="chunk">
            </question-detail-correction>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span [innerHTML]="chunk.content | safe: 'html'"></span>
        </ng-container>
    </ng-container>
</div>