import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'teams-points-chart',
  templateUrl: './teams-points-chart.component.html',
  styleUrls: ['./teams-points-chart.component.scss']
})
export class TeamsPointsChartComponent implements OnInit {

  @Input()
  public schoolclass: any;

  public teams: any[];
  public chartsData: any[];

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.loadTeams();
  }

  loadTeams(){
    this._apiService.getObjectsList(`/api/schoolapp/dx-competitors-teams/?filter=["schoolclass__id","=","${this.schoolclass.id}"]`).subscribe({
      next: (data:any) => {
        this.teams = data.data;
        this.loadGraphData();
      }
    });
  }

  loadGraphData(){
    this.chartsData = [];

    this._apiService.getObjectsList(`/api/schoolapp/schoolclass-teams-points-for-charts/${this.schoolclass.id}/`).subscribe({
      next: (data:any) => {
        this.chartsData = data.data;
      }
    });    
  }
}
