import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { DatetimeService } from 'src/app/services/datetime.service';

@Component({
  selector: 'app-homeworks',
  templateUrl: './homeworks.component.html',
  styleUrls: ['./homeworks.component.scss']
})
export class HomeworksComponent implements OnInit {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  public dataSource: any;
  public schoolclasses: any[];

  constructor(private _apiService: ApiService,
              private _dG: DatagridService,
              private _snackbar: MatSnackBar,
              public datetimeService: DatetimeService,) { }

  ngOnInit(): void {
    this.dataSource = this._dG.createStore(`api/dx-min-homeworks/`,'data');

    this._apiService.getObjectsList(`api/dx-schoolclasses/`).subscribe(
      data => {
        this.schoolclasses = [];

        data['data'].forEach(schoolclass => {
          this.schoolclasses.push(
            {
              text: schoolclass['name'],
              value: ["schoolclass_id", "=", schoolclass['id']]
            },
          )
        });
      }
    )
  }

  fromTimestampToDate(timestamp:number){
    return new Date(timestamp * 1e3).toLocaleDateString("en-US");
  }

  /**
   * Create a custom filter expression on schoolclass id
   * @param filterValue 
   * @param selectedFilterOperation 
   */
  schoolclassesFilterExpression(filterValue, selectedFilterOperation){
    let column = this as any;

    if (selectedFilterOperation === "=") {
      var filterExpression = ['schoolclass_id', "=", filterValue];

      return filterExpression;
    }

    // Invokes the default filtering behavior
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  /**
   * Delete a Homework instance, using _dG customestore service
   * (so it takes care of datagrid refresh)
   * @param id homeworkId
   */
  deleteHomework(id:number){
    if(confirm("Êtes-vous certain(e) de vouloir supprimer ce devoir ? Cette action est irréversible.")){
      this.dataSource.remove({id:id}); // warning : remove method needs an object in our custom store
    }
  }

  refreshDataSource(event:any){
    this.dataGrid.instance.refresh();
  }

  takeExerciseForExam(exerciseId:number){
    let exercisesForExam = JSON.parse(localStorage.getItem('exercisesForExam'));
    let message = "Exercice ajouté pour un examen";

    if(!exercisesForExam){
      localStorage.setItem('exercisesForExam', JSON.stringify([exerciseId,]));
      console.log(exercisesForExam);
    } else {
      if(!exercisesForExam.includes(exerciseId)){
        exercisesForExam.push(exerciseId);
        localStorage.setItem('exercisesForExam', JSON.stringify(exercisesForExam));
      } else {
        message = "Cet exercice est déjà sélectionné";
      }
    }
    this._snackbar.open(message,"success");
  }
}
