import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import DataSource from "devextreme/data/data_source";
import notify from 'devextreme/ui/notify';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'choices',
  templateUrl: './choices.component.html',
  styleUrls: ['./choices.component.scss']
})
export class ChoicesComponent implements OnInit {

  @Input()
  public questionDetailId: string;

  @Input()
  public showEditChoices: boolean = false;

  @Output()
  public choiceUpdated = new EventEmitter();

  public question;
  public questionDetail;
  public choices: DataSource;
  public store: any;

  constructor(private _apiService: ApiService,
              private _dG: DatagridService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    //this.loadChoices(this.questionDetailId);
    this.store = this._dG.createStore('api/dx-choices/');
    this.choices = new DataSource({
      store: this.store,
      filter:["question_detail__id","=",this.questionDetailId],
    })
    // this.choices = this._dG.createStore(`api/dx-choices/`);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadChoices(changes.questionDetailId.currentValue);
  }

  loadChoices(questionDetailid){
    if(questionDetailid){
      this._apiService.getObjectsList(`api/dx-choices/?filter=["question_detail__id","=","${questionDetailid}"]`).subscribe(
        data => {
          this.choices = data['data'];
        }
      );
    }
  }

  closeChoicesEdition(){
    // this.choices = undefined;
    this.choiceUpdated.emit('close');
  }

  insertQuestionDetailId(event){
    event.data.question_detail = this.questionDetailId;
    this.store.insert(event.data).then(data => this.choiceUpdated.emit(data));
  }

  updateChoice(event){
    const data = {"content":event.data.content}
    this._apiService.patchObject(`api/dx-choices/${event.data.id}/`,data).subscribe(
      data => {
        this.choiceUpdated.emit(data);
        notify("Donnée enregistrée","success");
      },
      err => notify("Une erreur est survenue","error")
    )
  }

  deleteChoice(event){
    this._apiService.deleteObject(`api/dx-choices/${event.data.id}/`).subscribe(
      data => {
        this.choiceUpdated.emit(data);
        notify("Choix supprimé","success");
      },
      err => notify("Une erreur est survenue","error")
    )
  }

  copyChoices(){
    this._apiService.getObjectDetail(`api/copy-choices/${this.questionDetailId}/`).subscribe(
      data => {
        this.choiceUpdated.emit(data);
      }
    )
  }
}
