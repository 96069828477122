<h2>
    Liste des exercices liés à l'étape
</h2>

<mat-card>
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Exercices sélectionnés</mat-label>
        <mat-chip-list #chipList aria-label="Exercices sélectionnés">
            <mat-chip
                *ngFor="let exercise of selectedExercises"
                [selectable]="selectable"
                [removable]="removable">
            {{exercise.name}}
                <mat-icon matChipRemove
                        (click)="removeExercise(exercise.id)">
                    cancel
                </mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>
    <!--<div *ngIf="exerciseHomeworkChecks&&exerciseHomeworkChecks.length>0">
        <mat-card class="warning" *ngFor="let match of exerciseHomeworkChecks">
            Attention : {{match}}
        </mat-card>
    </div>-->
</mat-card>

<exercises-list [showTakeButton]="true"
                [showLinkButton]="false"
                [showOverviewButton]="true"
                (exerciseTaken)="addExercise($event)">
</exercises-list>