<h3>Pré-visualisation des données qui seront importées</h3>

<p>
    <mat-list role="list">
        <mat-list-item role="listitem">Pour chaque colonne, veuillez indiquer de quel type de donnée il s'agit au moyen de la liste déroulante au sommet du tableau.</mat-list-item>
        <mat-list-item role="listitem">Veuillez également indiquer à partir de quelle ligne les données doivent être importée, au moyen des boutons 'radio' sur la gauche du tableau.</mat-list-item>
    </mat-list>
</p>

<table *ngIf="!completeData; else completeTable" class="preview">
    <thead [formGroup]="colForm">
        <tr formArrayName="colNames">
        <th></th>
        <th *ngFor="let head of previewData[0]; let i = index;"
            [attr.data-index]="i">
            <mat-form-field>
                <mat-label>Type de donnée</mat-label>
                <mat-select formControlName="{{i}}" (selectionChange)="change(i)">
                    <mat-option *ngFor="let col of modelColumns" [value]="col[0]">
                    {{col[1]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </th> 
        </tr>
    </thead>
    
    <tr *ngFor="let row of previewData; let i = index;" [attr.data-index]="i">
        <td>
            <mat-radio-button value="i" (click)="checkRadiovalue(i)"></mat-radio-button>
        </td>
        <td *ngFor="let cell of row"
            class="content">
            {{cell}}
        </td>
    </tr>
</table>

<br><br>

<button mat-raised-button color="accent" (click)="save()">Save</button>

<ng-template #completeTable>
    <table>
        <tr *ngFor="let row of completeData">
            <td class="content">
                {{row}}
            </td>
        </tr>
    </table>
</ng-template>