<dx-data-grid
    [dataSource]="parents"
    [remoteOperations]="true"
    (onRowInserting)="insertRow($event)"
    (onRowUpdating)="updateRow($event)">
    <dxo-editing
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="true">
    </dxo-editing>

    <dxi-column
        dataField="name"
        dataType="string">
    </dxi-column>
    <dxi-column
        dataField="is_legal_representative"
        dataType="boolean"
        caption="Représentant légal">
    </dxi-column>
    <dxi-column
        dataField="student"
        dataType="number"
        [width]="0"
        [filterValue]="student.id"
        [calculateFilterExpression]="calculateFilterExpression">
    </dxi-column>
    <dxi-column
        dataField="id"
        dataType="number"
        caption="Moyens de communication"
        cellTemplate="communicationMediumsCellTemplate">
    </dxi-column>

    <div *dxTemplate="let data of 'communicationMediumsCellTemplate'">
        <dx-button
            icon="info"
            text="Voir"
            (click)="showPopup(data.data)">
        </dx-button>
    </div>
</dx-data-grid>

<dx-popup
    *ngIf="selectedParent"
    width="80%"
    height="80vh"
    [showTitle]="true"
    title="Moyens de communication du parent {{selectedParent.name}}"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view
            #scrollView
            id="scrollview"
            [showScrollbar]="true">
            <dx-data-grid
                [dataSource]="parentCommunicationMediums"
                [remoteOperations]="true"
                (onRowInserting)="insertCommunicationMedium($event)"
                (onRowUpdating)="updateCommunicationMedium($event)">
                <dxo-editing
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true">
                </dxo-editing>

                <dxi-column
                    dataField="value"
                    dataType="string">
                </dxi-column>
                <dxi-column
                    dataField="parent"
                    dataType="number"
                    [filterValue]="selectedParent.id"
                    [allowEditing]="false"
                    [width]="0">
                </dxi-column>
            </dx-data-grid>
        </dx-scroll-view>
    </div>
</dx-popup>