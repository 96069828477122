import { Component, OnInit, Input, enableProdMode, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'sanctions-list',
  templateUrl: './sanctions-list.component.html',
  styleUrls: ['./sanctions-list.component.scss']
})
export class SanctionsListComponent implements OnInit {

  @Input()
  public coursePk: number;

  @Input()
  public filtering: boolean = false;

  @Input()
  public showDetail: boolean = false;

  @ViewChild(MatSort, {static: true})
  public sort: MatSort;

  public getFinishedSanctionsForm: FormGroup;

  public displayedColumns: string[] = ['student', 'comment', 'status', 'postponed', 'actions',];
  public dataSource: any = new MatTableDataSource();

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if(this.showDetail){
      this.displayedColumns = ['date', 'course', 'student', 'comment', 'status', 'postponed', 'actions',];
    }

    this.getFinishedSanctionsForm = new FormGroup({
      'finishedSanctions': new FormControl(false)
    });

    this.getSanctions();
    this.onChanges();
  }

  getSanctions(getFinishedSanctions: boolean = false){
    let endPoint: string;

    if(this.coursePk){
      endPoint = `/api/schoolapp/sanctions/?c=${this.coursePk}`;

      if(getFinishedSanctions){
        endPoint = `${endPoint}&s=1`;
      }

    } else {
      endPoint = '/api/schoolapp/sanctions/';

      if(getFinishedSanctions){
        endPoint = '/api/schoolapp/sanctions/?s=1';
      }
    }

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        this.dataSource.data = data;

        /** Add children objects to the sorting */
        this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        this.dataSource.sort = this.sort;

        /** Add children objects to the filtering */
        this.dataSource.filterPredicate = (data: any, filter) => {
          const dataStr = JSON.stringify(data).toLowerCase();
          return dataStr.indexOf(filter) != -1;
        }
      }
    )
  }

  delete(pk:number){
    this._apiService.deleteObject(`/api/schoolapp/sanctions/${pk}/`).subscribe(
      data => {
        this.getSanctions();
        this._snackBar.open('La punition a été supprimée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.')
    )
  }

  finish(pk:number){
    this._apiService.patchObject(`/api/schoolapp/sanctions/${pk}/`, {"status":3}).subscribe(
      data => {
        this.getSanctions();
        this._snackBar.open('La punition a été passée à terminée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  postpone(coursePk:number, pk:number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?c=${coursePk}`).subscribe(
      data => {
        this._apiService.patchObject(`/api/schoolapp/sanctions/${pk}/`,
                                     {"course":data['id'],
                                      "postponed":true}).subscribe(
          d => {
            this.getSanctions();
            this._snackBar.open('La punition a été repoussée au prochain cours.', 'Fermer');
          },
          error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
        )
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  /* Apply filter to data table */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProperty(obj, property){
    /* Uses lodash magic power. Nearly the same as getattr() python native method. */
    return _.get(obj, property);
  }

  onChanges(): void {
    this.getFinishedSanctionsForm.get('finishedSanctions').valueChanges.subscribe(val => {
      this.getSanctions(val);
    });
  }

  refresh(){

  }
}