import { compileDeclareClassMetadata, ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { TeamPointsListComponent } from '../team-points-list/team-points-list.component';
import { TeamsPointsChartComponent } from '../teams-points-chart/teams-points-chart.component';

@Component({
  selector: 'competitors-teams-list',
  templateUrl: './competitors-teams-list.component.html',
  styleUrls: ['./competitors-teams-list.component.scss']
})
export class CompetitorsTeamsListComponent implements OnInit {

  @ViewChild(TeamsPointsChartComponent)
  public teamPointsCharts: TeamsPointsChartComponent;

  @ViewChild(TeamPointsListComponent)
  public teamPointsList: TeamPointsListComponent;

  @Input()
  public schoolclass: any;

  public competitorsTeams: any = {};
  public noTeamCompetitors: any[] = [];

  public competitorsTeamsList: any[] = [];
  public selectedTeam: any = undefined;
  public teamPointsTitle: string = '';
  public teamPopupVisible: boolean = false;

  constructor(private _apiService: ApiService,
              private _dG: DatagridService,) {
    this.onAdd = this.onAdd.bind(this);
  }

  ngOnInit(): void {
    this.loadNoTeamCompetitors();
    this.loadCompetitorsLists();

    this.competitorsTeams = this._dG.createStore(`/api/schoolapp/dx-competitors-teams/`);
  }

  loadCompetitorsLists(){
    this._apiService.getObjectsList(`/api/schoolapp/dx-competitors-teams/?filter=["schoolclass__id","=","${this.schoolclass.id}"]`).subscribe({
      next: (data:any) => {
        this.competitorsTeamsList = data.data;
        console.log('LISTE DE COMPETITORS TEAMS :', this.competitorsTeamsList);
      }
    });
  }

  loadNoTeamCompetitors(){
    this._apiService.getObjectsList(`/api/schoolapp/no-team-competitors/${this.schoolclass.id}/`).subscribe({
      next: (data:any) => {
        this.noTeamCompetitors = data;
        console.log('NO TEAM COMPETITORS :', data);
      }
    })
  }

  insertRow(event:any){
    // we add student id to the data sent
    event['data']['schoolclass'] = this.schoolclass.id;
    this.loadCompetitorsLists();
    this.loadNoTeamCompetitors();
  }

  reloadCompetitorsAndLists(event:any){
    this.loadCompetitorsLists();
    this.loadNoTeamCompetitors();
    this.teamPointsCharts.loadTeams();
  }
  
  updateRow(event:any){
    event['newData']['schoolclass'] = this.schoolclass.id;
  }

  removedRow(event:any){
    this.loadCompetitorsLists();
    this.loadNoTeamCompetitors();
    this.teamPointsCharts.loadTeams();
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;
    // Override implementation for the "between" filter operation
    if (selectedFilterOperation === "=") {
        const filterExpression = ["schoolclass__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  extractTeamId(rawId:string){
    return parseInt(rawId.substring(4));
  }

  addCompetitorToTeam(competitorId:number, teamId:number){
    const data = {};
    this._apiService.createObject(`/api/schoolapp/competitor-to-team/${competitorId}/${teamId}/`,{}).subscribe({
      next: (data:any) => {
        console.log(data);
        this.loadCompetitorsLists();
        this.loadNoTeamCompetitors();
      }
    });
  }

  removeCompetitorFromAllTeams(competitorId:number, teamId:number){
    this._apiService.deleteObject(`/api/schoolapp/competitor-to-team/${competitorId}/${teamId}/`).subscribe({
      next: (data:any) => {
        console.log(data);
        this.loadCompetitorsLists();
        this.loadNoTeamCompetitors();
      }
    })
  }

  onDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
    //console.log('ON START :', e.itemData);
  }

  onAdd(e) {
    e.toData.splice(e.toIndex, 0, e.itemData);

    let competitorId = undefined;

    if(isNaN(e.itemData)){
      competitorId = e.itemData.id;
    } else {
      competitorId = e.itemData;
    }

    const oldTeamId = this.extractTeamId(e.fromComponent.element().id);
    const teamId = this.extractTeamId(e.toComponent.element().id);
    const schoolclassId = this.schoolclass.id;
    
    // console.log('ADD COMPETITOR :', e.itemData);
    // console.log('ADD COMPETITOR ID :', e.itemData.id);
    // console.log('ADD FROM TEAM ID:', this.extractTeamId(e.fromComponent.element().id));
    // console.log('ADD TO TEAM ID:', this.extractTeamId(e.toComponent.element().id));

    if(teamId == 0) {
      this.removeCompetitorFromAllTeams(competitorId, oldTeamId);
    } else {
      this.addCompetitorToTeam(competitorId, teamId);
    }
  }

  onRemove(e) {
    e.fromData.splice(e.fromIndex, 1);
    //console.log('ON REMOVE :', e);
  }

  showTeamPoints(team:any){
    this.selectedTeam = team;
    this.teamPointsTitle = `Liste des points pour ${this.selectedTeam.name}`;
    console.log('SELECTED TEAM :', this.selectedTeam);
    this.teamPopupVisible = true;
  }

  onEditorPreparing (e) {
    if (e.dataField == "color" && e.parentType === "dataRow") {
        const defaultValueChangeHandler = e.editorOptions.onValueChanged;
        e.editorName = "dxColorBox";
        e.editorOptions.onValueChanged = function (args) {
            defaultValueChangeHandler(args);
        }
    }
  }

  refreshPointsGraph(event:any){
    this.teamPointsCharts.loadGraphData();
  }
}
