<dx-data-grid 
    #gridContainer
    id="gridContainer"
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="true"
    [columnAutoWidth]="true"
    [showColumnLines]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [rowAlternationEnabled]="true">
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <!--<dxi-column
        dataField="id"
        dataType="number"
        caption="id"
        [allowHeaderFiltering]="false">
    </dxi-column>-->
    <!--<dxi-column
        dataField="homework"
        dataType="number"
        caption="Id devoir"
        [filterValue]="homeworkId">
    </dxi-column>-->
    <dxi-column
        dataField="id"
        dataType="number">
    </dxi-column>
    <dxi-column
        dataField="user_detail"
        dataType="obj"
        caption="Élève"
        cellTemplate="studentCellTemplate">
    </dxi-column>
    <!--<dxi-column
        dataField="exercise"
        dataType="obj"
        caption="Exercice"
        cellTemplate="exerciseCellTemplate"
        [allowFiltering]="true">
        <dxo-lookup
            *ngIf="exercises"
            [dataSource]="exercises"
            displayExpr="full_name"
            valueExpr="id"
            [allowClearing]="false">
        </dxo-lookup>
    </dxi-column>-->
    <!--<dxi-column
        dataField="exercise"
        dataType="obj"
        caption="Exercice"
        cellTemplate="exerciseCellTemplate"
        [allowHeaderFiltering]="false"
        [allowFiltering]="true">
        <dxo-lookup
            [dataSource]="exercises"
            displayExpr="full_name"
            valueExpr="id"
            [allowClearing]="true">
        </dxo-lookup>
    </dxi-column>-->
    <dxi-column
        dataField="total_duration"
        dataType="number"
        cellTemplate="durationCellTemplate"
        caption="Temps passé">
    </dxi-column>
    <dxi-column
        dataField="percentages"
        dataType="string"
        caption="% de réponses justes"
        [width]="200">
    </dxi-column>
    <dxi-column
        dataField="difficulty"
        dataType="number"
        caption="Difficulté"
        cellTemplate="difficultyCellTemplate">
    </dxi-column>
    <dxi-column
        dataField="message"
        dataType="string"
        caption="Message"
        [width]="200">
    </dxi-column>
    <dxi-column
        dataField="status"
        dataType="number"
        cellTemplate="statusCellTemplate"
        caption="Statut">
    </dxi-column>
    <dxi-column
        dataField="id"
        dataType="number"
        name="actions"
        cellTemplate="actionsCellTemplate"
        caption="Actions">
    </dxi-column>

    <div *dxTemplate="let data of 'studentCellTemplate'">
        {{ data.data.user_detail.username }}
    </div>

    <div *dxTemplate="let data of 'exerciseCellTemplate'">
        {{ data.data.exercise.full_name }}
    </div>

    <div *dxTemplate="let data of 'durationCellTemplate'">
        {{ dateTimeService.fromSecondsToMinutes(data.data.total_duration) }}
    </div>

    <div *dxTemplate="let data of 'difficultyCellTemplate'">
        <mat-icon 
            *ngFor="let i of [].constructor(data.data.difficulty)"
            aria-hidden="false"
            aria-label="Difficulty">
                star
        </mat-icon>
    </div>

    <div *dxTemplate="let data of 'statusCellTemplate'">
        {{statusDetail[data.data.status]}}
    </div>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <dx-button
            icon="info"
            (click)="showCorrectionsDetails(data.data.id)"></dx-button>
    </div>
</dx-data-grid>


<!--<dx-popup
    [width]="80+'%'"
    [height]="800"
    [showTitle]="true"
    title="Information"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [showCloseButton]="false"
    [(visible)]="popupVisible">
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeButtonOptions">
    </dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view
            *ngIf="popupExercisesCorrections&&popupExercisesCorrections.length>0"
            width="100%"
            height="100%">
            <mat-tab-group>
                <mat-tab
                    *ngFor="let correction of popupExercisesCorrections"
                    backgroundColor="blue"
                    label="First">
                        <exercise-correction
                            [exerciseCorrectionId]="correction">
                        </exercise-correction>
                </mat-tab>
            </mat-tab-group>
        </dx-scroll-view>
    </div>
  </dx-popup>-->