import { Component, Input, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'internships-list',
  templateUrl: './internships-list.component.html',
  styleUrls: ['./internships-list.component.scss']
})
export class InternshipsListComponent implements OnInit {

  @Input()
  public resumeId: number;

  public dataSource: any;

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.loadDatasource();
  }

  loadDatasource(){
    this.dataSource = this._dG.createStore('api/dx-internships/');
  }
}
