import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddSanctionDialogComponent } from '../_dialogs/add-sanction-dialog/add-sanction-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddTaskDialogComponent } from '../_dialogs/add-task-dialog/add-task-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { CreateObjectDialogComponent } from '../_dialogs/create-object-dialog/create-object-dialog.component';
import { StudentCommentsComponent } from '../_dialogs/student-comments/student-comments.component';
import notify from 'devextreme/ui/notify';
import { CompetitorBehaviourCoursePointsListComponent } from '../_partials/competitor-behaviour-course-points-list/competitor-behaviour-course-points-list.component';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss']
})
export class EditCourseComponent implements OnInit {

  @ViewChild(CompetitorBehaviourCoursePointsListComponent)
  public competitorBehaviourCoursePointsList: CompetitorBehaviourCoursePointsListComponent;

  public course: any;
  public courseForm: FormGroup;
  public sanctions: any[];
  public tasks: any[];
  public pk: number;
  public previousPk: number;
  public nextPk: number;
  public students: any[];
  public behaviourPoints: any[];

  public displayedColumns: any[] = [
    {name: 'name', dataBinding: 'name', title: 'Titre', type: 'text',},
    {name: 'type_of', dataBinding: 'type_of', title: 'Type', type: 'text', callback: 'typeOfExam',},
    {name: 'detail', dataBinding: 'id', title: 'Détail', type: 'r-link', callback: 'detailUrl',},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ];

  public dataSource: any = new MatTableDataSource();
  public step: number = -1;

  public showBonusBtn: boolean = false;
  public showRewardBtn: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private _apiService: ApiService,
              private dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit(): void {
    this.pk = this.activatedRoute.snapshot.params.pk;

    this.initForm();
    this.getCourse(this.pk);
    this.getNextCourse(this.pk);
    this.getPreviousCourse(this.pk);
    // this.getExams(this.pk);
    this.getCourseStudents();
    this.getBehaviourPoints();
  }

  public getCourse(pk: number){
    this._apiService.getObjectDetail(`/api/schoolapp/courses/${pk}/`).subscribe(
      data => {
        this.course = data;
        this.courseForm.controls['coursePreparation'].setValue(data['preparation']);
        // this.sanctions = data['sanctions'];
        this.pk = data['id'];
      }
    );
  }

  public getNextCourse(pk: number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?c=${pk}`).subscribe(
      data => {
        this.nextPk = data['id'];
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  public getPreviousCourse(pk: number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?p=${pk}`).subscribe(
      data => {
        if(data.length > 0){
          this.previousPk = data[0]['id'];
        } else {
          this.previousPk = this.pk;
        }
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  public getExams(pk: number){
    this._apiService.getObjectsList(`/api/schoolapp/exams/?course=${this.pk}`).subscribe(
      data => {
        this.dataSource.data = data;
      }
    )
  }

  private initForm() {
    this.courseForm = new FormGroup({
      'coursePreparation': new FormControl(null, Validators.required)
    });
  }

  public onSubmit(){
    let preparation = this.courseForm.controls['coursePreparation'].value;

    this._apiService.patchObject(`/api/schoolapp/courses/${this.pk}/`,{"preparation":preparation}).subscribe(
      data => {
        this._snackBar.open('Préparation modifiée', 'Fermer');
      },
      err => {
        this._snackBar.open('Une erreur est survenue.', 'Fermer');
      }
    );
  }

  /**
   * Open a dialog to show available fields objects list.
   */
  openAddSanctionDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    // dialogConfig.data = this.fields;
    dialogConfig.data = this.pk;

    let addSanctionDialogRef = this.dialog.open(AddSanctionDialogComponent, dialogConfig);

    addSanctionDialogRef.afterClosed().subscribe(value => {
      console.log(value);
    });
  }

  /**
   * Open a dialog to show available fields objects list.
   */
  openAddTaskDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    // dialogConfig.data = this.fields;
    dialogConfig.data = this.pk;

    let addSanctionDialogRef = this.dialog.open(AddTaskDialogComponent, dialogConfig);

    addSanctionDialogRef.afterClosed().subscribe(value => {
      
    });
  }

  openCreateExamDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'modelName': 'Exam', 'additionalData':{'course':this.pk}}

    let dialogRef = this.dialog.open(CreateObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.getExams(this.pk);
    });
  }

  openStudentCommentsDialog(student: any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = student;

    let dialogRef = this.dialog.open(StudentCommentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.getExams(this.pk);
    });
  }

  redirect(pk: number) {
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(()=>this.router.navigate(['/schoolapp/edit-course',pk]));
  }

  typeOfExam(val: string){
    let types = {
      short_exam:'Contrôle de leçon',
      oral:'Oral',
      dictation:'Dictée',
      vocabulary:'Vocabulaire',
    };

    return types[val];
  }

  updateDeleteUrl(pk: number){
    return `/api/schoolapp/exams/${pk}/`;
  }

  refresh(){
    this.getExams(this.pk);
  }

  detailUrl(pk: number){
    return `/api/schoolapp/exam-detail/${pk}/`;
  }

  getCourseStudents(){
    this._apiService.getObjectsList(`/api/schoolapp/courses/${this.pk}/students/`).subscribe(
      data => {
        this.students = data;

        this.students.forEach(student => {
          student.commentaries = [];

          if(student.student_detail&&student.student_detail.commentaries){
            student.commentaries = student.student_detail.commentaries;
          }
          // console.log(student.student_detail.commentaries);
        });
      }
    )
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addBonusPoint(){
    return true;
    this._apiService.createObject(`/api/schoolapp/schoolclass/add-bonus-point/${this.course.schoolclass.id}/`,{}).subscribe(
      data => {
        this.course.schoolclass.bonus_points = data['bonus_points'];
      },
      err => {
        this._snackBar.open("Vous avez déjà ajouté un point bonus à cette classe aujourd'hui.", "Fermer");
      }
    );
  }

  addReward(){
    if(confirm("Voulez-vous vraiment ajouter une récompense à cette classe ? Cela remettra les points de classe à zéro.")){
      this._apiService.createObject(`/api/schoolapp/schoolclass/add-reward/${this.course.schoolclass.id}/`,{}).subscribe(
        data => {
          this.course.schoolclass.rewards = data['rewards'];
          this.course.schoolclass.bonus_points = data['bonus_points'];
        },
        erro => {
          this._snackBar.open("Cette classe n'a pas de point bonus. Vous ne pouvez lui attribuer une récompense.", 'Fermer')
        }
      );
    }
  }

  getBehaviourPoints(){
    this._apiService.getObjectsList
  }

  closeCourse(pk:number){
    if(confirm("Voulez-vous vraiment clôturer le cours ? Cela va attribuer des points à tous les élèves qui ont 3 coeurs en comportement.")){
      this._apiService.patchObject(`/api/schoolapp/courses/${pk}/`,{"status":1}).subscribe(
        (data:any) => {
          this.course = data;
          // this.course.status = data["status"];
          this.competitorBehaviourCoursePointsList.dataGrid.instance.refresh();
        }
      )
    }
  }
}
