import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'create-homework',
  templateUrl: './create-homework.component.html',
  styleUrls: ['./create-homework.component.scss']
})
export class CreateHomeworkComponent implements OnInit {

  @ViewChild('stepper') private _stepper: MatStepper;
  
  public firstFormGroup: FormGroup;
  public schoolclasses;

  public dueDate: any;
  public minPercentage: any;
  public selectedSchoolclasses: any[] = [];
  public selectedExercises: any[] = [];

  public exerciseHomeworkChecks: any[] = [];

  constructor(private _formBuilder: FormBuilder,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private _router: Router,) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      dueDate: ['', Validators.required],
      minPercentage: [80, [Validators.required, Validators.min(10), Validators.max(100),],],
      schoolclasses: ['', Validators.required],
    });
    this.loadSchoolclasses();
  }

  /**
   * Load all user's SchoolClasses
   */
  loadSchoolclasses(){
    this._apiService.getObjectsList(`api/dx-schoolclasses/`).subscribe(
      data => {
        this.schoolclasses = data['data'];
      }
    )
  }

  /**
   * Create a new HomeWork instance
   */
  createHomework(){
    this.dueDate = this.firstFormGroup.get('dueDate').value;
    this.minPercentage = this.firstFormGroup.get('minPercentage').value;
    this.selectedSchoolclasses = this.firstFormGroup.get('schoolclasses').value;
    
    // Before creating the HomeWork instance, checks if all data was given.
    if((!this.dueDate) || (!this.minPercentage) || (this.minPercentage < 10) || (this.minPercentage > 100) || (!this.selectedSchoolclasses) || (this.selectedSchoolclasses.length == 0)){
      
      this._snackBar.open("Veuillez renseigner correctement toutes les 'Données générales du devoir' pour pouvoir continuer.");
      this._stepper.selectedIndex = 0;

    } else if (!this.selectedExercises || this.selectedExercises.length == 0) {

      this._snackBar.open("Veuillez ajouter au moins un exercice dans 'Exercices du devoir' pour pouvoir continuer.");
      this._stepper.selectedIndex = 1;

    } else {

      this.selectedSchoolclasses.forEach(schoolclass => {

        const data = {
          "due_date":this.dueDate,
          "min_percentage":this.minPercentage,
          "schoolclass":schoolclass.id,
          "exercises":this.selectedExercises.map(exercise => exercise.id),
        };

        this._apiService.createObject(`api/homeworks/`,data).subscribe(
          data => {
            this._snackBar.open("Le devoir a été créé avec succès","Fermer");
            this._router.navigate(['admin/homeworks']);
          },
          err => this._snackBar.open(err, "Fermer")
        );
        
      });
    }
  }

  /**
   * Add an Exercise to HomeWork
   * @param exercise
   */
  addExercise(exercise:any){
    if(!this.selectedExercises.includes(exercise)){
      this.selectedExercises.push(exercise);
    }

    this.checkExerciseHomeworks();
    this._snackBar.open("L'exercice a été ajouté à la liste avec succès.","Fermer");
  }

  /**
   * Remove an Exercise from HomeWork
   * @param exercise
   */
  removeExercise(exercise:any){
    const index = this.selectedExercises.indexOf(exercise);

    if (index >= 0){
      this.selectedExercises.splice(index, 1);
    }

    this.checkExerciseHomeworks();
    this._snackBar.open("L'exercice a été retiré de la liste avec succès.","Fermer");
  }

  /** 
   * Check if given exercise was already used in a HomeWork by current user.
   */
  checkExerciseHomeworks(){
    this.exerciseHomeworkChecks = [];

    this.selectedExercises.forEach(exercise => {
      this._apiService.getObjectsList(`api/homeworks-check/${exercise.id}/`).subscribe(
        data => {
          if(data&&data.length>0){
            data.forEach(homework => {
              homework['exercises'].forEach(ex => {
                if(ex.id === exercise.id){
                  this.exerciseHomeworkChecks.push(`L'exercice ${exercise.name} (${exercise.yearsubject.name} - ${exercise.theme.name}) a déjà été donné en devoir à la classe "${homework['schoolclass']['name']}" pour le ${this.fromTimestampToDate(homework['due_date'])}.`);
                }
              });
            });
          }
        }
      )
    });
  }

  fromTimestampToDate(timestamp:number){
    return new Date(timestamp * 1e3).toLocaleDateString("fr-CH");
  }
}
