import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-admin-links',
  templateUrl: './admin-links.component.html',
  styleUrls: ['./admin-links.component.scss']
})
export class AdminLinksComponent implements OnInit {

  public yearSubjects: any[];
  public themes: any[];
  public newLinkCategories: any[];

  public form: FormGroup;

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this._apiService.getObjectsList('api_school/yearsubjects/?ordering=id').subscribe(
      data => {
        this.yearSubjects = data;
      }
    );

    this.initForm();
  }

  initForm(){
    this.form = new FormGroup({
      'yearSubjectCtrl': new FormControl(null),
      'themeCtrl': new FormControl(null),
      'newLinkCategoryCtrl': new FormControl(null),
    })
  }

  onYearSubjectChange(yearSubject){
    this.themes = [];
    this.newLinkCategories = [];

    const yearSubjectId = yearSubject.value;
    const endPoint      = `api_school/themes/?yearsubject=${yearSubjectId}&ordering=id`;

    this._apiService.getObjectDetail(endPoint).subscribe(
      (data: any[]) => {
        this.themes = data;
      }
    )
  }

  onThemeChange(theme){
    this.newLinkCategories = [];

    const themeId       = theme.value;
    const endPoint      = `api_school/newlinkcategories/?theme=${themeId}`;

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        this.newLinkCategories = data;
      }
    )
  }

  onNewLinkCategoryChange(category){
    const categoryId    = category.value;
    const endPoint      = `api_school/links/?newlinkcategory=${categoryId}`;

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        // data);
      }
    )
  }
}
