<h2>
    Bienvenue sur teraro.ch
</h2>

<mat-card>
    <h3>
        Si vous êtes un visiteur, vous pouvez :
    </h3>
    <ul>
        <li>Consulter les liens utiles pour les cours</li>
    </ul>
    <br>
    <h3>
        Si vous êtes un élève enregistré, vous pouvez en plus vous connecter pour :
    </h3>
    <ul>
        <li>Faire vos devoirs en ligne</li>
        <li>Compléter des parcours (suites d'exercices) donnés par votre enseignant(e)</li>
        <li>Vous exercer dans diverses branches (français et allemand pour le moment)</li>
    </ul>
    <br>
    <h3>
        Si vous êtes un enseignant inscrit, vous pouvez en plus :
    </h3>
    <ul>
        <li>Créer et modifier des groupes d'élèves, pour leur assigner des exercices comme devoirs</li>
        <li>Créer des parcours (suites d'exercices) pour vos élèves et suivre leur progression</li>
    </ul>
    <br>
    Pour le moment, seuls les enseignants/élèves du CO St-Guérin peuvent utiliser cette plateforme.
</mat-card>
