import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-courses-planification',
  templateUrl: './courses-planification.component.html',
  styleUrls: ['./courses-planification.component.scss']
})
export class CoursesPlanificationComponent implements OnInit {

  public dateRangeForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(){
    this.dateRangeForm = new FormGroup({
      'startDate': new FormControl(null, Validators.required),
      'endDate': new FormControl(null, Validators.required)
    })
  }

  onSubmit(){
    console.log(this.dateRangeForm);
  }
}
