<dx-data-grid
    [dataSource]="todoTasks"
    [remoteOperations]="true">

    <dxo-editing
        [allowUpdating]="true"
        [allowAdding]="true"
        [allowDeleting]="true"
        mode="row"> <!-- 'batch' | 'cell' | 'form' | 'popup' -->
    </dxo-editing>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
        dataField="content">
    </dxi-column>

    <!--<dxi-column
        dataField="is_finished"
        dataType="boolean"
        caption="Statut">
    </dxi-column>-->

</dx-data-grid>