<dx-data-grid
    [dataSource]="tasks"
    [remoteOperations]="true">

    <dxo-filter-row
        [visible]="true">
    </dxo-filter-row>
    <dxo-paging [pageSize]="5"></dxo-paging>
    <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[5, 10,]">
    </dxo-pager>

    <dxi-column
        dataField="student_detail"
        dataType="string"
        caption="Elève"
        cellTemplate="studentCellTemplate"
        [calculateFilterExpression]="studentCalculateFilterExpression"
        [filterOperations]="['contains',]">
    </dxi-column>
    <dxi-column
        dataField="comment"
        dataType="string"
        caption="Tâche">
    </dxi-column>
    <dxi-column
        dataField="course"
        [filterValue]="coursePk"
        [calculateFilterExpression]="courseCalculateFilterExpression"
        [width]="0">
    </dxi-column>
    <dxi-column
        dataField="postponed"
        cellTemplate="postponedCellTemplate"
        caption="Actions"
        [filterOperations]="['=',]">
        <dxo-lookup
            [dataSource]="postponedLookups"
            valueExpr="value"
            displayExpr="name">
        </dxo-lookup>
    </dxi-column>    
    
    <!--<dxi-column
        dataField="course_detail"
        dataType="string"
        caption="Cours"
        cellTemplate="courseCellTemplate">
    </dxi-column>-->

    <!--<div *dxTemplate="let data of 'courseCellTemplate'">
        {{data.data.course_detail.schoolclass.name}} du {{data.data.course_detail.date | date:'dd.MM.yyyy'}}
    </div>-->
    <div *dxTemplate="let data of 'studentCellTemplate'">
            {{data.data.student_detail.username}}
    </div>

    <div *dxTemplate="let data of 'postponedCellTemplate'">
        <span *ngIf="data.data.postponed"
              matTooltip="Cette tâche a déjà été reportée au moins une fois."
              matTooltipPosition="above"
              class="material-icons warning pointer">
            warning
        </span>
        <ng-container *ngIf="data.data.status==2">
            <span matTooltip="Passer la tâche à 'terminée'" 
                  class="material-icons action success"
                  (click)="finish(data.data.id)">
                done_outline
            </span>
            <span matTooltip="Remettre la tâche au prochain cours"
                  class="material-icons action warning"
                  (click)="postpone(data.data.course, data.data.id)">
                double_arrow
                </span>
            <span matTooltip="Supprimer la tâche"
                  class="material-icons action danger"
                  (click)="delete(data.data.id)">
                delete
            </span>
        </ng-container>
        <ng-container *ngIf="data.data.status==3">
            <span matTooltip="La tâche est terminée" 
                  class="material-icons action success"
                  (click)="finish(data.data.id)">
                check_circle_outline
            </span>
        </ng-container> 
    </div>
</dx-data-grid>
