import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Models } from 'src/app/school/models/api-models';

@Component({
  selector: 'create-object-dialog',
  templateUrl: './create-object-dialog.component.html',
  styleUrls: ['./create-object-dialog.component.scss']
})
export class CreateObjectDialogComponent implements OnInit {

  public objectForm: FormGroup = new FormGroup({}); // Formular to create a new object

  public endPoint: string; // Url endPoint in API to create a new object
  public model: any; // Instance of created item
  public additionalData: any; // Additional data passed, e.g. parent object foreign key (id)
  public selectChoices: any = {};

  constructor(public dialogRef: MatDialogRef<CreateObjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService) { }

  ngOnInit(): void {
    this.model      = new Models[this.data['modelName']]();
    this.endPoint   = this.model.getListEndPoint();
    this.additionalData = this.data['additionalData'];

    this.model.createEditFields().forEach(key => {
      this.objectForm.addControl(key, new FormControl(this.model[key], Validators.required));
    });

    if(this.model.getSelectChoices()){
      this.selectChoices = this.model.getSelectChoices();
    }
  }

  /**
   * Closes current dialog without saving anything
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * Closes current dialog but saves created object before
   */
  save() {
    let data = this.objectForm.value;

    // if additionalData was passed to component, we add it
    // to the object before creating it (foreign key e.g.)
    if(this.additionalData){
      for(const property in this.additionalData){
        data[property] = this.additionalData[property];
      }
    }

    this._apiService.createObject(this.endPoint, data).subscribe(
      data => {
        this.dialogRef.close({"message":"Données enregistrées"});
      },
      err => this.dialogRef.close({"message":err.message})
    )
  }
}
