import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnInit {

  @Input()
  public coursePk: number;

  @Input()
  public filtering: boolean = false;

  @Input()
  public showDetail: boolean = false;

  @ViewChild(MatSort, {static: true})
  public sort: MatSort;

  public getFinishedTasksForm: FormGroup;

  public displayedColumns: string[] = ['student', 'comment', 'status', 'actions',];
  public dataSource: any = new MatTableDataSource();

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if(this.showDetail){
      this.displayedColumns = ['date', 'course', 'student', 'comment', 'status', 'actions',];
    }

    this.getFinishedTasksForm = new FormGroup({
      'finishedTasks': new FormControl(false)
    });

    this.getTasks();
    // don't forget to initialize this method here !!
    // otherwise, onChanges won't trigger when the form is changed !!
    this.onChanges();
  }

  getTasks(getFinishedTasks: boolean = false){
    console.log('this.coursepk : ', this.coursePk);
    console.log('getfinishedTasks : ', getFinishedTasks);

    let endPoint = '/api/schoolapp/tasks/';

    if(this.coursePk){
      endPoint = `/api/schoolapp/tasks/?c=${this.coursePk}`;

      if(getFinishedTasks){
        endPoint = `${endPoint}&s=1`;
      }
    } else {
      endPoint = '/api/schoolapp/tasks/';

      if(getFinishedTasks){
        endPoint = '/api/schoolapp/tasks/?s=1';
      }
    }
    // if(getFinishedTasks){
    //   endPoint = 'tasks/?s=1';
    // }

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        this.dataSource.data = data;
        console.log('DATA : ', data);

        /** Add children objects to the sorting */
        this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        this.dataSource.sort = this.sort;

        /** Add children objects to the filtering */
        this.dataSource.filterPredicate = (data: any, filter) => {
          const dataStr = JSON.stringify(data).toLowerCase();
          return dataStr.indexOf(filter) != -1;
        }
      }
    )
  }

  delete(pk:number){
    this._apiService.deleteObject(`/api/schoolapp/tasks/${pk}/`).subscribe(
      data => {
        this.getTasks();
        this._snackBar.open('La tâche a été supprimée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.')
    )
  }

  finish(pk:number){
    this._apiService.patchObject(`/api/schoolapp/tasks/${pk}/`, {"status":3}).subscribe(
      data => {
        this.getTasks();
        this._snackBar.open('La tâche a été passée à terminée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  postpone(coursePk:number, pk:number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?c=${coursePk}`).subscribe(
      data => {
        this._apiService.patchObject(`/api/schoolapp/tasks/${pk}/`,
                                     {"course":data['id'],
                                      "postponed":true}).subscribe(
          d => {
            this.getTasks();
            this._snackBar.open('La tâche a été repoussée au prochain cours.', 'Fermer');
          },
          error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
        )
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

   /* Apply filter to data table */
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProperty(obj, property){
    /* Uses lodash magic power. Nearly the same as getattr() python native method. */
    return _.get(obj, property);
  }

  onChanges(): void {
    this.getFinishedTasksForm.get('finishedTasks').valueChanges.subscribe(val => {
      this.getTasks(val);
    });
  }

  refresh(){
    console.log('refresh');

  }
}