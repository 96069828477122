<div class="content-block">
    <h2 *ngIf="schoolclass">
        Gestion du groupe d'élèves : <u>{{schoolclass.name}}</u>
    </h2>

    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Liste des élèves du groupe</span>
            </ng-template>
            <div *ngIf="membersDataSource">
                <dx-data-grid 
                    #gridContainer
                    id="gridContainer"
                    [dataSource]="membersDataSource"
                    [showBorders]="true">
                    <dxo-header-filter 
                    [visible]="true"></dxo-header-filter>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager 
                        [showPageSizeSelector]="true"
                        [allowedPageSizes]="[5, 10, 20]"
                        [showInfo]="true"
                        [visible]="true">
                    </dxo-pager>
                    <dxi-column
                        dataField="username"
                        dataType="string"
                        caption="NOM"
                        [allowHeaderFiltering]="false">
                    </dxi-column>
                    <dxi-column
                        dataField="id"
                        dataType="number"
                        caption="Actions"
                        [allowHeaderFiltering]="false"
                        cellTemplate="actionsTemplate">
                    </dxi-column>
                    
                    <div *dxTemplate="let data of 'actionsTemplate'">
                        <mat-icon aria-hidden="false"
                                  aria-label="Editer"
                                  class="pointer"
                                  (click)="removeMember(data.data.id)">delete</mat-icon>
                    </div>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                </dx-data-grid>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Ajouter des élèves</span>
            </ng-template>
            <div *ngIf="allUsersDataSource">
                <dx-data-grid 
                    #gridContainer
                    id="gridContainer"
                    [dataSource]="allUsersDataSource"
                    [showBorders]="true">
                    <dxo-header-filter 
                    [visible]="true"></dxo-header-filter>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager 
                        [showPageSizeSelector]="true"
                        [allowedPageSizes]="[5, 10, 20]"
                        [showInfo]="true"
                        [visible]="true">
                    </dxo-pager>
                    <dxi-column
                        dataField="username"
                        dataType="string"
                        caption="NOM"
                        [allowHeaderFiltering]="false">
                    </dxi-column>
                    <dxi-column
                        dataField="id"
                        dataType="number"
                        caption="Actions"
                        [allowHeaderFiltering]="false"
                        cellTemplate="actionsTemplate">
                    </dxi-column>
                    
                    <div *dxTemplate="let data of 'actionsTemplate'">
                        <mat-icon aria-hidden="false"
                                  aria-label="Editer"
                                  class="pointer"
                                  (click)="addMember(data.data.id)">add</mat-icon>
                    </div>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                </dx-data-grid>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Générer des mots de passe</span>
            </ng-template>
            <button (click)="generatePasswords(schoolclass.id)">Générer pour cette classe</button>
            <br><br>
            <table *ngIf="modifiedUsers" class="users-table">
                <tr *ngFor="let user of modifiedUsers">
                    <td>{{ user.username }}</td>
                    <td>{{ user.password }}</td>
                </tr>
            </table>
        </mat-tab>
    </mat-tab-group>
</div>
