<div [formGroup]="getFinishedSanctionsForm"
     matTooltip="Charger les données"
     (change)="refresh()">
     Masquer les punitions terminées :
    <mat-slide-toggle formControlName="finishedSanctions"></mat-slide-toggle>
</div>

<mat-form-field *ngIf="filtering">
    <mat-label>Filtrer</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="">
</mat-form-field>

<table mat-table [dataSource]="dataSource" 
       matSort
       class="mat-elevation-z8">

    <!-- Date Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.course_detail.date | date:'dd-MM-yyyy'}} </td>
    </ng-container>

    <!-- Course Column -->
    <ng-container matColumnDef="course">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cours </th>
        <td mat-cell *matCellDef="let element"> {{element.course_detail.course_program.name}} </td>
    </ng-container>

    <!-- Student Column -->
    <ng-container matColumnDef="student">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Elève </th>
      <td mat-cell *matCellDef="let element"> {{element.student_detail.username}} </td>
    </ng-container>
  
    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status==2; else finished">
                <span matTooltip="La punition est en cours"
                      class="material-icons warning">
                    autorenew
                </span>
            </ng-container>
            <ng-template #finished>
                <span matTooltip="La punition est terminée"
                      class="material-icons success">
                    check_circle_outline
                </span>
            </ng-template>
        </td>
    </ng-container>

    <!-- Postponed Column -->
    <ng-container matColumnDef="postponed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Report </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.postponed" 
                  matTooltip="Cette punition a déjà été reportée au moins une fois."
                  matTooltipPosition="above"
                  class="material-icons warning pointer">
                warning
            </span>
        </td>
      </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element" style="width:20%;">
            <ng-container *ngIf="element.status==2">
                <span matTooltip="Passer la punition à 'terminée'" 
                      class="material-icons action success"
                      (click)="finish(element.id)">
                    done_outline
                </span>
                <span matTooltip="Remettre la punition au prochain cours"
                      class="material-icons action warning"
                      (click)="postpone(element.course, element.id)">
                    double_arrow
                    </span>
                <span matTooltip="Supprimer la punition"
                      class="material-icons action danger"
                      (click)="delete(element.id)">
                    delete
                </span>
            </ng-container> 
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-template #noData>
      <div>Aucune punition trouvée pour ce cours.</div>
  </ng-template>