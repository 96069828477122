import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-schoolclass',
  templateUrl: './schoolclass.component.html',
  styleUrls: ['./schoolclass.component.scss']
})
export class SchoolclassComponent implements OnInit {

  public students: any[];
  public schoolClass: any;

  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public allStudents: string[] = [];

  public studentCtrl: FormControl = new FormControl();
  public studentFilterCtrl: FormControl = new FormControl();
  public filteredStudents: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  // @ViewChild('studentInput') studentInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private activatedRoute: ActivatedRoute,
              private _apiService: ApiService) { }

  ngOnInit(): void {
    this.getStudentsList();
    this.getCourseProgramDetail();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredStudents
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterStudents() {
    if (!this.students) {
      return;
    }
    // get the search keyword
    let search = this.studentFilterCtrl.value;
    if (!search) {
      this.filteredStudents.next(this.students.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStudents.next(
      this.students.filter(student => {
        return student.name.toLowerCase().indexOf(search) > -1
      })
    );
  }

  getCourseProgramDetail(){
    let pk = this.activatedRoute.snapshot.params.pk;

    this._apiService.getObjectsList(`courses-programs/${pk}`).subscribe(
      data => {
        this.schoolClass = data;
      }
    )
  }

  getStudentsList(){
    this._apiService.getObjectsList(`students`).subscribe(
      (data: any[]) => {
        this.students = data;
        this.initMatSelect();
      }
    )
  }

  initMatSelect(){
    this.studentCtrl.setValue(this.students[0]);

    // load the initial bank list
    this.filteredStudents.next(this.students.slice());

    // listen for search field value changes
    this.studentFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStudents();
      });
  }

  remove(pk: number){
    // get course program pk
    let courseProgramPk = this.activatedRoute.snapshot.params.pk;
    // make an array of course program students
    let data = this.schoolClass.students.map(student => student);
    const pkIndex = data.indexOf(pk,0);
    data.splice(pkIndex, 1);

    this.schoolClass.students = data;
    this._apiService.putObject(`courses-programs/${courseProgramPk}`, this.schoolClass).subscribe(
      data => {
        this.getCourseProgramDetail();
      }
    )
  }

  addStudent(pk: number){
    // get course program pk
    let courseProgramPk = this.activatedRoute.snapshot.params.pk;

    let data = this.schoolClass.students.map(student => student);
    data.push(pk);
    this.schoolClass.students = data;

    this._apiService.putObject(`courses-programs/${courseProgramPk}`, this.schoolClass).subscribe(
      data => {
        this.getCourseProgramDetail();
      }
    )
  }
}