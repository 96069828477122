import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditObjectDialogComponent } from '../_dialogs/edit-object-dialog/edit-object-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { CreateObjectDialogComponent } from '../_dialogs/create-object-dialog/create-object-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss']
})
export class StudentDetailComponent implements OnInit {

  public pk: number;
  public student: any;
  public additionalData: any;
  public commentaries: any = {};

  public hobbiesProjectsForm: FormGroup;

  public com_mediums_types: any = {
    'emergency_num':"Téléphone d'urgence",
    'private_num':'Téléphone privé',
    'other_num':'Autre téléphone',
    'portable_num':'Téléphone portable',
    'email':'Email'
  }

  public scores_types: any = {
    'short_exam':'Contrôle de leçon',
    'oral':'Oral',
    'dictation':'Dictée',
    'vocabulary':'Vocabulaire'
  }

  // public com_display_columns: any = [
  //   {name: 'type_of', dataBinding: 'type_of', title: 'Type', type: 'text',},
  //   {name: 'value', dataBinding: 'value', title: 'Valeur', type: 'text',},
  // ];

  public dataSource: any = new MatTableDataSource();
  public comment_display_columns: any = [
    {name: 'created_at', dataBinding: 'created_at', title: 'Date', type: 'date',},
    {name: 'content', dataBinding: 'content', title: 'Contenu', type: 'text',},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ];

  public scoresDataSource: any = new MatTableDataSource();
  public scores_display_columns: any = [
    {name: 'course', dataBinding: 'exam_detail.course_detail.date', title: 'Date', type: 'date',},
    {name: 'value', dataBinding: 'value', title: 'Valeur', type: 'text',},
    {name: 'type_of', dataBinding: 'exam_detail.type_of', title: 'Type', type: 'text', callback: 'scoreType'},
    {name: 'name', dataBinding: 'exam_detail.name', title: 'Titre', type: 'text',},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'scoresUpdateDeleteUrl'},
  ];

  // for matChips input
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [ 'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        //'insertUnorderedList',
        //'insertOrderedList',
        'heading',
        'fontName'
       ],
       [//'fontSize',
        //'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        //'removeFormat',
        'toggleEditorMode'
       ]
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  public parents: any = {};
  public popupVisible: boolean = false;
  public selectedParent: any = {};
  public parentCommunicationMediums: any = {};

  constructor(private activatedRoute: ActivatedRoute,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private _dG: DatagridService,
              private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.pk = this.activatedRoute.snapshot.params.pk;
    this.additionalData = {"student":this.pk};

    this.getStudent(this.pk);
    this.getCommentaries();
    // this.getScores(this.pk);
    this.parents = this._dG.createStore(`/api/schoolapp/dx-parents/`);
  }

  getCommentaries(){
    this.commentaries = this._dG.createStore(`/api/schoolapp/dx-commentaries/`);
  }

  getStudent(pk: number){
    this._apiService.getObjectDetail(`/api/schoolapp/dx-users/${pk}/`).subscribe(
      data => {
        this.student = data['student_detail'];
        this.student.username = data['username'];
        this.dataSource.data = this.student.commentaries;
        this.initForms();
      }
    )
    //this._apiService.getObjectDetail(`/api/schoolapp/students/${pk}`).subscribe(
    //  data => {
    //    this.student = data;
    //    this.dataSource.data = this.student.commentaries;
    //    this.initForms();
    //    console.log('STUDENT : ', this.student);
    //  }
    //)
  }

  // getScores(studentPk: number){
  //   this._apiService.getObjectsList(`/api/schoolapp/scores/?student=${studentPk}`).subscribe(
  //     data => {
  //       this.scoresDataSource.data = data;
  //       console.log(data);
  //     }
  //   )
  // }

  initForms(){
    this.hobbiesProjectsForm = new FormGroup({
      'hobbiesCtrl': new FormControl(this.student.hobbies),
      'projectsCtrl': new FormControl(this.student.projects)
    });
  }

  saveHobbiesProjects(){
    let data = {
      "hobbies":this.hobbiesProjectsForm.controls['hobbiesCtrl'].value,
      "projects":this.hobbiesProjectsForm.controls['projectsCtrl'].value
    }

    console.log('DATA : ', data);
    
    this._apiService.patchObject(`/api/schoolapp/students/${this.student.id}`,data).subscribe(
      data => {
        this._snackBar.open('Contenu sauvegardé','Fermer');
      },
      err => this._snackBar.open('Une erreur est survenue','Fermer')
    )
  }

  openEditStudentDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'pk': this.student.id, 'endPoint': `/api/schoolapp/students/${this.student.id}`, 'modelName': 'Student'}

    let dialogRef = this.dialog.open(EditObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.getStudent(this.pk);
    });
  }

  openEditParentDialog(parentId: number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'pk': parentId, 'endPoint': `/api/schoolapp/parents/${parentId}`, 'modelName': 'Parent'}

    let dialogRef = this.dialog.open(EditObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.getStudent(this.pk);
    });
  }

  openCreateParentDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'modelName': 'Parent', 'additionalData':{'student':this.student.pk}}

    let dialogRef = this.dialog.open(CreateObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.getStudent(this.pk);
    });
  }

  add(event: MatChipInputEvent, parentId: number): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this._apiService.createObject(`/api/schoolapp/communication-mediums/`,
                                    {
                                      "parent":parentId,
                                      "value":value
                                    }).subscribe(
        data => {
          this.getStudent(this.pk);
          this._snackBar.open('Moyen de communication ajouté','Fermer');
        },
        err => this._snackBar.open('Une erreur est survenue','Fermer')
      )
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(comId: number): void {
    if(confirm("Êtes-vous certain(e) de vouloir supprimer ce moyen de communcation ?")){
      this._apiService.deleteObject(`/api/schoolapp/communication-mediums/${comId}`).subscribe(
        data => {
          this.getStudent(this.pk);
          this._snackBar.open('Moyen de communication supprimé','Fermer');
        },
        err => this._snackBar.open('Une erreur est survenue','Fermer')
      )
    }
  }

  delete(parentId: number): void {
    if(confirm("Êtes-vous certain(e) de vouloir supprimer ce parent ?")){
      this._apiService.deleteObject(`/api/schoolapp/parents/${parentId}`).subscribe(
        data => {
          this.getStudent(this.pk);
          this._snackBar.open('Parent supprimé','Fermer');
        },
        err => this._snackBar.open('Une erreur est survenue','Fermer')
      )
    }
  }

  refresh(){
    this.getStudent(this.pk);
    // this.getScores(this.pk);
  }

  updateDeleteUrl(pk: string){
    return `commentaries/${pk}`;
  }

  scoresUpdateDeleteUrl(pk: string){
    return `scores/${pk}`;
  }

  scoreType(key: string){
    return this.scores_types[key];
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;
    // Override implementation for the "between" filter operation
    if (selectedFilterOperation === "=") {
        const filterExpression = ["student__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  insertRow(event:any){
    // we add student id to the data sent
    event['data']['student'] = this.student.id;
  }

  updateRow(event:any){
    event['newData']['student'] = this.student.id;
  }

  insertCommunicationMedium(event:any){
    event['data']['parent'] = this.selectedParent.id;
  }

  updateCommunicationMedium(event:any){
    event['newData']['parent'] = this.selectedParent.id;
  }

  showPopup(parentData:any){
    this.selectedParent = parentData;
    this.parentCommunicationMediums = this._dG.createStore(`/api/schoolapp/dx-communication-mediums/`);
    this.popupVisible = true;
  }
}
