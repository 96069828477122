<h1 mat-dialog-title>Ajouter une tâche pour ce programme de cours / pour cette classe</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-select [formControl]="courseCtrl" placeholder="Cours">
        <mat-option *ngFor="let course of nextCourses" [value]="course.id">
            {{course.schoolclass_detail.name}} - {{days[course.date | date:'EEEE']}} {{course.date | date:'dd-MM-yyyy'}}
          </mat-option>
      </mat-select>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Commentaire</mat-label>
    <input matInput placeholder="Punition pour ..." [formControl]="commentCtrl" (keyup.enter)="save()">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="">Annuler</button>
  <button mat-raised-button color="accent" cdkFocusInitial (click)="save()">Ok</button>
</div>