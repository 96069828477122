import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'my-students',
  templateUrl: './my-students.component.html',
  styleUrls: ['./my-students.component.scss']
})
export class MyStudentsComponent implements OnInit {

  public schoolclasses: any[];
  public students: any = {};

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.loadSchoolclasses();
  }

  loadSchoolclasses(){
    this._apiService.getObjectsList(`/api/dx-schoolclasses/`).subscribe({
      next: (data:any) => {
        this.schoolclasses = data.data;
      }
    })
  }
}
