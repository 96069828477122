<dx-data-grid 
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="false"
    dateSerializationFormat="yyyy-MM-dd"
    [showColumnLines]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [rowAlternationEnabled]="false"
    (onInitNewRow)="onInitNewRow($event)">

    <dxo-editing
        [allowUpdating]="false"
        [allowAdding]="true"
        [allowDeleting]="false"
        mode="cell"> <!-- 'row' | 'batch' | 'cell' | 'form' | 'popup' -->
    </dxo-editing>

    <dxi-column
        dataField="content"
        dataType="string">
    </dxi-column>

    <dxi-column
        dataField="id"
        dataType="string"
        cellTemplate="actionsCellTemplate"
        [width]="'30%'">
    </dxi-column>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <dx-button
            icon="trash"
            (click)="deleteRow(data.data.id)">
        </dx-button>
    </div>
</dx-data-grid>
