import { Component, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'app-admin-index',
  templateUrl: './admin-index.component.html',
  styleUrls: ['./admin-index.component.scss']
})
export class AdminIndexComponent implements OnInit {

  public adminContentLinks: any[] = [];
  public adminWorksLinks: any[] = [];
  public coursesLinks: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.adminContentLinks = [
      {'url':'schoolclasses', 'icon':'group', 'tooltip':'Mes classes'},
      {'url':'links', 'icon':'link', 'tooltip':'Gestion des liens'},
      {'url':'exercises', 'icon':'text_snippet', 'tooltip':'Gestion des exercices'},
      {'url':'journeys', 'icon':'hiking','tooltip':'Création de parcours'},
    ];

    this.adminWorksLinks = [
      {'url':'homeworks', 'icon':'school', 'tooltip':'Attribuer des exercices en devoirs aux classes'},
      {'url':'schoolclass-journeys', 'icon':'moving', 'tooltip':'Attribuer des parcours aux classes'},
    ];

    this.coursesLinks = [
      {'url':'/schoolapp', 'icon':'calendar_today', 'tooltip':'Gestion des cours'},
      {'url':'/schoolapp/week-template', 'icon':'event', 'tooltip':"Créer mon programme de cours annuel"},
      // {'url':'/schoolapp/students', 'icon':'group', 'tooltip':'Gestion des élèves'},
    ]
  }

}
