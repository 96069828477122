import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  @Input()
  public media: any;

  constructor() { }

  ngOnInit(): void {
  }

}
