import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { QuestionDetailComponent } from '../question-detail/question-detail.component';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  @Input()
  public question: any;

  @Input()
  public admin: boolean = false;

  @Input()
  public showEditChoices: boolean = false;

  @ViewChildren(QuestionDetailComponent)
  public questionDetails: QueryList<QuestionDetailComponent>;

  @Output()
  public correctedQuestionObj = new EventEmitter;

  @Input()
  public exerciseCorrection: any;

  public correctedQuestionDetails: any[];
  
  public questionDetailToEditId: string;

  public questionContainerClass: string = 'full-width';
  public choicesContainerClass: string = 'right-side';
  
  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    if(this.admin){
      this.questionContainerClass = 'left-side';
    }
  }

  correctQuestion(){
    this.questionDetails.toArray().forEach(questionDetail => {
      questionDetail.correctAnswerGiven();
    });
  }

  correctionFromQuestionDetail(obj){
    this.correctedQuestionObj.emit(obj);
  }

  getQuestionDetailId(pk){
    this.showEditChoices = true;
    this.questionDetailToEditId = pk;
  }

  reloadQuestion(event){
    if(event == 'close'){
      this.showEditChoices = false;
    }

    this._apiService.getObjectDetail(`api/dx-questions/${this.question.id}/`).subscribe(
      data => {
        this.question = data;
      }
    )
  }
}
