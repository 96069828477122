<ng-container *ngIf="resume">
    <div class="border">

        <div class="row">
            <div class="column-left">
                <div class="block center">
                    <resume-picture [pictureDetail]="resume.picture_detail"></resume-picture>
                </div>
                <div class="block">
                    <h2>Contact</h2>
                    <p><span class="material-icons">event</span> {{resume.birthdate}}</p>
                    <p><span class="material-icons">place</span> {{resume.address}}</p>
                    <p><span class="material-icons">phone</span> {{resume.phonenumber}}</p>
                    <p><span class="material-icons">mail</span> {{resume.email}}</p>
                </div>

                <!--<div class="block">
                    <h2>Compétences</h2>
                    <p *ngFor="let skill of resume.skills_details">
                        {{skill}}
                    </p>
                </div>-->
            </div>

            <div class="column-right">
                <h1>{{resume.name}} {{resume.first_name}}</h1>

                <hr>

                <div class="block">
                    <h2>Présentation</h2>
                    <p>{{resume.description}}</p>
                </div>

                <div class="block">
                    <h2>Parcours scolaire</h2>
                    <div *ngFor="let path of resume.school_paths_details" class="row">
                        <div class="col-25">
                            {{path.start_year}}
                        </div>
                        <div class="col-25">
                            {{path.end_year}}
                        </div>
                        <div class="col-50">
                            {{path.content}}
                        </div>
                    </div>
                </div>

                <div class="block">
                    <h2>Stages</h2>
                    <div *ngFor="let internship of resume.internships_details">
                        {{internship.period}}
                    </div>
                </div>

                <div class="block">
                    <h2>Qualités</h2>
                    <p *ngFor="let quality of resume.personal_qualities_details">
                        {{quality}}
                    </p>
                </div>

                <div class="block">
                    <h2>Langues</h2>
                    <p *ngFor="let language of resume.languages_details">
                        {{language}}
                    </p>
                </div>

                <div class="block">
                    <h2>Références</h2>
                    <div *ngFor="let reference of resume.references_details">
                        {{reference}}

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>