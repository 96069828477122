import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'resume2',
  templateUrl: './resume2.component.html',
  styleUrls: ['./resume2.component.scss']
})
export class Resume2Component implements OnInit {

  @Input()
  public resume: any;
  public pictureSrc: string;

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.loadResume();
  }

  ngOnChanges(changes: SimpleChanges){
    console.log(changes);
    if(changes && changes.resume.previousValue != changes.resume.currentValue){
      this.loadResume();
    }
  }

  loadResume(){
    this._apiService.getObjectDetail('api/get-or-create-resume/').subscribe(
      data => {
        this.resume = data;
        this.pictureSrc = `data:${this.resume.picture_detail.mime_type};base64,${this.resume.picture_detail.b64_encoded}`;
      }
    )
  }

}
