<h1>Détail de l'objet</h1>

<mat-dialog-content *ngIf="loadIsComplete">
    <p *ngFor="let field of model | keyvalue">
        {{ field.key }} - {{ field.value }}
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()"> Fermer</button>
</mat-dialog-actions>