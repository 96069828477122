<dx-data-grid
    id="behaviourCoursePointsContainer"
    [dataSource]="behaviourCoursePoints"
    [remoteOperations]="true"
    (onSelectionChanged)="selectionChanged($event)">
    <dxo-paging [pageSize]="50"> </dxo-paging>
    <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[10,20,50,]"
        [displayMode]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-filter-row
        [visible]="true">
    </dxo-filter-row>
    <!--<dxo-selection
        mode="multiple"
        selectAllMode="allPages">
    </dxo-selection>
    <dxo-toolbar>
        <dxi-item location="before">
          <dx-button
            text="Vider la sélection"
            (onClick)="dataGrid.instance.clearSelection()">
          </dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button
              text="Attribuer un point"
              (onClick)="addBonusPoints()">
            </dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button
              text="Attribuer une récompense"
              (onClick)="addRewards()">
            </dx-button>
        </dxi-item>
    </dxo-toolbar>-->

    <dxi-column
        dataField="competitor"
        dataType="string"
        caption="Élève"
        cellTemplate="competitorCellTemplate"
        [filterOperations]="['contains']"
        [calculateFilterExpression]="competitorFilterExpression">
    </dxi-column>
    <dxi-column
        dataField="course"
        dataType="string"
        [filterOperations]="['=']"
        [filterValue]="course.id"
        [width]="0">
    </dxi-column>
    <dxi-column
        dataField="behaviour_points"
        dataType="number"
        caption="Comportement"
        [allowFiltering]="false"
        cellTemplate="behaviourPointsCellTemplate">
    </dxi-column>
    <dxi-column
        dataField="competitor_detail.bonus_points"
        dataType="number"
        caption="Points individuels"
        [allowFiltering]="false"
        cellTemplate="bonusPointCellTemplate">
    </dxi-column>
    <dxi-column
        dataField="competitor_detail.rewards"
        dataType="number"
        caption="Récompenses"
        [allowFiltering]="true"
        cellTemplate="rewardsCellTemplate">
    </dxi-column>
    <dxi-column
        dataField="user"
        dataType="string"
        cellTemplate="studentCellTemplate"
        caption="Commentaires"
        [allowFiltering]="false">
    </dxi-column>

    <div *dxTemplate="let data of 'competitorCellTemplate'">
        {{ data.data.competitor_detail.user_detail.username }}
    </div>

    <div *dxTemplate="let data of 'behaviourPointsCellTemplate'">
        <button *ngIf="course.status==0" mat-button (click)="removePoint(data.data.id)">-</button>
        <ng-container *ngFor="let point of [].constructor(data.data.behaviour_points)">
            <mat-icon
                aria-hidden="false"
                aria-label="Points de comportement"
                fontIcon="favorite"
                class="pointer"
                [class]="getBehaviourPointsColor(data.data.behaviour_points)">
            </mat-icon>
        </ng-container>
        <button *ngIf="course.status==0" mat-button (click)="addPoint(data.data.id)">+</button>
    </div>

    <div *dxTemplate="let data of 'bonusPointCellTemplate'">
        <div class="left">
            <mat-progress-bar mode="determinate" color="accent" [value]="(data.data.competitor_detail.bonus_points)*10"></mat-progress-bar>
        </div>
        <div class="right">
            <button *ngIf="course.status==0" mat-button (click)="addBonusPoint(data.data.competitor_detail.id)">+</button>
        </div>
    </div>

    <div *dxTemplate="let data of 'rewardsCellTemplate'">
        <ng-container *ngFor="let reward of data.data.competitor_detail.rewards_detail">
            <ng-container *ngIf="reward.color=='grey'">
                <button mat-mini-fab aria-label="Example icon button with a home icon">
                    <mat-icon (click)="chooseReward(reward.id)">flag</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="reward.color!='grey'">
                <div *ngIf="reward.used=='non'" class="round" [style]="'width:'+reward.size+'px; height:'+reward.size+'px; background-color:'+reward.color+'; cursor:pointer;'" (click)="chooseReward(reward.id)"></div>
                <div *ngIf="reward.used=='oui'" class="round" [style]="'width:'+reward.size+'px; height:'+reward.size+'px; background-color:'+reward.color+'; cursor:pointer; opacity:0.3;'" (click)="chooseReward(reward.id)"></div>
            </ng-container>
        </ng-container>
        <button *ngIf="course.status==0" mat-button (click)="addReward(data.data.competitor_detail.id)">+</button>
    </div>

    <div *dxTemplate="let data of 'studentCellTemplate'">
        <button
            *ngIf="data.data.competitor_detail&&data.data.competitor_detail.user_detail&&data.data.competitor_detail.user_detail.student_detail&&data.data.competitor_detail.user_detail.student_detail.commentaries"
            mat-raised-button
            color="accent"
            matTooltip="Voir les commentaires pour cet élève"
            matTooltipPosition="left"
            (click)="openStudentCommentsDialog(data.data.competitor_detail.user_detail.student_detail)">
            <span class="material-icons">
                remove_red_eye
            </span> ({{data.data.competitor_detail.user_detail.student_detail.commentaries.length}})
        </button>
        &nbsp;&nbsp;
        <button
            mat-raised-button
            color="accent"
            matTooltip="Vers la fiche de l'élève"
            matTooltipPosition="left"
            [routerLink]="['/schoolapp', 'student-detail',data.data.competitor_detail.user_detail.id]">
            <span class="material-icons">
                double_arrow
            </span>
        </button>
    </div>
</dx-data-grid>