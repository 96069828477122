<dx-data-grid 
    #examinationsGridContainer
    id="examinationsGridContainer"
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="true"
    dateSerializationFormat="yyyy-MM-dd"
    [showColumnLines]="true"
    [showRowLines]="true"
    [showBorders]="true"
    [rowAlternationEnabled]="false">

    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing
        [allowUpdating]="true"
        [allowAdding]="false"
        [allowDeleting]="true"
        mode="popup"> <!-- 'row' | 'batch' | 'cell' | 'form' | 'popup' -->
    </dxo-editing>

    <dxo-paging [pageSize]="10"></dxo-paging>

    <dxo-pager 
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[5, 10, 20]"
        [showInfo]="true"
        [visible]="true">
    </dxo-pager>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
        dataField="name"
        dataType="string">
    </dxi-column>

    <dxi-column
        dataField="exercises_details"
        dataType="string">
    </dxi-column>

    <dxi-column
        dataField="id"
        dataType="string"
        caption="Actions"
        cellTemplate="actionsCellTemplate">
    </dxi-column>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <button 
            mat-raised-button
            (click)="generateExamDocx(data.data.id)">
            Générer document
        </button>
    </div>
</dx-data-grid>