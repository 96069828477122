import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { EditRewardDialogComponent } from '../../_dialogs/edit-reward-dialog/edit-reward-dialog.component';
import { EditObjectDialogComponent } from '../../_dialogs/edit-object-dialog/edit-object-dialog.component';

@Component({
  selector: 'competitor-behaviour-course-points-list',
  templateUrl: './competitor-behaviour-course-points-list.component.html',
  styleUrls: ['./competitor-behaviour-course-points-list.component.scss']
})
export class CompetitorBehaviourCoursePointsListComponent implements OnInit {

  @Input()
  public course: any;

  @ViewChild(DxDataGridComponent)
  public dataGrid: DxDataGridComponent;

  public behaviourCoursePoints: any = {};
  public selectedItemKeys: any[] = [];

  constructor(private _dG:DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private _matDialog: MatDialog) { }

  ngOnInit(): void {
    this.loadBehaviourPoints();
  }

  loadBehaviourPoints(){
    this.behaviourCoursePoints = this._dG.createStore(`/api/schoolapp/dx-competitor-behaviour-course-points/`);
  }

  competitorFilterExpression(filterValue, selectedFilterOperation){
    return ["competitor__user__username", "contains", filterValue];
  }

  removePoint(pk:number){
    this._apiService.createObject(`/api/schoolapp/competitor/remove-behaviour-point/${pk}/`, {}).subscribe({next:
      (data:any) => {
        this.dataGrid.instance.refresh();
      }
    })
  }

  addPoint(pk:number){
    this._apiService.createObject(`/api/schoolapp/competitor/add-behaviour-point/${pk}/`, {}).subscribe({next:
      (data:any) => {
        this.dataGrid.instance.refresh();
      }
    })
  }

  getBehaviourPointsColor(behaviourPoints: number){
    if (behaviourPoints == 1){
      return 'red';
    } else if (behaviourPoints == 2){
      return 'orange';
    } else if (behaviourPoints == 3) {
      return 'green';
    } 
    return '';
  }

  chooseReward(rewardId:number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'pk': rewardId, 'endPoint': `/api/schoolapp/competitor-rewards/${rewardId}/`, 'modelName': 'CompetitorReward'}

    let dialogRef = this._matDialog.open(EditObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.dataGrid.instance.refresh();
    });
  }

  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

  addBonusPoint(competitorId: number){
    this._apiService.createObject(`/api/schoolapp/competitor/add-bonus-point/${competitorId}/`,{}).subscribe(
      data => {
        this.dataGrid.instance.refresh();
      },
      err => {
        this._snackBar.open("Vous avez déjà ajouté un point bonus à cet élève aujourd'hui.", "Fermer");
      }
    );
  }

  addReward(competitorId: any){
    this._apiService.createObject(`/api/schoolapp/competitor/add-reward/${competitorId}/`,{}).subscribe(
      data => {
        this.dataGrid.instance.refresh();
      },
      erro => {
        this._snackBar.open("Cet élève n'a pas de point bonus. Vous ne pouvez lui attribuer une récompense.", 'Fermer')
      }
    );
  }
}
