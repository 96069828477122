import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-edit-schoolclass',
  templateUrl: './edit-schoolclass.component.html',
  styleUrls: ['./edit-schoolclass.component.scss']
})
export class EditSchoolclassComponent implements OnInit {

  public schoolclassId: number;
  public schoolclass: any;
  public membersDataSource: any;
  public allUsersDataSource: any;
  public modifiedUsers: any;

  constructor(private _route: ActivatedRoute,
              public _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.schoolclassId  = this._route.snapshot.params.schoolclassId;
    this.loadAllUsers();
    this.loadSchoolclass();
  }

  loadAllUsers(){
    this._apiService.getObjectsList(`api/users/`).subscribe(
      data => {
        this.allUsersDataSource = data;
      }
    )
  }

  loadSchoolclass(){
    this._apiService.getObjectDetail(`api/dx-schoolclasses/${this.schoolclassId}/`).subscribe(
      data => {
        this.schoolclass = data;
        this.membersDataSource = data['members'];
      }
    );
  }

  removeMember(userId){
    if(confirm("Voulez-vous vraiment supprimer cet élève du groupe ? Cela effacera également tous ses travaux/devoirs dans ce groupe.")){
      this._apiService.deleteObject(`api/schoolclass/${this.schoolclassId}/member/${userId}/`).subscribe(
        data => {
          this._snackBar.open("Elève supprimé du groupe","Fermer");
          this.loadSchoolclass();
        }
      );
    }
  }

  addMember(userId){
    this._apiService.createObject(`api/schoolclass/${this.schoolclassId}/member/${userId}/`,{}).subscribe(
      data => {
        this._snackBar.open("Elève ajouté au groupe","Fermer");
        this.loadSchoolclass();
      }
    );
  }

  generatePasswords(schoolclassId){
    this._apiService.getObjectDetail(`api/schoolapp/generate-passwords/${schoolclassId}/`).subscribe(
      data => {
        console.log(data);
        this.modifiedUsers = data;
      })
  }
}
