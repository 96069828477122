<dx-data-grid
    [dataSource]="sanctions"
    [remoteOperations]="true">

    <dxi-column
        *ngIf="userId"
        dataField="student"
        dataType="number"
        caption="Elève"
        [filterValue]="userId"
        [calculateFilterExpression]="userCalculateFilterExpression"
        [width]="0">
    </dxi-column>
    <dxi-column
        *ngIf="!userId"
        dataField="student"
        dataType="number"
        cellTemplate="studentCellTemplate"
        caption="Elève"
        [calculateFilterExpression]="userCalculateFilterExpression">
    </dxi-column>

    
    <dxi-column
        *ngIf="courseId"
        dataField="course_detail"
        dataType="string"
        caption="Cours id"
        [width]="0"
        [filterValue]="courseId"
        [calculateFilterExpression]="courseCalculateFilterExpression">
    </dxi-column>
    <dxi-column
        *ngIf="!courseId"
        dataField="course_detail"
        dataType="string"
        caption="Cours"
        cellTemplate="courseCellTemplate">
    </dxi-column>

    <dxi-column
        dataField="comment"
        dataType="string"
        caption="Commentaire">
    </dxi-column>

    <dxi-column
        dataField="status"
        dataType="string"
        cellTemplate="statusCellTemplate"
        caption="Etat">
    </dxi-column>

    <dxi-column
        dataField="id"
        dataType="string"
        cellTemplate="actionsCellTemplate"
        caption="Actions">
    </dxi-column>

    <div *dxTemplate="let data of 'courseCellTemplate'">
        {{data.data.course_detail.schoolclass.name}} du {{data.data.course_detail.date | date:'dd.MM.yyyy'}}
    </div>

    <div *dxTemplate="let data of 'studentCellTemplate'">
        <ng-container *ngIf="data.data.student_detail&&data.data.student_detail.username">
            {{data.data.student_detail.username}}
        </ng-container>
    </div>

    <div *dxTemplate="let data of 'statusCellTemplate'">
        <ng-container *ngIf="data.data.status==2; else finished">
            <span matTooltip="La punition n'est pas terminée"
                  class="material-icons warning">
                autorenew
            </span>
        </ng-container>
        <ng-template #finished>
            <span matTooltip="La punition est terminée"
                  class="material-icons success">
                check_circle_outline
            </span>
        </ng-template>
    </div>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <ng-container *ngIf="data.data.status==2">
            <span matTooltip="Passer la punition à 'terminée'" 
                  class="material-icons action success"
                  (click)="finish(data.data.id)">
                done_outline
            </span>
            <span *ngIf="courseId"
                  matTooltip="Remettre la punition au prochain cours"
                  class="material-icons action warning"
                  (click)="postpone(data.data.course, data.data.id)">
                double_arrow
            </span>
            <span *ngIf="!courseId"
                  matTooltip="Aller au cours"
                  class="material-icons action warning"
                  (click)="redirectToCourse(data.data.course)">
                double_arrow
            </span>
            <span matTooltip="Supprimer la punition"
                  class="material-icons action danger"
                  (click)="delete(data.data.id)">
                delete
            </span>
        </ng-container>
    </div>
</dx-data-grid>
