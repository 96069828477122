import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'app-journeys-home',
  templateUrl: './journeys-home.component.html',
  styleUrls: ['./journeys-home.component.scss']
})
export class JourneysHomeComponent implements OnInit {

  public studentJourneys: any[];
  public studentSteps: any[];

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.loadStudentSteps();
  }

  loadStudentSteps(){
    this._apiService.getObjectsList(`api/dx-student-journeys/`).subscribe(
      data => {
        this.studentJourneys = data['data'];
      }
    )
  }

  getStudentJourneyValue(event:any){
    this.loadStudentSteps();
  }
}
