<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>{{ 'Filtrer' }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col.title }}</th>
            <td mat-cell *matCellDef="let row">
                <div [ngSwitch]="col.type">
                    <div *ngSwitchCase="'text'">
                        <span *ngIf="col.callback; else rawValue">
                            {{ this.parentComponent[col.callback](getProperty(row, col.dataBinding)) }}
                        </span>
                        <ng-template #rawValue>
                            {{ getProperty(row, col.dataBinding) }}
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'url'">
                        <span *ngIf="col.callback; else rawValue">
                            <url [url]="getProperty(row, col.name)"
                                 [text]="getProperty(row, col.name)">
                            </url>
                        </span>
                        <ng-template #rawValue>
                            ...
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'r-link'">
                        <r-link [url]="this.parentComponent[col.callback](getProperty(row, col.dataBinding))"
                                [text]="getProperty(row, col.dataBinding)">
                        </r-link>
                    </div>
                    <div *ngSwitchDefault>...</div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames;"></tr>
    </table>
</div>