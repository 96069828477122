import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-edit-question-content',
  templateUrl: './edit-question-content.component.html',
  styleUrls: ['./edit-question-content.component.scss']
})
export class EditQuestionContentComponent implements OnInit {

  public questionId: string;
  public question: any;

  public htmlContent: string;

  constructor(private _route: ActivatedRoute,
              private _apiService: ApiService,
              private _router: Router,) { }

  ngOnInit(): void {
    this.questionId = this._route.snapshot.paramMap.get('questionId');
    this.loadQuestion();
  }

  loadQuestion(){
    this._apiService.getObjectDetail(`api/dx-questions/${this.questionId}/`).subscribe(
      data => {
        this.question = data;
      }
    )
  }
 
  valueChange(value) {
    this.htmlContent = value;
  }

  nextStep(){
    const data = {'content':this.htmlContent,
                  'pk':this.questionId};

    this._apiService.patchObject(`api/dx-questions/${this.questionId}/`,data).subscribe(
      data => {
        this._router.navigate(['/admin','questions',this.questionId,'edit-detail']);
      }
    )
  }
}
