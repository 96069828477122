<div [class]="questionContainerClass">
    <ng-container *ngFor="let chunk of question.chunks; let i=index" [ngSwitch]="chunk.question_detail_type">
        <ng-container *ngSwitchCase="'questions réponses'">
            <question-detail [questionDetailObj]="chunk"
                             [exerciseCorrection]="exerciseCorrection"
                             [admin]="admin"
                             [showStudentAnswers]="showStudentAnswers"
                             (correctedQuestionDetailObj)="correctionFromQuestionDetail($event)"
                             (editChoices)="getQuestionDetailId($event)"></question-detail>
        </ng-container>
        <ng-container *ngSwitchCase="'textes à trous'">
            <question-detail [questionDetailObj]="chunk"
                             [exerciseCorrection]="exerciseCorrection"
                             [admin]="admin"
                             [showStudentAnswers]="showStudentAnswers"
                             (correctedQuestionDetailObj)="correctionFromQuestionDetail($event)"
                             (editChoices)="getQuestionDetailId($event)"></question-detail>
        </ng-container>
        <ng-container *ngSwitchCase="'choix multiples'">
            <question-detail [questionDetailObj]="chunk"
                             [exerciseCorrection]="exerciseCorrection"
                             [admin]="admin"
                             [showStudentAnswers]="showStudentAnswers"
                             (correctedQuestionDetailObj)="correctionFromQuestionDetail($event)"
                             (editChoices)="getQuestionDetailId($event)"></question-detail>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span [innerHTML]="chunk.content | safe: 'html'"></span>
        </ng-container>
    </ng-container>
</div>

<div *ngIf="showEditChoices" [class]="choicesContainerClass">
    <choices
        [showEditChoices]="showEditChoices"
        [questionDetailId]="questionDetailToEditId"
        (choiceUpdated)="reloadQuestion($event)"></choices>
</div>