<h1 mat-dialog-title>Editer le CV</h1>

<mat-card>
    <ng-container *ngIf="resume">
        <mat-tab-group>
            <mat-tab label="Edition">
                <mat-accordion>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                            Données personnelles
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <edit-personal-data [resume]="resume"></edit-personal-data>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                        <mat-panel-title>
                            Qualités personnelles
                        </mat-panel-title>
                        </mat-expansion-panel-header>
                        <personal-qualities-list [resumeId]="resume.id"></personal-qualities-list>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Langues
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <languages-list [resumeId]="resume.id"></languages-list>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Parcours scolaire
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <school-paths-list [resumeId]="resume.id"></school-paths-list>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Stages
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <internships-list [resumeId]="resume.id"></internships-list>
                    </mat-expansion-panel>
                
                    <!--<mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Compétences
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <skills-list [resumeId]="resume.id"></skills-list>
                    </mat-expansion-panel>-->
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Hobbies
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <hobbies-list [resumeId]="resume.id"></hobbies-list>
                    </mat-expansion-panel>
        
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Références
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <references-list [resumeId]="resume.id"></references-list>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Photo
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <resume-picture [pictureDetail]="resume.picture_detail"></resume-picture>
                        <input type="file" class="file-upload" (change)="uploadFile($event)">
                    </mat-expansion-panel>
                
                </mat-accordion>
            </mat-tab>
            <mat-tab label="Aperçu 1">
                <div class="resume-content">
                    <resume1 [resume]="resume"></resume1>
                </div>
            </mat-tab>
            <mat-tab label="Aperçu 2">
                <div class="resume-content">
                    <resume2 [resume]="resume"></resume2>
                </div>
            </mat-tab>
            <mat-tab label="Versions imprimables">
                <h3>Le meilleur rendu est obtenu au moyen du navigateur Chrome. Sur Firefox, des couleurs d'arrière-plan sont perdues.</h3>
                <div>
                    <ul>
                        <li>
                            <a class="white-text" [routerLink]="['/resumes',1]">Template 1</a>
                        </li>
                        <li>
                            <a class="white-text" [routerLink]="['/resumes',2]">Template 2</a>
                        </li>
                    </ul>
                </div>
                <h3>Vous pouvez également générer un document Word (docx) au moyen des liens ci-dessous.</h3>
                <div>
                    <ul>
                        <li>
                            <a class="white-text" (click)="generateDocx('cv_template_1')">Word Template 1</a>
                        </li>
                        <li>
                            <a class="white-text" (click)="generateDocx('cv_template_2')">Word Template 2</a>
                        </li>
                        <li>
                            <a class="white-text" (click)="generateDocx('cv_template_officiel')">Word Template 3 - officiel St-Guérin</a>
                        </li>
                    </ul>
                </div>
            </mat-tab>
        </mat-tab-group>
        
    </ng-container>
</mat-card>