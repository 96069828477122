import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'question-detail-correction',
  templateUrl: './question-detail-correction.component.html',
  styleUrls: ['./question-detail-correction.component.scss']
})
export class QuestionDetailCorrectionComponent implements OnInit {

  @Input()
  public exerciseCorrectionId: number;
  
  @Input()
  public questionDetail: any;

  public isLoaded: boolean = false;
  
  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    console.log('EXERCISE CORRECTION ID :', this.exerciseCorrectionId, 'QUESTION DETAIL :', this.questionDetail);
    this._apiService.getObjectDetail(`api/exercisecorrection-questiondetail/${this.exerciseCorrectionId}/${this.questionDetail.question_detail_id}/`).subscribe(
      data => {
        this.questionDetail.questionDetailCorrection = data;
        this.isLoaded = true;
      }
    )
  }

}
