<h2 mat-dialog-title>
    Aperçu de l'exercice
</h2>

<mat-dialog-content>
    <ng-container *ngIf="exercise">
        <exercise [exercise]="exercise"></exercise>
    </ng-container>
    <ng-container *ngIf="exerciseId">
        <exercise [exerciseId]="exerciseId"></exercise>
    </ng-container>
</mat-dialog-content>
