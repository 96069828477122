import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private API_URL= environment.API_URL;

  private httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "JWT " + localStorage.getItem('token'),
        "withCredentials": "true"
    })
  };

  private httpFileUploadOptions = {
    headers: new HttpHeaders({
        "Authorization": "JWT " + localStorage.getItem('token'),
        "withCredentials": "true"
    })
  };

  constructor(private _http: HttpClient) { 
  }

  /**
   * General 'GET' method to load a list of objects from API.
   * @param endPoint url in backend API
   */
  getObjectsList(endPoint: string) {
    return this._http.get<object[]>(`${this.API_URL}${endPoint}`,
                                    this.httpOptions);
  }

  /**
   * General 'GET' method to load a particular object from API.
   * @param endPoint url in backend API with params included
   */
  getObjectDetail(endPoint: string) {
    return this._http.get<object>(`${this.API_URL}${endPoint}`,
                                  this.httpOptions);
  }

  /**
   * General 'POST' method to create a new object in API.
   * @param endPoint url in backend API
   * @param data serialized dictionnary sent to API to create object.
   */
  createObject(endPoint: string, data: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this._http.post(`${this.API_URL}${endPoint}`,
                           data,
                           this.httpOptions);
  }

  /**
   * General 'PATCH' method to modify an existing object in API.
   * @param endPoint url in backend API
   * @param data serialized dictionnary sent to API to patch existing object.
   */
  patchObject(endPoint: string, data: any) {
    return this._http.patch(`${this.API_URL}${endPoint}`,
                            data,
                            this.httpOptions);
  }

  /**
   * General 'PUT' method to modify an existing object in API.
   * @param endPoint url in backend API
   * @param data serialized dictionnary sent to API to patch existing object.
   */
  putObject(endPoint: string, data: any) {
    return this._http.put(`${this.API_URL}${endPoint}`,
                          data,
                          this.httpOptions);
  }

  /**
   * General 'DELETE' method to delete an existing object in API.
   * @param endPoint url in backend API
   */
  deleteObject(endPoint: string){
    return this._http.delete(`${this.API_URL}${endPoint}`,
                             this.httpOptions);
  }

  /**
   * General 'PATCH' method to send a file in API.
   * @param endPoint  url
   * @param file file obj
   */
  patchFile(endPoint: string, file: File){
    const formData: FormData = new FormData();
    formData.append('picture', file, file.name);

    return this._http.patch(`${this.API_URL}${endPoint}`, formData);
  }

    /**
   * Specific method to generate courses
   * TODO : DELETE THIS ROUTE, IT'S USELESS
   */
     generateCourses(data){
      const endPoint = '/api/schoolapp/generate-courses/';
      console.log('DATA SENT : ', data);
  
      return this._http.post(`${this.API_URL}${endPoint}`,
                             data,
                             this.httpOptions);
    }
  
    /**
     * Specific method to post a file to show it's preview in the browser
     * @param fileToUpload 
     */
    postFileForPreview(fileToUpload: File): Observable<any> {
  
      const formData: FormData = new FormData()
      formData.append('file', fileToUpload, fileToUpload.name);
  
      return this._http.post<[]>(`${this.API_URL}api/schoolapp/file-preview/`,
                                 formData,
                                 this.httpFileUploadOptions);
      
    }
  
    postFile(fileToUpload: File, columnsToImport: number[] = undefined, firstRowToImport: number): Observable<any> {
  
      const formData: FormData = new FormData()
      formData.append('file', fileToUpload, fileToUpload.name);
  
      let endPoint    = '/api/schoolapp/upload-data/';
      let filters     = '?';
      let filtersData = ['firstRow='+String(firstRowToImport),];
      let columns     = '';
  
      if(columnsToImport && columnsToImport.length>0){
        columns = columnsToImport.join(',');
        filtersData.push('columns='+columns);
      }
  
      filters  += filtersData.join('&');
      endPoint += filters;
      console.log(endPoint);
      
      return this._http.post<[]>(`${this.API_URL}${endPoint}`,
                            formData);
      
    }
}
