<dx-data-grid
    id="competitorsGridContainer"
    [dataSource]="competitors"
    [remoteOperations]="true"
    (onSelectionChanged)="selectionChanged($event)">
    <dxo-paging [pageSize]="5"> </dxo-paging>
    <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[5,10,]"
        [displayMode]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-filter-row
        [visible]="true">
    </dxo-filter-row>
    <dxo-selection
        mode="single"
        selectAllMode="allPages">
    </dxo-selection>
    <dxo-toolbar>
        <dxi-item location="before">
          <dx-button
            text="Vider la sélection"
            (onClick)="dataGrid.instance.clearSelection()">
          </dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button
              text="Attribuer un point"
              (onClick)="addBonusPoints()">
            </dx-button>
        </dxi-item>
        <dxi-item location="before">
            <dx-button
              text="Attribuer une récompense"
              (onClick)="addRewards()">
            </dx-button>
        </dxi-item>
    </dxo-toolbar>

    <dxi-column
        dataField="user"
        dataType="string"
        caption="Élève"
        cellTemplate="userCellTemplate"
        [calculateFilterExpression]="userFilterExpression">
    </dxi-column>
    <dxi-column
        dataField="student_detail"
        dataType="string"
        cellTemplate="studentCellTemplate"
        caption="Commentaires"
        [allowFiltering]="false">
    </dxi-column>
    <dxi-column
        dataField="bonus_points"
        dataType="number"
        caption="Points individuels"
        [allowFiltering]="true">
    </dxi-column>
    <dxi-column
        dataField="rewards"
        dataType="number"
        caption="Récompenses"
        [allowFiltering]="true">
    </dxi-column>
    <dxi-column
        dataField="schoolclass"
        dataType="number"
        [width]="0"
        [filterValue]="schoolclass.id"
        [calculateFilterExpression]="schoolclassFilterExpression">
    </dxi-column>
    <dxi-column
        dataField="id"
        dataType="number"
        cellTemplate="actionsCellTemplate"
        caption="Fiche de l'élève"
        [allowFiltering]="false">
    </dxi-column>

    <div *dxTemplate="let data of 'userCellTemplate'">
        {{ data.data.user_detail.username }}
    </div>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <button
            mat-raised-button
            color="accent"
            matTooltip="Vers la fiche de l'élève"
            matTooltipPosition="left"
            [routerLink]="['/schoolapp', 'student-detail',data.data.id]">
            <span class="material-icons">
                double_arrow
            </span>
        </button>
    </div>

    <div *dxTemplate="let data of 'studentCellTemplate'">
        <button
            *ngIf="data.data.user_detail&&data.data.user_detail.student_detail&&data.data.user_detail.student_detail.commentaries"
            mat-raised-button
            color="accent"
            matTooltip="Voir les commentaires pour cet élève"
            matTooltipPosition="left"
            (click)="openStudentCommentsDialog(data.data.user_detail.student_detail)">
            <span class="material-icons">
                remove_red_eye
            </span> ({{data.data.user_detail.student_detail.commentaries.length}})
        </button>
    </div>

    <div *dxTemplate="let data of 'pointsCellTemplate'">
        <!--<button
            mat-raised-button
            color="accent"
            matTooltip="Gérer les points"
            matTooltipPosition="left"
            (click)="showCompetitorPoints(data.data)">
            <span class="material-icons">
                remove_red_eye
            </span>
        </button>-->
        <button (click)="addBonusPoint(data.data)">add pt</button>
    </div>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <button
            mat-raised-button
            color="accent"
            matTooltip="Vers la fiche de l'élève"
            matTooltipPosition="left"
            [routerLink]="['/schoolapp', 'student-detail',data.data.user]">
            <span class="material-icons">
                double_arrow
            </span>
        </button>
    </div>
</dx-data-grid>


<dx-popup
    width="80%"
    height="80vh"
    [showTitle]="true"
    [title]="competitorPointsTitle"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [(visible)]="competitorPopupVisible">

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view
            #scrollView
            id="scrollview"
            [showScrollbar]="true">
            <competitor-points-list [competitor]="selectedCompetitor"></competitor-points-list>
        </dx-scroll-view>
    </div>
</dx-popup>