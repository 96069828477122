import { Component, Input, OnInit } from '@angular/core';
import { DatagridService } from '../services/datagrid-service/datagrid.service';
import { DatetimeService } from '../services/datetime.service';

@Component({
  selector: 'studentworks-list',
  templateUrl: './studentworks-list.component.html',
  styleUrls: ['./studentworks-list.component.scss']
})
export class StudentworksListComponent implements OnInit {

  @Input()
  public status: number[] = [0,1,];

  @Input()
  public startDueDate: Date;

  @Input()
  public endDueDate: Date;

  @Input()
  public showYearSubject: boolean = true;

  public dueDateRangeFilter: any = [];

  public dataSource: any = {};

  public statusDetail : string[] = [
    'pas commencé',
    'commencé',
    'terminé dans les temps',
    'terminé en retard',
  ];

  constructor(private _dG: DatagridService,
              public datetimeService: DatetimeService,) { }

  ngOnInit(): void {
    this.dueDateRangeFilter = [['due_date', '>=', this.startDueDate], 'and', ['due_date', '<=', this.endDueDate]];
    console.log(this.dueDateRangeFilter);
    this.dataSource = this._dG.createStore(`api/dx-studentworks/`,'data');
  }

  calculateYearSubjectFilterExpression (filterValue, selectedFilterOperation) {
    return ["exercise__yearsubject__name", selectedFilterOperation, filterValue];
  }

  calculateThemeFilterExpression (filterValue, selectedFilterOperation) {
    return ["exercise__theme__name", selectedFilterOperation, filterValue];
  }

  dueDateFilterExpression (filterValue, selectedFilterOperation) {
    return [
            ["due_date",">=",`"${this.startDueDate}"`],
            "and",
            ["due_date","<",`"${this.endDueDate}"`]
          ];
  }
}
