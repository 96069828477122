import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.scss']
})
export class AddTaskDialogComponent implements OnInit {

  public days: any = {
    'Monday':'Lundi',
    'Tuesday':'Mardi',
    'Wednesday':'Mercredi',
    'Thursday':'Jeudi',
    'Friday':'Vendredi'
  }

  public coursePk: number;
  public course: any;
  public nextCourses: any[] = [];
  public courseCtrl: FormControl = new FormControl();
  public commentCtrl: FormControl = new FormControl();
  public students: any[];

  constructor(public dialogRef: MatDialogRef<AddTaskDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.coursePk = this.data;

    this.getCourse(this.coursePk);
    this.getNextCourses(this.coursePk);
  }

  getCourse(coursePk: number){
    this._apiService.getObjectDetail(`/api/schoolapp/courses/${coursePk}/`).subscribe(
      data => {
        this.course = data;
        this.students = data['schoolclass']['students_details'];
      }
    )
  }

  getNextCourses(coursePk: number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?n=${coursePk}`).subscribe(
      data => {
        for(let i=0; i<data.length; i++){
          if(i>0){
            if(data[i]['date']!=data[i-1]['date']){
              this.nextCourses.push(data[i]);
            }
          } else {
            this.nextCourses.push(data[i]);
          }
        }
      }
    )
  }

  save(){
    let courseId  = this.courseCtrl.value;
    let comment   = this.commentCtrl.value;
    
    this.students.forEach(
      student => {
        this._apiService.createObject(`/api/schoolapp/tasks/`,
                                      {"comment":comment,"status":2,"student":student.id,"course":courseId}).subscribe(
                                        data => {
                                          this._snackBar.open('La tâche a été ajoutée.', 'Fermer');
                                        },
                                        error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
                                      )
      }
    )
    this.dialogRef.close();
  }
}
