<mat-form-field [formGroup]="openaiForm">
    <mat-label>Description de l'exercice pour ChatGpt</mat-label>
    <textarea matInput
              cdkTextareaAutosize
              id="prompt"
              formControlName="prompt"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5">
    </textarea>
    <br>
    <hr>
    <br>
    <mat-select
        id="typeof"
        formControlName="typeof">
        <mat-option [value]="'QCM'">QCM</mat-option>
        <mat-option [value]="'Texte à trous'">Texte à trous</mat-option>
      </mat-select>
</mat-form-field>
<br>
<button
    mat-flat-button 
    color="primary"
    type="submit"
    (click)="sendPrompt()">
        Submit
</button>