<h1>Gestion des Branches / Thèmes / Catégories</h1>

<div fxLayout="row"
     fxLayoutGap="10px"
     class="content">
    <div *ngIf="yearSubjects">
        <div fxLayout="row"
             fxLayoutAlign="space-around center">
            <h3>Branches</h3>
            <button color="accent"
                    mat-raised-button
                    (click)="openCreateYearSubjectDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="dataColumn">
            <div [ngClass]="yearSubject.id == yearSubjectId ? 'warnDataItem' : 'dataItem'"
                 *ngFor="let yearSubject of yearSubjects"
                 matTooltipClass="warn"
                 matTooltip="Nombre de thèmes liés : {{yearSubject.themes_count}}"
                 (click)="getThemes(yearSubject.id)">
                <div>
                    {{yearSubject.name}}
                    <button mat-button
                            (click)="opendEditYearSubjectDialog(yearSubject.id, yearSubject.name)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>    
        </div>
    </div>

    <div *ngIf="themes">
        <div fxLayout="row"
             fxLayoutAlign="space-around center">
            <h3>Thèmes</h3>
            <button color="accent"
                    mat-raised-button
                    (click)="openCreateThemeDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="dataColumn" *ngIf="themes.length>0; else noData">
            <div [ngClass]="theme.id == themeId ? 'warnDataItem' : 'dataItem'"
                 *ngFor="let theme of themes"
                 (click)="getNewLinkCategories(theme.id)"
                 matTooltipClass="warn"
                 matTooltip="Nombre d'exercices liés : {{theme.exercises_count}}&#13;Nbre de catégories de liens liées : {{theme.newlinkcategories_count}}"
                 fxLayout="row"
                 fxLayoutAlign="space-between">
                 <div>
                     {{theme.name}}
                 </div>
                 <div>
                     <button mat-button
                             (click)="opendEditThemeDialog(theme.id, theme.name)">
                         <mat-icon>edit</mat-icon>
                     </button>
                     <button mat-button
                             (click)="deleteTheme(theme.id)">
                         <mat-icon>delete</mat-icon>
                     </button>
                 </div>
            </div>
        </div>
    </div>

    <div *ngIf="newLinkCategories">
        <div fxLayout="row"
             fxLayoutAlign="space-around center">
            <h3>Catégories</h3>
            <button color="accent"
                    mat-raised-button
                    (click)="openCreateCategoryDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="dataColumn" *ngIf="newLinkCategories.length>0; else noData">
            <div [ngClass]="category.id == newLinkCategoryId ? 'warnDataItem' : 'dataItem'"
                 matTooltipClass="warn"
                 matTooltip="Nombre de liens liés : {{category.links_count}}"
                 (click)="getLinks(category.id)"
                 *ngFor="let category of newLinkCategories"
                 fxLayout="row"
                 fxLayoutAlign="space-between">
                <div>
                    {{category.name}}
                </div>
                <div>
                    <button mat-button
                            (click)="opendEditCategoryDialog(category.id, category.name)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button
                            (click)="deleteCategory(category.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                
            </div>
        </div>
    </div>

    <div *ngIf="links">
        <div fxLayout="row"
             fxLayoutAlign="space-around center">
            <h3>Liens</h3>
            <button color="accent"
                    mat-raised-button
                    (click)="openCreateLinkDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="dataColumn" *ngIf="links.length>0; else noData">
            <div [ngClass]="link.id == linkId ? 'warnDataItem' : 'dataItem'"
                 *ngFor="let link of links"
                 fxLayout="row"
                 fxLayoutAlign="space-between"
                 (click)="getLink(link.id)">
                <div>
                    {{link.url}}
                </div>
                <div>
                    <button mat-button
                            (click)="opendEditLinkDialog(link.id, link.url)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-button
                            (click)="deleteLink(link.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                
            </div>
        </div>
    </div>

    <div *ngIf="link">
        <div>
            <h3>Lien</h3>
        </div>
        <div class="dataColumn frame">
            <iframe width="100%" height="100%" [src]="urlSafe" frameborder="0"></iframe>
        </div>
    </div>
</div>

<ng-template #noData>
    Aucune donnée
</ng-template>