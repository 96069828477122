import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-exercise-corrections-dialog',
  templateUrl: './exercise-corrections-dialog.component.html',
  styleUrls: ['./exercise-corrections-dialog.component.scss']
})
export class ExerciseCorrectionsDialogComponent implements OnInit {

  public popupExercisesCorrections: any[];

  constructor(public dialogRef: MatDialogRef<ExerciseCorrectionsDialogComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.popupExercisesCorrections = this.data['popupExercisesCorrections'];
    console.log('CORRECTION :', this.popupExercisesCorrections);
  }

}
