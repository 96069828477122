import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'student-journeys',
  templateUrl: './student-journeys.component.html',
  styleUrls: ['./student-journeys.component.scss']
})
export class StudentJourneysComponent implements OnInit {

  @Input()
  public schoolclassJourneyId: number;

  public dataSource: any;
  public journeys: any[] = [];
  public schoolclasses: any[] = [];

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    // this.loadSchoolclasses();
    // this.loadJourneys();
    this.dataSource = this._dG.createStore(`api/dx-all-student-journeys/?filter=["schoolclass_journey","=",${this.schoolclassJourneyId}]`,'data');
  }

  /**
   * Necessary for dxo-master-detail
   * @param e 
   */
  contentReady(e) {
    if (!e.component.getSelectedRowKeys().length)
        e.component.selectRowsByIndexes(0);
  }

  /**
   * Necessary for dxo-master-detail
   * @param e 
   */
  selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  loadJourneys(){
    this._apiService.getObjectsList(`api/dx-journeys/`).subscribe(
      data => {
        this.journeys = data['data'];
      }
    )
  }

  loadSchoolclasses(){
    this._apiService.getObjectsList(`api/dx-schoolclasses/`).subscribe(
      data => {
        this.schoolclasses = data['data'];
      }
    )
  }

  fakeArray(length: number): Array<any> {
    if (length >= 0) {
      return new Array(length);
    }
  }
}
