import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'exercise-correction',
  templateUrl: './exercise-correction.component.html',
  styleUrls: ['./exercise-correction.component.scss']
})
export class ExerciseCorrectionComponent implements OnInit {

  @Input()
  public exerciseCorrectionId: number;

  public exerciseCorrection: any;

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this._apiService.getObjectDetail(`api/dx-exercisecorrections/${this.exerciseCorrectionId}/`).subscribe(
      data => {
        this.exerciseCorrection = data;
        console.log('EXERCISE CORRECTION :', this.exerciseCorrection);
      }
    )
  }

}
