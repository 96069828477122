import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-yearsubject-dialog',
  templateUrl: './edit-yearsubject-dialog.component.html',
  styleUrls: ['./edit-yearsubject-dialog.component.scss']
})
export class EditYearsubjectDialogComponent implements OnInit {

  public editForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditYearsubjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.editForm = new FormGroup({
      'yearSubjectCtrl': new FormControl(this.data.name)
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    this.dialogRef.close(this.editForm.get('yearSubjectCtrl').value);
  }
}
