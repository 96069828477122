<dx-data-grid
  id="gridContainer"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  [allowColumnReordering]="false"
  [rowAlternationEnabled]="true"
  [showBorders]="true">

  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true">
  </dxo-editing>

  <dxi-column dataField="id" [width]="0"></dxi-column>
  <dxi-column dataField="start_year" caption="De"></dxi-column>
  <dxi-column dataField="end_year" caption="À"></dxi-column>
  <dxi-column dataField="content" caption="Contenu"></dxi-column>

</dx-data-grid>