<mat-toolbar class="toolbar">
    <mat-toolbar-row>
        <button mat-button [matMenuTriggerFor]="main">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #main="matMenu">
            <button mat-menu-item [routerLink]="['/']"> Accueil </button>
            <button mat-menu-item [routerLink]="['/links']"> Liens </button>
            <button mat-menu-item [routerLink]="['/exercises']"> Exercices </button>
            <button mat-menu-item [routerLink]="['/about']"> A propos </button>
            <!--<button mat-menu-item [matMenuTriggerFor]="general"> Général </button>-->
            <button mat-menu-item [matMenuTriggerFor]="myspace"> Mon espace </button>
        </mat-menu>

        <!--<mat-menu #general="matMenu">
            
        </mat-menu>-->

        <mat-menu #myspace="matMenu">
            <button mat-menu-item [routerLink]="['/studentworks']"> Devoirs </button>
            <button mat-menu-item [routerLink]="['/my-exercises-list']"> Ma liste </button>
            <button mat-menu-item [routerLink]="['/student-journeys']"> Parcours </button>
            <button mat-menu-item [routerLink]="['/edit-resume']"> Mon CV </button>
            <button *ngIf="isTeacher" mat-menu-item [routerLink]="['/admin']"> Admin </button>
        </mat-menu>

        <span class="fill-remaining-space"></span>

        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/schoolapp"
                matTooltip="Mes cours"
                class="pointer">
                    calendar_today
            </mat-icon>
        </span>
        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/admin/homeworks"
                matTooltip="Devoirs de mes élèves"
                class="pointer">
                    school
            </mat-icon>
        </span>
        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/admin/schoolclass-journeys"
                matTooltip="Parcours de mes élèves"
                class="pointer">
                    moving
            </mat-icon>
        </span>

        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/schoolapp/my-students"
                matTooltip="Mes élèves"
                class="pointer">
                    group
            </mat-icon>
        </span>

        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/admin/studentworks-messages-list"
                matTooltip="Messages"
                class="pointer">
                    email
            </mat-icon>
        </span>

        <span class="toolbar-element pointer menu-element">
            <mat-icon
                *ngIf="_userService.username&&isTeacher"
                routerLink="/admin/todo-tasks"
                matTooltip="Todo"
                class="pointer">
                    rule
            </mat-icon>
        </span>
        
        <span class="fill-remaining-space"></span>
        
        <span class="toolbar-element pointer menu-element">
            <span *ngIf="_userService.username; else login"
                  routerLink="/login"
                  class="pointer">
                Bienvenue sur Teraro, {{_userService.username}}
            </span>
            <ng-template #login>
                <span routerLink="/login" class="pointer">Login</span>
            </ng-template>
        </span>
    </mat-toolbar-row>
</mat-toolbar>