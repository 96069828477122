<h1>Planification de cours</h1>

<h2>Choisissez une plage de dates pour laquelle vous voulez créer un programme de cours (en général, début - fin de l'année scolaire)</h2>

<form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">

    <mat-form-field appearance="fill">
        <mat-label>Choisissez une date de début</mat-label>
        <input matInput
               [matDatepicker]="startDate" 
               formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Choisissez une date de fin</mat-label>
        <input matInput
               [matDatepicker]="endDate"
               formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button color="accent">Valider</button>

</form>

<!-- <div *ngIf="startDate.touched && startDate.errors">
    <div class="alert alert-danger" 
         *ngIf="startDate.errors.required">
        The company name is required
    </div>
    <div class="alert alert-danger" 
         *ngIf="endDate.touched && !endDate.valid">
        The industry is required
    </div>
</div> -->