import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'dx-tasks-list',
  templateUrl: './dx-tasks-list.component.html',
  styleUrls: ['./dx-tasks-list.component.scss']
})
export class DxTasksListComponent implements OnInit {

  @ViewChild(DxDataGridComponent)
  public dataGrid: DxDataGridComponent;

  @Input()
  public coursePk: number;

  public tasks: any = {};

  public postponedLookups: any[] = [
    {"value":true, "name":"Tâches qui ont déjà été reportées",},
    {"value":false, "name":"Tâches qui n'ont jamais été reportées",},
  ];

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.tasks = this._dG.createStore(`/api/schoolapp/dx-tasks/`)
  }

  delete(pk:number){
    if(confirm("Voulez-vous vraiment supprimer cette tâche ? Cette action est irréversible.")){
      this._apiService.deleteObject(`/api/schoolapp/tasks/${pk}/`).subscribe(
        data => {
          this._snackBar.open('La tâche a été supprimée.', 'Fermer');
          this.dataGrid.instance.refresh();
        },
        error => this._snackBar.open('Une erreur est survenue.')
      );
    }
  }

  finish(pk:number){
    this._apiService.patchObject(`/api/schoolapp/tasks/${pk}/`, {"status":3}).subscribe(
      data => {
        this._snackBar.open('La tâche a été passée à terminée.', 'Fermer');
        this.dataGrid.instance.refresh();
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  postpone(coursePk:number, pk:number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?c=${coursePk}`).subscribe(
      data => {
        this._apiService.patchObject(`/api/schoolapp/tasks/${pk}/`,
                                     {"course":data['id'],
                                      "postponed":true}).subscribe(
          d => {
            this._snackBar.open('La tâche a été repoussée au prochain cours.', 'Fermer');
            this.dataGrid.instance.refresh();
          },
          error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
        )
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  courseCalculateFilterExpression(filterValue, selectedFilterOperation){
    return ["course__id", "=", filterValue];
  }

  studentCalculateFilterExpression(filterValue, selectedFilterOperation){
    return ["student__username", "contains", filterValue];
  }
}
