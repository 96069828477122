import { Component, Input, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'dx-students-list',
  templateUrl: './dx-students-list.component.html',
  styleUrls: ['./dx-students-list.component.scss']
})
export class DxStudentsListComponent implements OnInit {

  @Input()
  public schoolclasses: any[];

  public schoolclassesIds: number[];
  public students: any = {};

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.schoolclassesIds = this.schoolclasses.map(schoolclass => schoolclass.id);
    this.students = this._dG.createStore(`/api/schoolapp/dx-users/`);
  }

  calculateFilter(filterValue){
    return ['schoolclasses__id','=',filterValue];
  }
}
