import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss']
})
export class LinksListComponent implements OnInit {

  public yearSubjects: any[];
  public themes: any[];
  public newLinkCategories: any[];
  public linkCategories: any[];
  public linkCategoriesFilters: any[];
  public links: any[];

  public selectedYearsubjectId: number;
  public selectedThemeId: number;
  public selectedCategoryId: number;

  public form: FormGroup;

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this._apiService.getObjectsList('api_school/yearsubjects/?ordering=id').subscribe(
      data => {
        this.yearSubjects = data;
      }
    );

    this.initForm();
  }

  initForm(){
    this.form = new FormGroup({
      'yearSubjectCtrl': new FormControl(null),
      'themeCtrl': new FormControl(null),
      'newLinkCategoryCtrl': new FormControl(null),
    })
  }

  onYearSubjectChange(yearSubject){
    this.themes = [];
    this.newLinkCategories = [];
    this.links = undefined;
    this.linkCategories = [];
    this.linkCategoriesFilters = [];

    const yearSubjectId = yearSubject.value;
    this.selectedYearsubjectId = yearSubject.value;
    const endPoint      = `api/dx-themes-for-links/?filter=["yearsubject","=",${yearSubjectId}]`;

    this._apiService.getObjectDetail(endPoint).subscribe(
      (data: any[]) => {
        this.themes = data['data'];
      }
    )
  }

  onThemeChange(theme){
    this.newLinkCategories = [];
    this.links = undefined;

    const themeId       = theme.value;
    this.selectedThemeId = theme.value;
    const endPoint      = `api/dx-links/?filter=[["yearsubject_id","=",${this.selectedYearsubjectId}],"and",["theme_id","=",${this.selectedThemeId}]]`;

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        this.linkCategories = [];
        this.linkCategoriesFilters = [];
        data['data'].forEach(link => {
          if(!this.linkCategories.includes(link.linkcategory)){
            this.linkCategoriesFilters.push(`["id","=",${link.linkcategory}]`)
          }
        });
        let filters = this.linkCategoriesFilters.join(`,"or",`);
        
        const categoriesEndPoint = `api/dx-links-categories/?filter=[${filters}]`;

        this._apiService.getObjectsList(categoriesEndPoint).subscribe(
          data => {
            this.linkCategories = data['data'];
          }
        )
      }
    )
  }

  onNewLinkCategoryChange(category){
    const categoryId    = category.value;
    this.selectedCategoryId = category.value;
    const endPoint      = `api/dx-links/?filter=[["yearsubject_id","=",${this.selectedYearsubjectId}],"and",["theme_id","=",${this.selectedThemeId}],"and",["linkcategory","=",${categoryId}]]`;

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        this.links = data['data'];
      }
    )
  }
}
