import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss']
})
export class ObjectComponent implements OnInit {

  @Input() public obj: any;
  @Input() public mapping: string;

  public externalId: string;

  constructor() { }

  ngOnInit(): void {
    this.externalId = _.get(this.obj, 'external_id')
    console.log(this.obj);
  }

}
