import DataSource from "devextreme/data/data_source";
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from "@angular/material/dialog";
import { OpenaiExerciseFormComponent } from "src/app/school/_dialogs/openai-exercise-form/openai-exercise-form.component";

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  @Input()
  public exerciseId: string = undefined;

  public exercise: any;

  constructor(private _route: ActivatedRoute,
              private _apiService: ApiService,
              private _dG: DatagridService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    if(!this.exerciseId){
      this.exerciseId = this._route.snapshot.paramMap.get('exerciseId');
    }
    this.loadExercise();
  }

  loadExercise(){
    this._apiService.getObjectDetail(`api/dx-exercises/${this.exerciseId}/`).subscribe(
      data => {
        this.exercise = data;
      }
    )
  }

  createQuestion(){
    const data = {
      "exercise":this.exerciseId
    };

    this._apiService.createObject(`api/dx-questions/`,data).subscribe(
      data => {
        this.loadExercise();
      }
    )
  }

  deleteQuestion(questionId:number){
    if(confirm("Êtes-vous certain(e) de vouloir supprimer cette question ? Cette action est irréversible.")){
      this._apiService.deleteObject(`api/dx-questions/${questionId}/`).subscribe(
        data => {
          this.loadExercise();
          this._snackBar.open("Question supprimée avec succès","Fermer")
        },
        err => this._snackBar.open("Une erreur est survenue","Fermer")
      );
    }
  }

  openDialog(stepId: number): void {
    const dialogRef = this.dialog.open(OpenaiExerciseFormComponent, {
      width: '80%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this._apiService.createObject(`/api/generate-exercise/${this.exerciseId}/`,{"theme":result["prompt"],"typeof":result["typeof"]}).subscribe({
          next: (data:any) => {
            this.loadExercise();
          }
        });
      }
    });
  }
}
