import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'app-attempt',
  templateUrl: './attempt.component.html',
  styleUrls: ['./attempt.component.scss']
})
export class AttemptComponent implements OnInit {

  public studentStep: any;
  public exercises: any[];
  public selectedExercises: any[];
  public correctedExercisesCount: number = 0;
  public correctAnswersPercentages: number[] = [];
  public percentages

  constructor(private _snackBar: MatSnackBar,
              private _apiService: ApiService,
              private _route: ActivatedRoute,
              private _router: Router,) { }

  ngOnInit(): void {
    // this.studentStep = this.data['studentStep'];
    this._apiService.getObjectDetail(`api/dx-student-steps/${this._route.snapshot.params.studentStepId}/`).subscribe(
      data => {
        this.studentStep = data;

        this.exercises   = this.studentStep.exercises_details;
        if(this.exercises.length > 0 && this.studentStep.min_correct_count_to_complete > 0 && this.exercises.length >= this.studentStep.min_correct_count_to_complete){
          this.selectedExercises = this.exercises.sort(() => Math.random() - Math.random()).slice(0, this.studentStep.min_correct_count_to_complete);
          this.selectedExercises.forEach(exercise => {
            exercise.isCorrected = false;
          });
        } else {
          this._snackBar.open("Une erreur est survenue, l'étape ne peut être complétée normalement. Veuillez en informer votre enseignant(e).","Fermer");
        }
      },
      err => {
        this._router.navigate(['/student-journeys']);
        this._snackBar.open("Tu ne peux pas encore (re)faire cette étape.","Fermer");
      }
    )
  }

  getCorrectedValue(exerciseCorrection:any){
    this.correctedExercisesCount++;
    this.correctAnswersPercentages.push(exerciseCorrection.right_answers_percentage);
    this._snackBar.open(`Tu as terminé ${this.correctedExercisesCount} exercice sur les ${this.selectedExercises.length} demandés.`);
  
    this.attachExerciseCorrectionToStudentStep(exerciseCorrection);

    if (this.correctedExercisesCount === this.selectedExercises.length){
      this.correctStep();
    }
  }

  attachExerciseCorrectionToStudentStep(exerciseCorrection:any){
    this._apiService.patchObject(`api/student-steps/${this.studentStep.id}/${exerciseCorrection.id}/`,{}).subscribe(
      data => {
      }
    )
  }

  correctStep(){
    let sum = 0;
    this.correctAnswersPercentages.forEach(percentage => {
      sum += percentage;
    });

    const average = sum / this.selectedExercises.length;
    const status = average >= this.studentStep.min_percentage ? 2 : 1;

    const data = {
      "status":status,
    }

    const endPoint = `api/dx-student-steps/${this.studentStep.id}/`;

    this._apiService.patchObject(endPoint,data).subscribe(
      data => {

        if(data['status'] === 2){
          this._snackBar.open("Bravo, tu as terminé cette étape du parcours.","Fermer");
        } else {
          this._snackBar.open(`Il faut refaire cette étape, tu n'a pas atteint le pourcentage minimal requis (${average}% sur ${this.studentStep.min_percentage}% requis).`,"Fermer");
        }
        setTimeout(() => {
          this._router.navigate(['/student-journeys']);
        }, 3000);
      }
    )
  }
}
