import { Component, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'dx-todo-tasks-list',
  templateUrl: './dx-todo-tasks-list.component.html',
  styleUrls: ['./dx-todo-tasks-list.component.scss']
})
export class DxTodoTasksListComponent implements OnInit {

  public todoTasks: any = {};

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.loadTodoTasks();
  }

  loadTodoTasks(){
    this.todoTasks = this._dG.createStore('/api/schoolapp/dx-todo-tasks/');
  }
}
