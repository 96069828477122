import { Component, OnInit } from '@angular/core';
// import { ApiService } from 'src/app/services/api-service/api.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  public daysOfWeek: any[];
  public courses: any[];

  public frenchDays: string[] = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi',]
  public nbrOfCourseHours: number[] = [0,1,2,3,4,5,6,7,8,];
  public concernedDate: any;
  public concernedDateString: string;

  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.setConcernedDate();
    this.getCourses();
  }

  getCourses(){
    let endPoint = `/api/schoolapp/courses/?d=${this.concernedDateString}`;

    this._apiService.getObjectsList(endPoint).subscribe(
      data => {
        console.log('LISTE DES COURS : ', data);
        this.daysOfWeek = data['days_of_week'].map(day => {
          return formatDate(new Date(day), 'dd-MM-yyyy', 'en-us');
        });
        this.courses    = data['courses'];
      }
    );
  }

  setConcernedDate(modif?: number){
    let concernedDateString = this.getConcernedDate()

    if(concernedDateString){
      this.concernedDate = new Date(concernedDateString);
      if(modif){
        this.concernedDate.setDate(this.concernedDate.getDate() + modif);
      }
    } else {
      this.concernedDate = new Date();
    }

    localStorage.setItem('concernedDateString', formatDate(this.concernedDate, 'yyyy-MM-dd', 'en-us'));
    this.getConcernedDate();
    this.getCourses();

    console.log('CONCERNED DATE SETTED : ', this.concernedDate);
    console.log('CONCERNED DATE STRING : ', localStorage.getItem('concernedDateString'));
  }

  getConcernedDate(){
    this.concernedDateString = localStorage.getItem('concernedDateString');

    if(this.concernedDateString){
      return new Date(this.concernedDateString);
    }

    return undefined;
  }

  nextWeek(){
    this.setConcernedDate(7);
  }

  previousWeek(){
    this.setConcernedDate(-7);
  }

  resetWeek(){
    localStorage.removeItem('concernedDateString');
    this.setConcernedDate();
  }
}
