import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume3',
  templateUrl: './resume3.component.html',
  styleUrls: ['./resume3.component.scss']
})
export class Resume3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
