import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication-mediums-list',
  templateUrl: './communication-mediums-list.component.html',
  styleUrls: ['./communication-mediums-list.component.scss']
})
export class CommunicationMediumsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
