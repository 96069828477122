<div *ngIf="dataSource" class="content-block">
    <h2>
        Liste des Parcours
    </h2>

    <dx-data-grid 
        #gridContainer
        id="gridContainer"
        [dataSource]="dataSource"
        [showBorders]="true"
        [remoteOperations]="true"
        dateSerializationFormat="yyyy-MM-dd"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [rowAlternationEnabled]="false">
        <dxo-header-filter 
        [visible]="true"></dxo-header-filter>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager 
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[5, 10, 20]"
            [showInfo]="true"
            [visible]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing
            [allowUpdating]="true"
            [allowAdding]="true"
            [allowDeleting]="true"
            mode="row"> <!-- 'row' | 'batch' | 'cell' | 'form' | 'popup' -->
        </dxo-editing>
        <dxo-master-detail
            [enabled]="true"
            [autoCollapseAll]="true"
            [template]="'detail'">
        </dxo-master-detail>
        <dxi-column
            dataField="name"
            dataType="string"
            caption="Nom"
            class="pointer"
            [allowHeaderFiltering]="false"
            [allowFiltering]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column
            dataField="description"
            dataType="string"
            caption="Description"
            class="pointer"
            [allowHeaderFiltering]="false"
            [allowFiltering]="true">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column
            dataField="teacher"
            dataType="number"
            caption="Créé par"
            cellTemplate="teacherCellTemplate">
        </dxi-column>
        <!--<dxi-column 
            dataField="min_interval_between_attemps"
            dataType="number"
            caption="Intervalle min entre 2 tentatives"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="attempsIntervalCellTemplate">
        </dxi-column>
        <dxi-column 
            dataField="min_interval_between_steps"
            dataType="number"
            caption="Intervalle min entre 2 étapes"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="stepsIntervalCellTemplate">
        </dxi-column>-->
        <!--<dxi-column 
            dataField="id"
            dataType="number"
            caption="Actions"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="actionsTemplate">
        </dxi-column>-->
        
        <div *dxTemplate="let data of 'actionsTemplate'">
            <mat-icon aria-hidden="false"
                      aria-label="Example home icon"
                      class="pointer">edit</mat-icon>
        </div>

        <div *dxTemplate="let data of 'attempsIntervalCellTemplate'">
            {{data.data.min_interval_between_attemps}} jour(s)
        </div>

        <div *dxTemplate="let data of 'stepsIntervalCellTemplate'">
            {{data.data.min_interval_between_steps}} jour(s)
        </div>

        <div *dxTemplate="let data of 'teacherCellTemplate'">
            <ng-container *ngIf="data.data.teacher">
                {{data.data.teacher_details.username}}
            </ng-container>
        </div>

        <div *dxTemplate="let data of 'detail'">
            <steps [journeyId]="data.data.id"></steps>
        </div>
    </dx-data-grid>
</div>
