import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-student-comments',
  templateUrl: './student-comments.component.html',
  styleUrls: ['./student-comments.component.scss']
})
export class StudentCommentsComponent implements OnInit {

  public student: any;

  constructor(public dialogRef: MatDialogRef<StudentCommentsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.student = this.data;
    console.log('STUDENT :', this.student);
  }

}
