import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrls: ['./meetings-list.component.scss']
})
export class MeetingsListComponent implements OnInit {

  @Input()
  public courseDate: string;

  @ViewChild(DxDataGridComponent)
  public dataGrid: DxDataGridComponent;

  public dataSource: any = {};

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.parseDate();
    this.loadMeetings();
    
  }

  parseDate(){
    const parts       = this.courseDate.split('-');
    this.courseDate   = `${parts[2]}-${parts[1]}-${parts[0]}`;
    console.log('COURSE DATE :', this.courseDate);
  }

  loadMeetings(){
    this.dataSource   = this._dG.createStore(`/api/schoolapp/dx-meetings/?filter=["date","=","${this.courseDate}"]`);
  }

  deleteRow(meetingId:string){
    if(confirm("Êtes-vous certain(e) ?")){
      this._apiService.deleteObject(`/api/schoolapp/dx-meetings/${meetingId}/`).subscribe({
        next: (data:any) => {
          this.dataGrid.instance.refresh();
        },
        error: (err:any) => {
          notify("Une erreur est survenue","error");
        }
      })
    }
  }

  onInitNewRow(e:any){
    e.data.date = this.courseDate;
  }
}
