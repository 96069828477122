import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'dx-sanctions-list',
  templateUrl: './dx-sanctions-list.component.html',
  styleUrls: ['./dx-sanctions-list.component.scss']
})
export class DxSanctionsListComponent implements OnInit {

  @ViewChild(DxDataGridComponent)
  public datagrid: DxDataGridComponent;

  @Input()
  public userId: number;

  @Input()
  public courseId: number;

  public sanctions: any = {};

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private _router: Router,) { }

  ngOnInit(): void {
    this.sanctions = this._dG.createStore(`/api/schoolapp/dx-sanctions/`);
  }

  userCalculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;
    // Override implementation for the "between" filter operation
    if (selectedFilterOperation === "=") {
        const filterExpression = ["student__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  courseCalculateFilterExpression(filterValue, selectedFilterOperation){
    return ["course__id", "=", filterValue];
  }

  delete(pk:number){
    this._apiService.deleteObject(`/api/schoolapp/sanctions/${pk}/`).subscribe(
      data => {
        this.datagrid.instance.refresh();
        this._snackBar.open('La punition a été supprimée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.')
    )
  }

  finish(pk:number){
    this._apiService.patchObject(`/api/schoolapp/sanctions/${pk}/`, {"status":3}).subscribe(
      data => {
        this.datagrid.instance.refresh();
        this._snackBar.open('La punition a été passée à terminée.', 'Fermer');
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  postpone(coursePk:number, pk:number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?c=${coursePk}`).subscribe(
      data => {
        this._apiService.patchObject(`/api/schoolapp/sanctions/${pk}/`,
                                     {"course":data['id'],
                                      "postponed":true}).subscribe(
          d => {
            this.datagrid.instance.refresh();
            this._snackBar.open('La punition a été repoussée au prochain cours.', 'Fermer');
          },
          error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
        )
      },
      error => this._snackBar.open('Une erreur est survenue.', 'Fermer')
    )
  }

  redirectToCourse(courseId: number){
    this._router.navigate(['/schoolapp','edit-course',courseId]);
  }
}
