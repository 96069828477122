<dx-data-grid
    [dataSource]="commentaries"
    [remoteOperations]="true"
    (onRowInserting)="insertRow($event)"
    (onRowUpdating)="updateRow($event)">
    <dxo-editing
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="true">
    </dxo-editing>

    <dxi-column
        dataField="created_at"
        dataType="date"
        format="dd.MM.yyyy"
        caption="Date"
        [allowEditing]="false">
    </dxi-column>
    <dxi-column
        dataField="content"
        dataType="string"
        caption="Commentaire">
    </dxi-column>
    <dxi-column
        dataField="student"
        dataType="number"
        [filterValue]="student.id"
        [calculateFilterExpression]="calculateFilterExpression"
        [width]="0">
    </dxi-column>
</dx-data-grid>