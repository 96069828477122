import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-week-template',
  templateUrl: './week-template.component.html',
  styleUrls: ['./week-template.component.scss']
})
export class WeekTemplateComponent implements OnInit {

  public dateRangeForm: FormGroup;
  public isLockedForm: FormGroup;

  public locked: boolean = true;

  public daysOfWeek: string[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi',];
  public nbrOfCourseHours: number[] = [0,1,2,3,4,5,6,7,8,];
  // public coursesPrograms: any[];
  public schoolClasses: any[];

  public coursesFormGroup: FormGroup;
  public coursesToGenerate: string[] = [];

  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.coursesFormGroup = new FormGroup({});
    // this._apiService.getObjectsList('/api/schoolapp/courses-programs/').subscribe(
    //   (data: any[]) => {
    //     this.coursesPrograms = data;
    //     // console.log('DATA : ', data);
    //   }
    // );
    this._apiService.getObjectsList(`/api/dx-schoolclasses/`).subscribe(
      (data:any[]) => {
        this.schoolClasses = data['data'];
      }
    )

    this.initForms();
    this.onChanges();
  }

  initForms(){
    let today = new Date();
    let endOfYear = new Date();
    endOfYear.setDate(endOfYear.getDate() + 15);

    this.dateRangeForm = new FormGroup({
      'startDate': new FormControl({value:today, disabled:true}, Validators.required),
      'endDate': new FormControl({value:endOfYear, disabled:true}, Validators.required)
    })

    this.isLockedForm = new FormGroup({
      'isLocked': new FormControl(false)
    })

    this.daysOfWeek.forEach(
      (day, index) => {
        this.nbrOfCourseHours.forEach(
          num => {
            let ctrl = new FormControl({value: null, disabled: true});
            this.coursesFormGroup.addControl(`day_${index}_course_${num}`, ctrl);
          }
        )
      }
    )
  }

  onSubmit(){
    if(confirm("Êtes-vous certain(e) de vouloir générer votre programme ? Cela effacera un éventuel programme pour la période concernée.")){
      this.coursesToGenerate = [];

      Object.keys(this.coursesFormGroup.controls).forEach(key => {
        if(this.coursesFormGroup.controls[key].value){
          // console.log(this.coursesFormGroup.controls[key]);
          this.coursesToGenerate.push(this.coursesFormGroup.controls[key].value);
          // jour (jour de la semaine de 0 à 4) || courseHour (comptage d'heur de la journée de 0 à 8) || id du cours
          
          
        }
      });

      let startDate = this.dateRangeForm.controls.startDate.value.getFullYear() + "-" + (this.dateRangeForm.controls.startDate.value.getMonth() + 1) + "-" + this.dateRangeForm.controls.startDate.value.getDate()
      let endDate   = this.dateRangeForm.controls.endDate.value.getFullYear() + "-" + (this.dateRangeForm.controls.endDate.value.getMonth() + 1) + "-" + this.dateRangeForm.controls.endDate.value.getDate()
      
      let data = {
          "startDate":startDate,
          "endDate":endDate,
          "coursesToGenerate":this.coursesToGenerate
      }

      this._apiService.generateCourses(data).subscribe(
                                        data => {
                                          console.log(data);
                                          alert('Terminé avec succès');
                                        }
                                      );
    }
    // console.log('COURS À GENERER : ', this.coursesToGenerate);
    // console.log('DATE RANGE FORME : ', this.dateRangeForm);
  }

  toggleLock(){
    console.log('toggle');
  }

  onChanges(): void {
    this.isLockedForm.get('isLocked').valueChanges.subscribe(val => {
      if(!val){
        this.locked = true;
        this.dateRangeForm.controls['startDate'].disable();
        this.dateRangeForm.controls['endDate'].disable();
        for (var control in this.coursesFormGroup.controls) {
          this.coursesFormGroup.controls[control].disable();
        }
      } else {
        this.locked = false;
        this.dateRangeForm.controls['startDate'].enable();
        this.dateRangeForm.controls['endDate'].enable();
        for (var control in this.coursesFormGroup.controls) {
          this.coursesFormGroup.controls[control].enable();
        }
      }
    });
  }
}
