<h1>Exercise</h1>

<div *ngIf="exercise" class="statement-container">
  <mat-tab-group>
    <mat-tab label="Consigne">
      <div class="tab-container">
        {{exercise.statement}}
      </div>
    </mat-tab>
    <mat-tab class="tab-container">
      <ng-template mat-tab-label>
        <span matBadge="{{exercise.medias.length}}"
              matBadgeOverlap="false">Médias</span>
      </ng-template>
      <div *ngIf="exercise.medias&&exercise.medias.length>0; else noMedia"
           class="tab-container">
        <media *ngFor="let media of exercise.medias" [media]="media"></media>
      </div>
    </mat-tab>
    <mat-tab class="tab-container">
      <ng-template mat-tab-label>
        <span matBadge="{{exercise.links.length}}"
              matBadgeOverlap="false">Liens</span>
      </ng-template>
      <div *ngIf="exercise.links&&exercise.links.length>0; else noLink"
           class="tab-container">
        <div *ngFor="let link of exercise.links">
          <a href="{{link.url}}" target=_blank>Lien</a>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noMedia>
  <div class="tab-container">
    Il n'y a aucun fichier média pour cet exercice.
  </div>
</ng-template>

<ng-template #noLink>
  <div class="tab-container">
    Il n'y a aucun lien vers une source externe pour cet exercice.
  </div>
</ng-template>

<div *ngIf="exercise" class="exercise-container">
  <ng-container *ngIf="exerciseCorrection; else showOnlyQuestions">
    <div *ngFor="let question of exercise.questions_details">
      <question [question]="question"
                [exerciseCorrection]="exerciseCorrection"
                (correctedQuestionObj)="correctionFromQuestion($event)">
      </question>
    </div>
    <button *ngIf="!isCorrected; else doItAgain"
            mat-flat-button
            color="primary"
            (click)="correctExercise()">
      Corriger
    </button>
  </ng-container>
</div>

<ng-template #doItAgain>
  <button mat-flat-button
          color="warn"
          (click)="startAgain()">
    Recommencer
  </button>
  &nbsp;&nbsp;&nbsp;
  <button *ngIf="studentworkId"
          mat-flat-button
          color="primary"
          [routerLink]="['/studentworks',]">
    Retour à la liste des devoirs
  </button>
</ng-template>

<ng-template #showOnlyQuestions>
  <div *ngFor="let question of exercise.questions_details">
    <div>
      <question [question]="question">
      </question>
    </div>
  </div>
</ng-template>
