<h1>Examens</h1>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            Voulez-vous créer un examen à partir de ces exercices ?</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="exercisesIds && exercisesIds.length > 0; else noData">
            <ng-container *ngFor="let exerciseId of exercisesIds">
                <exercise-infos [exerciseId]="exerciseId"></exercise-infos>
            </ng-container>
        </ng-container>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="createExam()">Créer l'examen</button>
        <button mat-button (click)="flushStorage()">Supprimer la liste temporaire</button>
      </mat-card-actions>
</mat-card>

<ng-template #noData>
    La liste de sélection est vide.
</ng-template>

<br>
<examinations-list></examinations-list>
