import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api-service/api.service';
import { EditLinkDialogComponent } from 'src/app/_dialogs/edit-link-dialog/edit-link-dialog.component';
import { EditNewlinkscategoryComponent } from 'src/app/_dialogs/edit-newlinkscategory/edit-newlinkscategory.component';
import { EditThemeComponent } from 'src/app/_dialogs/edit-theme/edit-theme.component';
import { EditYearsubjectDialogComponent } from 'src/app/_dialogs/edit-yearsubject-dialog/edit-yearsubject-dialog.component';

@Component({
  selector: 'app-admin-school',
  templateUrl: './admin-school.component.html',
  styleUrls: ['./admin-school.component.scss']
})
export class AdminSchoolComponent implements OnInit {

  public yearSubjectId: number;
  private _yearSubjectName: string;
  public themeId: number;
  private _themeName: string;
  public newLinkCategoryId: number;
  private _newLinkCategoryName: string;
  public linkId: number;
  private _linkUrl: string;
  public link: any;
  public urlSafe: SafeResourceUrl;

  public yearSubjects: any[];
  public themes: any[];
  public newLinkCategories: any[];
  public links: any[];

  public editForm: FormGroup;

  constructor(private _apiService:ApiService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getYearSubjects();
    this.initEditForm();
  }

  initEditForm(){
    this.editForm = new FormGroup({})
  }

  getYearSubjects(){
    this.yearSubjects = [];
    this.themes = null;
    this.newLinkCategories = null;
    this.links = null;
    this.link = null;

    this._apiService.getObjectsList(`api_school/yearsubjects/?ordering=id`).subscribe(
      data => {
        this.yearSubjects = data;
      }
    )
  }

  getThemes(yearSubjectId: number){
    this.yearSubjectId = yearSubjectId;
    this.themes = null;
    this.newLinkCategories = null;
    this.links = null;
    this.link = null;

    this._apiService.getObjectsList(`api_school/themes/?yearsubject=${yearSubjectId}&?ordering=id`).subscribe(
      data => {
        this.themes = data;
      }
    )
  }

  getNewLinkCategories(themeId: number){
    this.themeId = themeId;
    this.newLinkCategories = [];
    this.links = null;
    this.link = null;

    this._apiService.getObjectsList(`api_school/newlinkcategories/?theme=${themeId}`).subscribe(
      data => {
        this.newLinkCategories = data;
      }
    )
  }

  getLinks(newLinkCategoryId: number){
    this.newLinkCategoryId = newLinkCategoryId;
    this.links = null;
    this.link = null;

    this._apiService.getObjectsList(`api_school/links/?newlinkcategory=${newLinkCategoryId}`).subscribe(
      data => {
        this.links = data;
      }
    );
  }

  getLink(linkId: number){
    this.linkId = linkId;

    this._apiService.getObjectDetail(`api_school/links/${linkId}/`).subscribe(
      data => {
        this.link = data;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.link.url);
      }
    )
  }

  opendEditYearSubjectDialog(yearSubjectId?: number, yearSubjectName?: string){
    this.yearSubjectId   = yearSubjectId;
    this._yearSubjectName = yearSubjectName;

    const dialogRef = this.dialog.open(EditYearsubjectDialogComponent, {
      width: '250px',
      data: {name: yearSubjectName, yearSubjectId: yearSubjectId}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'id':yearSubjectId,'name':result};

         this._apiService.patchObject(`api_school/yearsubjects/${yearSubjectId}/`,data).subscribe(
           data => {
             this._snackBar.open('Changement enregistré','Fermer');
             this.getYearSubjects();
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  openCreateYearSubjectDialog(){
    const dialogRef = this.dialog.open(EditYearsubjectDialogComponent, {
      width: '250px',
      data: {name: null, yearSubjectId: null}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'name':result};

         this._apiService.createObject(`api_school/yearsubjects/`,data).subscribe(
           data => {
             this._snackBar.open('Branche ajoutée','Fermer');
             this.getYearSubjects();
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  opendEditThemeDialog(themeId?: number, themeName?: string){
    this.themeId   = themeId;
    this._themeName = themeName;

    const dialogRef = this.dialog.open(EditThemeComponent, {
      width: '250px',
      data: {name: themeName, themeId: themeId}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'id':themeId,'name':result};

         this._apiService.patchObject(`api_school/themes/${themeId}/`,data).subscribe(
           data => {
             this._snackBar.open('Changement enregistré','Fermer');
             this.getThemes(this.yearSubjectId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  openCreateThemeDialog(){
    const dialogRef = this.dialog.open(EditThemeComponent, {
      width: '250px',
      data: {name: null, themeId: null}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'name':result};

         this._apiService.createObject(`api_school/themes/`,data).subscribe(
           data => {
             this._snackBar.open('Thème ajouté','Fermer');
             this.getThemes(this.yearSubjectId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  opendEditCategoryDialog(newLinkCategoryId?: number, newLinkCategoryName?: string){
    this.newLinkCategoryId   = newLinkCategoryId;
    this._newLinkCategoryName = newLinkCategoryName;

    const dialogRef = this.dialog.open(EditNewlinkscategoryComponent, {
      width: '250px',
      data: {name: newLinkCategoryName, newLinkCategoryId: newLinkCategoryId}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'id':newLinkCategoryId,'name':result};

         this._apiService.patchObject(`api_school/newlinkcategories/${newLinkCategoryId}/`,data).subscribe(
           data => {
             this._snackBar.open('Changement enregistré','Fermer');
             this.getNewLinkCategories(this.themeId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  openCreateCategoryDialog(){
    const dialogRef = this.dialog.open(EditNewlinkscategoryComponent, {
      width: '250px',
      data: {name: null, newLinkCategoryId: null}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'name':result,'theme':this.themeId};

         this._apiService.createObject(`api_school/newlinkcategories/`,data).subscribe(
           data => {
             this._snackBar.open('Catégorie ajouté','Fermer');
             this.getNewLinkCategories(this.themeId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  opendEditLinkDialog(linkId?: number, linkUrl?: string){
    this.linkId    = linkId;
    this._linkUrl = linkUrl;

    const dialogRef = this.dialog.open(EditLinkDialogComponent, {
      width: '250px',
      data: {url: linkUrl, linkId: linkId}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'id':linkId,'url':result};

         this._apiService.patchObject(`api_school/links/${linkId}/`,data).subscribe(
           data => {
             this._snackBar.open('Changement enregistré','Fermer');
             this.getLinks(this.newLinkCategoryId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  openCreateLinkDialog(){
    const dialogRef = this.dialog.open(EditLinkDialogComponent, {
      width: '250px',
      data: {url: null, linkId: null}
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         const data = {'url':result,
                       'newlinkcategory':this.newLinkCategoryId,
                       'yearsubject':this.yearSubjectId,
                       'theme':this.themeId};

         this._apiService.createObject(`api_school/links/`,data).subscribe(
           data => {
             this._snackBar.open('Lien ajouté','Fermer');
             this.getLinks(this.newLinkCategoryId);
           },
           err => {
             this._snackBar.open('Une erreur est survenue','Fermer');
           }
         );
       }
    });
  }

  deleteTheme(themeId: number){
    if(confirm("Voulez-vous vraiment supprimer ce thème ?")){
      this._apiService.deleteObject(`api_school/themes/${themeId}/`).subscribe(
        data => {
          this.getThemes(this.yearSubjectId);
        }
      )
    }
  }

  deleteCategory(categoryId: number){
    if(confirm("Voulez-vous vraiment supprimer cette catégorie ?")){
      this._apiService.deleteObject(`api_school/newlinkcategories/${categoryId}/`).subscribe(
        data => {
          this.getNewLinkCategories(this.themeId);
        }
      )
    }
  }

  deleteLink(linkId: number){
    if(confirm("Voulez-vous vraiment supprimer ce lien ?")){
      this._apiService.deleteObject(`api_school/links/${linkId}/`).subscribe(
        data => {
          this.getLinks(this.newLinkCategoryId);
        }
      )
    }
  }
}
