import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpConfigInterceptor } from './httpconfig.interceptor';

// Layout components
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';

// Auth && JWT
import { AuthComponent } from './auth/auth.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

// Angular material Modules
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';

// import { MatVideoModule } from 'mat-video';

import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserService } from './services/user-service/user.service';
import { ApiService } from './services/api-service/api.service';
import { AdminIndexComponent } from './admin/admin-index/admin-index.component';
import { GeneralIndexComponent } from './general/general-index/general-index.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AdminLinksComponent } from './admin/admin-links/admin-links.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminSchoolComponent } from './admin/admin-school/admin-school.component';
import { EditYearsubjectDialogComponent } from './_dialogs/edit-yearsubject-dialog/edit-yearsubject-dialog.component';
import { EditThemeComponent } from './_dialogs/edit-theme/edit-theme.component';
import { EditNewlinkscategoryComponent } from './_dialogs/edit-newlinkscategory/edit-newlinkscategory.component';
import { EditLinkDialogComponent } from './_dialogs/edit-link-dialog/edit-link-dialog.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { SafePipeModule } from 'safe-pipe';
import { QuestionComponent } from './question/question.component';
import { ChunkComponent } from './chunk/chunk.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxBulletModule, DxButtonModule, DxChartModule, DxCheckBoxModule, DxColorBoxModule, DxHtmlEditorModule, DxListModule, DxLookupModule, DxNumberBoxModule, DxPivotGridModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTemplateModule, DxTreeListModule } from 'devextreme-angular';
import { ExercisesListComponent } from './exercises-list/exercises-list.component';
import { DatagridService } from './services/datagrid-service/datagrid.service';
import { MediaComponent } from './media/media.component';
import { StudentworksListComponent } from './studentworks-list/studentworks-list.component';
import { StudentworksHomeComponent } from './studentworks-home/studentworks-home.component';
import { HomeworksComponent } from './admin/homeworks/homeworks.component';
import { SchoolclassesComponent } from './admin/schoolclasses/schoolclasses.component';
import { EditSchoolclassComponent } from './admin/edit-schoolclass/edit-schoolclass.component';
import { CreateHomeworkComponent } from './admin/create-homework/create-homework.component';
import { ExerciseDialogComponent } from './_dialogs/exercise-dialog/exercise-dialog.component';
import { StudentworksComponent } from './admin/studentworks/studentworks.component';
import { ExerciseFeedbackComponent } from './_dialogs/exercise-feedback/exercise-feedback.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HomeworksSummaryComponent } from './admin/homeworks-summary/homeworks-summary.component';
import { JourneysComponent } from './admin/journeys/journeys.component';
import { StepsComponent } from './admin/steps/steps.component';
import { StepExercisesDialogComponent } from './_dialogs/step-exercises-dialog/step-exercises-dialog.component';
import { StudentJourneysComponent } from './admin/student-journeys/student-journeys.component';
import { StudentStepsComponent } from './admin/student-steps/student-steps.component';
import { SchoolclassJourneysComponent } from './admin/schoolclass-journeys/schoolclass-journeys.component';
import { JourneysHomeComponent } from './journeys-home/journeys-home.component';
import { JourneysListComponent } from './journeys-list/journeys-list.component';
import { StudentJourneyComponent } from './student-journey/student-journey.component';
import { StudentStepComponent } from './student-step/student-step.component';
import { AttemptComponent } from './attempt/attempt.component';
import { ExercisesComponent } from './admin/exercises/exercises.component';
import { LinksHomeComponent } from './links-home/links-home.component';
import { LinksListComponent } from './links-list/links-list.component';
import { AboutComponent } from './about/about.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { QuestionsListComponent } from './questions-list/questions-list.component';
import { EditQuestionContentComponent } from './admin/edit-question-content/edit-question-content.component';
import { EditQuestionDetailComponent } from './admin/edit-question-detail/edit-question-detail.component';
import { ChoicesComponent } from './admin/choices/choices.component';
import { QuestionCorrectionComponent } from './admin/question-correction/question-correction.component';
import { ExerciseCorrectionComponent } from './admin/exercise-correction/exercise-correction.component';
import { QuestionDetailCorrectionComponent } from './admin/question-detail-correction/question-detail-correction.component';
import { ExerciseCorrectionsDialogComponent } from './_dialogs/exercise-corrections-dialog/exercise-corrections-dialog.component';
import { MyExercisesListComponent } from './my-exercises-list/my-exercises-list.component';
import { EditResumeComponent } from './edit-resume/edit-resume.component';
import { Resume1Component } from './_resumes/resume1/resume1.component';
import { Resume2Component } from './_resumes/resume2/resume2.component';
import { Resume3Component } from './_resumes/resume3/resume3.component';
import { Resume4Component } from './_resumes/resume4/resume4.component';
import { PersonalQualitiesListComponent } from './_datagrids/personal-qualities-list/personal-qualities-list.component';
import { LanguagesListComponent } from './_datagrids/languages-list/languages-list.component';
import { SchoolPathsListComponent } from './_datagrids/school-paths-list/school-paths-list.component';
import { InternshipsListComponent } from './_datagrids/internships-list/internships-list.component';
import { SkillsListComponent } from './_datagrids/skills-list/skills-list.component';
import { HobbiesListComponent } from './_datagrids/hobbies-list/hobbies-list.component';
import { ReferencesListComponent } from './_datagrids/references-list/references-list.component';
import { EditPersonalDataComponent } from './edit-personal-data/edit-personal-data.component';
import { ResumePictureComponent } from './resume-picture/resume-picture.component';

// school app components
import { SimpleTableComponent } from './school/_tables/simple-table/simple-table.component';
import { TextComponent } from './school/_partials/text/text.component';
import { UrlComponent } from './school/_partials/url/url.component';
import { ObjectComponent } from './school/_partials/object/object.component';
import { RLinkComponent } from './school/_partials/r-link/r-link.component';
import { LoadingComponent } from './school/_partials/loading/loading.component';
import { AaComponent } from './school/_partials/aa/aa.component';
import { NoDataComponent } from './school/_partials/no-data/no-data.component';
import { HomeComponent } from './school/home/home.component';
import { StudentsListComponent } from './school/students-list/students-list.component';
import { CrudTableComponent } from './school/_tables/crud-table/crud-table.component';
import { UpdateDeleteComponent } from './school/_partials/update-delete/update-delete.component';
import { EditObjectDialogComponent } from './school/_dialogs/edit-object-dialog/edit-object-dialog.component';
import { CreateObjectDialogComponent } from './school/_dialogs/create-object-dialog/create-object-dialog.component';
import { StudentDetailComponent } from './school/student-detail/student-detail.component';
import { ShowObjectDetailDialogComponent } from './school/show-object-detail-dialog/show-object-detail-dialog.component';
import { CourseProgramsListComponent } from './school/course-programs-list/course-programs-list.component';
import { CoursesPlanificationComponent } from './school/courses-planification/courses-planification.component';
import { WeekTemplateComponent } from './school/week-template/week-template.component';
import { SchoolclassComponent } from './school/schoolclass/schoolclass.component';
import { CoursesListComponent } from './school/courses-list/courses-list.component';
import { EditCourseComponent } from './school/edit-course/edit-course.component';
import { SanctionsListComponent } from './school/sanctions-list/sanctions-list.component';
import { AddSanctionDialogComponent } from './school/_dialogs/add-sanction-dialog/add-sanction-dialog.component';
import { TasksListComponent } from './school/tasks-list/tasks-list.component';
import { AddTaskDialogComponent } from './school/_dialogs/add-task-dialog/add-task-dialog.component';
import { SanctionsAdminComponent } from './school/sanctions-admin/sanctions-admin.component';
import { TasksAdminComponent } from './school/tasks-admin/tasks-admin.component';
import { UploadDataComponent } from './school/upload-data/upload-data.component';
import { FilePreviewComponent } from './school/file-preview/file-preview.component';
import { ParentDetailComponent } from './school/parent-detail/parent-detail.component';
import { NotesListComponent } from './school/notes-list/notes-list.component';
import { ExamDetailComponent } from './school/exam-detail/exam-detail.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { StudentCommentsComponent } from './school/_dialogs/student-comments/student-comments.component';
import { CommentariesListComponent } from './school/_partials/commentaries-list/commentaries-list.component';
import { ParentsListComponent } from './school/_partials/parents-list/parents-list.component';
import { CommunicationMediumsListComponent } from './school/_partials/communication-mediums-list/communication-mediums-list.component';
import { DxSanctionsListComponent } from './school/_partials/dx-sanctions-list/dx-sanctions-list.component';
import { CompetitorsListComponent } from './school/_partials/competitors-list/competitors-list.component';
import { CompetitorsTeamsListComponent } from './school/_partials/competitors-teams-list/competitors-teams-list.component';
import { CompetitorPointsListComponent } from './school/_partials/competitor-points-list/competitor-points-list.component';
import { TeamPointsListComponent } from './school/_partials/team-points-list/team-points-list.component';
import { TeamsPointsChartComponent } from './school/_partials/teams-points-chart/teams-points-chart.component';
import { MyStudentsComponent } from './school/my-students/my-students.component';
import { DxStudentsListComponent } from './school/_partials/dx-students-list/dx-students-list.component';
import { DxTasksListComponent } from './school/_partials/dx-tasks-list/dx-tasks-list.component';
import { StudentworksMessagesListComponent } from './_partials/studentworks-messages-list/studentworks-messages-list.component';
import { DxTodoTasksListComponent } from './school/_partials/dx-todo-tasks-list/dx-todo-tasks-list.component';
import { TodoTasksComponent } from './school/todo-tasks/todo-tasks.component';
import { ExaminationsComponent } from './admin/examinations/examinations.component';
import { ExaminationsListComponent } from './_datagrids/examinations-list/examinations-list.component';
import { ExerciseInfosComponent } from './_partials/exercise-infos/exercise-infos.component';
import { MeetingsListComponent } from './school/_partials/meetings-list/meetings-list.component';
import { CompetitorBehaviourCoursePointsListComponent } from './school/_partials/competitor-behaviour-course-points-list/competitor-behaviour-course-points-list.component';
import { EditRewardDialogComponent } from './school/_dialogs/edit-reward-dialog/edit-reward-dialog.component';
import { OpenaiExerciseFormComponent } from './school/_dialogs/openai-exercise-form/openai-exercise-form.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    AdminIndexComponent,
    GeneralIndexComponent,
    AdminComponent,
    AdminLinksComponent,
    AdminSchoolComponent,
    EditYearsubjectDialogComponent,
    EditThemeComponent,
    EditNewlinkscategoryComponent,
    EditLinkDialogComponent,
    ExerciseComponent,
    QuestionComponent,
    ChunkComponent,
    QuestionDetailComponent,
    ExercisesListComponent,
    MediaComponent,
    StudentworksListComponent,
    StudentworksHomeComponent,
    HomeworksComponent,
    SchoolclassesComponent,
    EditSchoolclassComponent,
    CreateHomeworkComponent,
    ExerciseDialogComponent,
    StudentworksComponent,
    ExerciseFeedbackComponent,
    HomeworksSummaryComponent,
    JourneysComponent,
    StepsComponent,
    StepExercisesDialogComponent,
    StudentJourneysComponent,
    StudentStepsComponent,
    SchoolclassJourneysComponent,
    JourneysHomeComponent,
    JourneysListComponent,
    StudentJourneyComponent,
    StudentStepComponent,
    AttemptComponent,
    ExercisesComponent,
    LinksHomeComponent,
    LinksListComponent,
    AboutComponent,
    QuestionsComponent,
    QuestionsListComponent,
    EditQuestionContentComponent,
    EditQuestionDetailComponent,
    ChoicesComponent,
    QuestionCorrectionComponent,
    ExerciseCorrectionComponent,
    QuestionDetailCorrectionComponent,
    ExerciseCorrectionsDialogComponent,
    MyExercisesListComponent,
    EditResumeComponent,
    Resume1Component,
    Resume2Component,
    Resume3Component,
    Resume4Component,
    PersonalQualitiesListComponent,
    LanguagesListComponent,
    SchoolPathsListComponent,
    InternshipsListComponent,
    SkillsListComponent,
    HobbiesListComponent,
    ReferencesListComponent,
    EditPersonalDataComponent,
    ResumePictureComponent,

    // school app components
    RLinkComponent,
    SimpleTableComponent,
    TextComponent,
    UrlComponent,
    ObjectComponent,
    LoadingComponent,
    AaComponent,
    NoDataComponent,
    HomeComponent,
    StudentsListComponent,
    CrudTableComponent,
    UpdateDeleteComponent,
    EditObjectDialogComponent,
    CreateObjectDialogComponent,
    StudentDetailComponent,
    ShowObjectDetailDialogComponent,
    CourseProgramsListComponent,
    CoursesPlanificationComponent,
    WeekTemplateComponent,
    SchoolclassComponent,
    CoursesListComponent,
    EditCourseComponent,
    SanctionsListComponent,
    AddSanctionDialogComponent,
    TasksListComponent,
    AddTaskDialogComponent,
    SanctionsAdminComponent,
    TasksAdminComponent,
    UploadDataComponent,
    FilePreviewComponent,
    ParentDetailComponent,
    NotesListComponent,
    ExamDetailComponent,
    StudentCommentsComponent,
    CommentariesListComponent,
    ParentsListComponent,
    CommunicationMediumsListComponent,
    DxSanctionsListComponent,
    CompetitorsListComponent,
    CompetitorsTeamsListComponent,
    CompetitorPointsListComponent,
    TeamPointsListComponent,
    TeamsPointsChartComponent,
    MyStudentsComponent,
    DxStudentsListComponent,
    DxTasksListComponent,
    StudentworksMessagesListComponent,
    DxTodoTasksListComponent,
    TodoTasksComponent,
    ExaminationsComponent,
    ExaminationsListComponent,
    ExerciseInfosComponent,
    MeetingsListComponent,
    CompetitorBehaviourCoursePointsListComponent,
    EditRewardDialogComponent,
    OpenaiExerciseFormComponent,
  ],
  entryComponents: [ QuestionComponent, ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    MatOptionModule,
    MatSelectModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SafePipeModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxTreeListModule,
    DxPivotGridModule,
    DxLookupModule,
    // NgxStarRatingModule,
    TextFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSliderModule,
    MatProgressBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    DxHtmlEditorModule,
    DxButtonModule,
    DxPopupModule,
    DxScrollViewModule,
    AngularEditorModule,
    DxColorBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxListModule,
    DxChartModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-XSRF-TOKEN',
      }),
  ],
  providers: [
    UserService,
    ApiService,
    MatDatepickerModule,
    DatagridService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3500,
        verticalPosition: 'bottom'}
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
