import { Component, Input, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'parents-list',
  templateUrl: './parents-list.component.html',
  styleUrls: ['./parents-list.component.scss']
})
export class ParentsListComponent implements OnInit {

  @Input()
  public student: any;

  public parents: any = {};
  public popupVisible: boolean = false;
  public selectedParent: any = {};
  public parentCommunicationMediums: any = {};
  public closeButtonOptions: any = {};

  constructor(private _dG: DatagridService,) { }

  ngOnInit(): void {
    this.parents = this._dG.createStore(`/api/schoolapp/dx-parents/`);
  }

  insertRow(event:any){
    // we add student id to the data sent
    event['data']['student'] = this.student.id;
  }

  updateRow(event:any){
    event['newData']['student'] = this.student.id;
  }

  insertCommunicationMedium(event:any){
    event['data']['parent'] = this.selectedParent.id;
  }

  updateCommunicationMedium(event:any){
    event['newData']['parent'] = this.selectedParent.id;
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;
    // Override implementation for the "between" filter operation
    if (selectedFilterOperation === "=") {
        const filterExpression = ["student__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  showPopup(parentData:any){
    this.selectedParent = parentData;
    this.parentCommunicationMediums = this._dG.createStore(`/api/schoolapp/dx-communication-mediums/`);
    this.popupVisible = true;
  }
}
