import { Component, Input, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'competitor-points-list',
  templateUrl: './competitor-points-list.component.html',
  styleUrls: ['./competitor-points-list.component.scss']
})
export class CompetitorPointsListComponent implements OnInit {

  @Input()
  public competitor: any;

  @ViewChild(DxDataGridComponent)
  public workPointsDatagrid: DxDataGridComponent;

  public workPoints: any = {};
  public behaviorPoints: any = {};
  public series: any[];
  public chartsData: any[] = [];

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.series = [
      { value: 'number', name: 'Nombre' },
    ]

    this.loadCompetitorPoints();
    this.loadGraphData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.competitor = changes.competitor.currentValue;
    if (changes.competitor.previousValue && changes.competitor.previousValue != changes.competitor.currentValue){
      this.workPointsDatagrid.instance.refresh();
      this.loadGraphData();
    }
    console.log(this.competitor);
  }

  loadCompetitorPoints(){
    this.workPoints = this._dG.createStore(`/api/schoolapp/dx-competitors-points/`);
    // this.behaviorPoints = this._dG.createStore(`/api/schoolapp/dx-competitors-points/`);
  }

  loadGraphData(){
    this._apiService.getObjectsList(`/api/schoolapp/dx-competitors-points/?filter=["competitor__id","=","${this.competitor.id}"]`).subscribe({
      next: (data:any) => {
        this.chartsData = [];

        data.data.forEach(point => {
          
          this.chartsData.push(
            { date:point.created_at,
              point:point.typeof == 1 ?  this.round(point.number): undefined,
              behavior_point:point.typeof == 2 ? this.round(point.number) : undefined,
              work_points_until_then:point.work_points_until_then,
              behavior_points_until_then:point.behavior_points_until_then,
              average_of_points_until_then:this.round(point.average_of_points_until_then),
              average_of_behavior_points_until_then:this.round(point.average_of_behavior_points_until_then),
            });
        });
        console.log('POINTS :', this.workPoints);
      }
    });
  }

  insertRow(event:any,pointType:number){
    event['data']['competitor'] = this.competitor.id;
    event['data']['typeof'] = pointType;
  }

  updateRow(event:any){
    event['newData']['competitor'] = this.competitor.id;
  }

  calculateFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;

    if (selectedFilterOperation === "=") {
        const filterExpression = ["competitor__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  round(number:number){
    return Math.round(number * 100) / 100;
  }
}
