import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'course-programs-list',
  templateUrl: './course-programs-list.component.html',
  styleUrls: ['./course-programs-list.component.scss']
})
export class CourseProgramsListComponent implements OnInit {

  public dataSource: any = new MatTableDataSource();

  public modelName: string = "CourseProgram";

  public displayedColumns: any[] = [
    {name: 'id', dataBinding: 'id', title: 'Id', type: 'text',},
    {name: 'name', dataBinding: 'name', title: 'Nom', type: 'text',},
    {name: 'short_name', dataBinding: 'short_name', title: 'Abréviation', type: 'text',},
    {name: 'description', dataBinding: 'description', title: 'Description', type: 'text',},
    {name: 'color', dataBinding: 'color', title: 'Couleur', type: 'color',},
    {name: 'schoolclass', dataBinding: 'id', title: 'Elèves', type: 'r-link', callback: 'editStudentsUrl'},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ]

  public additionalData: any;

  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.additionalData = {'students':[]};
    this.refresh();
  }

  updateDeleteUrl(pk: string) {
    return `/api/schoolapp/courses-programs/${pk}`;
  }

  editStudentsUrl(pk: number){
    return `/api/schoolapp/schoolclass/${pk}`;
  }

  refresh() {
    this._apiService.getObjectsList('/api/schoolapp/courses-programs/').subscribe(
      data => {
        this.dataSource.data = data;
        // console.log('DATA : ', data);
      }
    )
  }
}
