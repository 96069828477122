import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'edit-personal-data',
  templateUrl: './edit-personal-data.component.html',
  styleUrls: ['./edit-personal-data.component.scss']
})
export class EditPersonalDataComponent implements OnInit {

  @Input()
  public resume: any;

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
  }

  save(){
    const obj = {
      "name":this.resume.name,
      "first_name":this.resume.first_name,
      "email":this.resume.email,
      "phonenumber":this.resume.phonenumber,
      "address":this.resume.address,
      "birthdate":this.resume.birthdate,
      "description":this.resume.description,
    }

    console.log(obj);

    this._apiService.patchObject(`api/dx-resumes/${this.resume.id}/`,obj).subscribe(
      data => {
        this.resume = data;
        this._snackBar.open("Modification enregistrée","Fermer");
      },
      error => {
        this._snackBar.open("Une erreur est survenue","Fermer");
      }
    )
  }
}
