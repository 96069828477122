import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { DatetimeService } from 'src/app/services/datetime.service';
import { ExerciseCorrectionsDialogComponent } from 'src/app/_dialogs/exercise-corrections-dialog/exercise-corrections-dialog.component';

@Component({
  selector: 'studentworks',
  templateUrl: './studentworks.component.html',
  styleUrls: ['./studentworks.component.scss']
})
export class StudentworksComponent implements OnInit {

  @Input()
  public homeworkId: number;

  public dataSource: any;
  public exercises: any[];

  public statusDetail : string[] = [
    'pas commencé',
    'commencé',
    'terminé dans les temps',
    'terminé en retard',
  ];

  public popupVisible: boolean = false;
  public closeButtonOptions: any;
  public popupExercise: any = undefined;
  public popupExercisesCorrections: any[] = [];
  public popupQuestionDetailsCorrections: any[] = [];
  public popupExerciseId: number = undefined;

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,
              public dateTimeService: DatetimeService,
              public dialog: MatDialog,) {
    const that = this;

    this.closeButtonOptions = {
      text: 'Close',
      onClick(e) {
        that.popupVisible  = false;
        that.popupExercise = undefined;
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = this._dG.createStore(`api/dx-all-min-studentworks/?filter=["homework__id","=",${this.homeworkId}]`);
    this.loadExercises();
  }

  loadExercises(){
    this._apiService.getObjectsList(`api/dx-min-homeworks/?filter=["id","=",${this.homeworkId}]`).subscribe(
      data => {
        // this.exercises = data['data'][0]['exercises'];
      }
    )
  }

  showCorrectionsDetails(studentworkId: number){
    this.popupVisible = true;
    this._apiService.getObjectDetail(`api/dx-all-studentworks/${studentworkId}/`).subscribe(
      data => {
        // this.popupExercise = data['exercise'];
        this.popupExercisesCorrections = data['exercisecorrections'];
        this.openCorrectionsDialog(data['exercisecorrections']);

        /**
        if(data['exercisecorrections'] && data['exercisecorrections'].length > 0){
          let filters = [];
          data['exercisecorrections'].forEach(element => {
            // WARNING : si on ne met qu'un underscore (_), on obtient l'erreur suivante de l'api :
            // django.core.exceptions.FieldError: Related Field got invalid lookup: iexact
            filters.push(`["exercisecorrection__id","=","${element}"]`);
          });
          console.log('FILTERS :', filters);
          const joinedFilters = filters.join(`,"or",`);
          console.log('ENDPOINT :', joinedFilters);
          
          this._apiService.getObjectsList(`api/dx-questiondetailcorrections/?filter=[[${joinedFilters}],"and",["is_correct","=",false]]`).subscribe(
            data => {
              this.popupQuestionDetailsCorrections = data;
              console.log('QUESTION DETAILS CORRECTIONS :', this.popupQuestionDetailsCorrections);
            }
          )
        }
        */
        console.log(data);
      }
    )
  }

  openCorrectionsDialog(popupExercisesCorrections: any[]): void {
    const dialogRef = this.dialog.open(ExerciseCorrectionsDialogComponent, {
      width: '80%',
      height: '900px',
      data: {popupExercisesCorrections:popupExercisesCorrections}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
