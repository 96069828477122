import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatetimeService } from 'src/app/services/datetime.service';

@Component({
  selector: 'app-homeworks-summary',
  templateUrl: './homeworks-summary.component.html',
  styleUrls: ['./homeworks-summary.component.scss']
})
export class HomeworksSummaryComponent implements OnInit {

  public summaries: any[];
  public schoolclasses: any[] = [];
  public selectedSchoolclass: string;

  public startDate: any;
  public endDate: any;
  public form: FormGroup;
  public teacher: string;

  constructor(private _apiService: ApiService,
              public datetimeService: DatetimeService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.loadSchoolclasses();
    this.form = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      schoolclass: new FormControl('', Validators.required),
    })
  }

  loadSchoolclasses(){
    this._apiService.getObjectsList(`api/dx-schoolclasses/`).subscribe(
      data => {
        this.schoolclasses = data['data'];
      }
    )
  }

  loadSummaries(){
    this.startDate      = this.form.get('startDate').value;
    this.endDate        = this.form.get('endDate').value;
    const schoolclassId = this.form.get('schoolclass').value;

    if(this.startDate && this.endDate && schoolclassId){
      this._apiService.getObjectsList(`api/schoolclass-summary/${schoolclassId}/${this.datetimeService.fromDateToStringUS(this.startDate)}/${this.datetimeService.fromDateToStringUS(this.endDate)}/`).subscribe(
        data => {
          this.summaries = data['data'];
          this.selectedSchoolclass = data['schoolclass'];
          this.teacher = data['teacher'];
          this._snackBar.open("Données chargées avec succès","Fermer");
        },
        erro => this._snackBar.open("Une erreur est survenue","Fermer")
      );
    } else {
      this.summaries = [];
    }
  }
}
