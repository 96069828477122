<div class="content-block">
    <h2>
        Mes classes / groupes d'élèves
    </h2>
    <div>
        <dx-data-grid 
            #gridContainer
            id="gridContainer"
            [dataSource]="dataSource"
            [showBorders]="true"
            [remoteOperations]="true"
            (onEditorPreparing)="onEditorPreparing($event)">
            <dxo-editing
                [allowUpdating]="true"
                [allowAdding]="true"
                mode="row"> <!-- 'batch' | 'cell' | 'form' | 'popup' -->
            </dxo-editing>
            <dxo-header-filter 
            [visible]="true"></dxo-header-filter>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="true"
                [visible]="true">
            </dxo-pager>

            <dxi-column
                dataField="name"
                dataType="string"
                caption="NOM"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column 
                dataField="color"
                caption="COULEUR"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="id"
                dataType="number"
                caption="Actions"
                [allowHeaderFiltering]="false"
                cellTemplate="actionsTemplate">
            </dxi-column>
            
            <div *dxTemplate="let data of 'actionsTemplate'">
                <dx-button
                    class="pointer"
                    text="Gérer les élèves de la classe"
                    type="default"
                    [routerLink]="['/admin/edit-schoolclass',data.data.id]">
                </dx-button>
            </div>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
        </dx-data-grid>
    </div>
</div>
