import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Models } from '../models/api-models';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input()
  public previewData: any;

  @Input()
  public dataFile: any;

  public completeData: any;
  public completeLoad: boolean = false;

  @Input()
  public numberOfColumns: number;

  public modelColumns: any[];
  public studentColumns: any;
  public parentColumns: any;
  public communicationMediumColumns: any;

  public colForm: FormGroup = new FormGroup({
    colNames: new FormArray([])
  });

  public columnsToImport: number[] = [];
  public firstRowToImport: number = 0;

  private nameColumnIndex: number;
  private currentStudent: any;

  private students: any = [];
  // private currentUser: any;
  // private currentUserIndex: number;

  public selectedOptions: string[] = [
    'password',
    'name',
    '',
    '',
    'birthdate',
    '',
    '',
    'parent_name',
    'address',
    'emergency_num',
    'private_num',
    'other_num',
    'portable_num',
    'email',
  ]

  constructor(private _apiService: ApiService,
              public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.studentColumns = [
      ['password',"Mot de passe"],
      ['name',"Elève : Prénom + Nom"],
      // ['first_name','Elève : Prénom'],
      // ['last_name','Elève : Nom de famille'],
      ['birthdate','Elève : Date de naissance'],
    ];
    this.parentColumns = [
      ['address','Parent : Adresse'],
      ['parent_name',"Parent : Prénom/Nom"],
    ];
    this.communicationMediumColumns = [
      ['emergency_num',"Parent : Num d'urgence"],
      ['private_num',"Parent : Num privé"],
      ['other_num',"Parent : Autre num"],
      ['portable_num',"Parent : Tel portable"],
      ['email',"Parent : email"]
    ]
    this.modelColumns = [...this.studentColumns, ...this.parentColumns, ...this.communicationMediumColumns];

    this.previewData[0].forEach((elem, index) => {
      let f = new FormControl(this.selectedOptions[index]); // select the right column name automatically for this type of file
      this.colNames.push(f); // works because of the Getter method bellow
    });
  }

  /**
   * we need to define a Getter method for the colNames form array
   */
  get colNames(): FormArray {
    return this.colForm.get('colNames') as FormArray;
  }

  loadCompleteFile(){
    this._apiService.postFile(this.dataFile, this.columnsToImport, this.firstRowToImport).subscribe(data => {
        this.completeData = data;
      }, error => {
        console.log(error);
      });
  }

  checkCheckBoxvalue(event, index){
    if(event.checked){
      if(!this.columnsToImport.includes(index)){
        this.columnsToImport.push(index);
      }
    }else{
      if(this.columnsToImport.includes(index)){
        this.columnsToImport.splice(this.columnsToImport.indexOf(index),1);
      }
    }
    console.log(this.columnsToImport);
  }

  checkRadiovalue(index){
    this.firstRowToImport = index;
    console.log(index);
  }

  change(index: number){
    console.log('Changed value of column : ', +index);
  }

  /**
   * Check if a column 'name' is choosen by user.
   * If not, the scipt should not import anything,
   * that's the strict minimum.
   * Also, set nameColumnIndex.
   */
  checkIfStudentNameIsGiven(){
    let check = false;

    this.colNames.controls.forEach(
      (control, index) => {
        if(control.value == 'name'){ 
          check = true;
          this.nameColumnIndex = index;
        }
      }
    )
    return check;
  }

  /**
   * Check if a row contains student's name
   * @param row 
   */
  checkIfRowContainsStudentName(row: any){
    if(row[this.nameColumnIndex] && row[this.nameColumnIndex] != ''){
      return true;
    }
    return false;
  }

  save(){
    this.students = [];

    if(this.checkIfStudentNameIsGiven()){
      this.previewData.forEach((row, index) => {
        
        if(index >= this.firstRowToImport){
          let rowHasStudent = this.checkIfRowContainsStudentName(row);

          if(rowHasStudent){
            this.currentStudent = {'parents':[]};
          }

          let parent  = {'communication_mediums':[]};
          
          row.forEach((cellValue, i) => {
            let colName = this.colNames.controls[i].value;

            if(colName && colName !== ''){
              console.log('COL NAME : ', colName);
              if(this.studentColumns.flat().includes(colName)){
                if(cellValue && cellValue !== ''){
                  this.currentStudent[colName] = cellValue;
                }
              } else if (this.parentColumns.flat().includes(colName)){
                if(cellValue && cellValue !== ''){
                  if(colName == 'parent_name'){
                    parent['name'] = cellValue;
                  } else {
                    parent[colName] = cellValue;
                  }
                }
              } else if (this.communicationMediumColumns.flat().includes(colName)){
                if(cellValue && cellValue !== ''){
                  let communicationMedium = {};
                  communicationMedium['type_of'] = colName;
                  communicationMedium['value']   = cellValue;
                  parent['communication_mediums'].push(communicationMedium);
                }
              }
            }
          });

          if(parent['name'] && parent['name'] !== ''){
            this.currentStudent['parents'].push(parent);
          }

          if(rowHasStudent){
            this.students.push(this.currentStudent);
          }
        }
      });
    } else {
      this.snackBar.open("Vous n'avez choisi aucune colonne pour le nom/prénom de l'élève.", "Fermer");
    }

    this._apiService.createObject('/api/schoolapp/students/', this.students).subscribe(
      data => {
        this.snackBar.open('Elèves ajoutés', 'Fermer')
      },
      err => this.snackBar.open(err, 'Fermer')
    )
  }
}
