<mat-form-field appearance="outline">
    <mat-label>Nom</mat-label>
    <input matInput [(ngModel)]="resume.name" (blur)="save()">
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Prénom</mat-label>
    <input matInput [(ngModel)]="resume.first_name"
    (blur)="save()">
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Date de naissance</mat-label>
    <input matInput [(ngModel)]="resume.birthdate"
    (blur)="save()">
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Adresse</mat-label>
    <input matInput [(ngModel)]="resume.address"
    (blur)="save()">
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <textarea
        matInput
        [(ngModel)]="resume.email"
        (blur)="save()">
    </textarea>
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Tel</mat-label>
    <input matInput [(ngModel)]="resume.phonenumber"
    (blur)="save()">
</mat-form-field>
<br>
<mat-form-field appearance="outline">
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="resume.description"
    (blur)="save()">
</mat-form-field>