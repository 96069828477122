<h2 *ngIf="exam">{{types[exam.type_of]}} du {{exam.course_detail.date | date:'dd.MM.yyyy'}} - {{exam.course_detail.course_program.name}}</h2>

<div>
    <form [formGroup]="formGroup"
          style="width:100%;"
          fxLayout="row"
          fxLayoutGap="10px">
        <mat-form-field>
            <mat-label>Elève</mat-label>
            <!-- <mat-select formControlName="studentCtrl">
                <mat-option *ngFor="let student of students">
                    {{student.name}}
                </mat-option>
            </mat-select> -->
            <input type="text"
                   placeholder="Choisissez un(e) élève"
                   matInput
                   formControlName="studentCtrl"
                   [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let student of filteredOptions | async" [value]="student">
                        {{student.name}}
                    </mat-option>
                </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Note</mat-label>
            <input matInput
                   formControlName="scoreCtrl">
        </mat-form-field>
        <button mat-button
                (click)="save()">
            <span class="material-icons accent">
                add
            </span>
        </button>
    </form>
    </div>

<div>
    <crud-table [dataSource]="dataSource"
                [modelName]="'Score'"
                [displayedColumns]="displayedColumns"
                [showCreateButton]="false"
                [parentComponent]="this">
    </crud-table>
</div>