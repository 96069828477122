<div class="row">
    <div class="column">
        <strong>Points attribués pour le travail effectué</strong>
        <dx-data-grid
            id="workPointsDatagrid"
            class="points-list"
            [dataSource]="workPoints"
            [remoteOperations]="true"
            (onRowInserting)="insertRow($event,1)"
            (onRowUpdating)="updateRow($event)"
            (onRowInserted)="loadGraphData()"
            (onRowRemoved)="loadGraphData()"
            (onRowUpdated)="loadGraphData()">
            <dxo-paging [pageSize]="5"> </dxo-paging>
            <dxo-pager
                [visible]="true"
                [allowedPageSizes]="[5]"
                [showPageSizeSelector]="false"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-editing
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true">
            </dxo-editing>
    
            <dxi-column
                dataField="created_at"
                dataType="date"
                format="dd.MM.yyyy"
                caption="Date"
                [allowEditing]="false">
            </dxi-column>
            <dxi-column
                dataField="competitor"
                [allowEditing]="false"
                [filterValue]="competitor.id"
                [calculateFilterExpression]="calculateFilterExpression"
                [width]="0">
            </dxi-column>
            <dxi-column
                dataField="number"
                dataType="number"
                caption="Points reçus">
            </dxi-column>
            <dxi-column
                dataField="work_points_until_then"
                dataType="number"
                caption="Total points jusque-là">
            </dxi-column>
            <dxi-column
                dataField="typeof"
                dataType="number"
                [filterValue]="1"
                [width]="0">
            </dxi-column>
        </dx-data-grid>
    </div>

    <div class="column">
        <strong>Points attribués pour le comportement</strong>
        <dx-data-grid
            id="behaviorPointsDatagrid"
            class="points-list"
            [dataSource]="workPoints"
            [remoteOperations]="true"
            (onRowInserting)="insertRow($event,2)"
            (onRowUpdating)="updateRow($event)"
            (onRowInserted)="loadGraphData()"
            (onRowRemoved)="loadGraphData()"
            (onRowUpdated)="loadGraphData()">
            <dxo-paging [pageSize]="5"> </dxo-paging>
            <dxo-pager
                [visible]="true"
                [allowedPageSizes]="[5]"
                [showPageSizeSelector]="false"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-editing
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true">
            </dxo-editing>
    
            <dxi-column
                dataField="created_at"
                dataType="date"
                format="dd.MM.yyyy"
                caption="Date"
                [allowEditing]="false">
            </dxi-column>
            <dxi-column
                dataField="competitor"
                [allowEditing]="false"
                [filterValue]="competitor.id"
                [calculateFilterExpression]="calculateFilterExpression"
                [width]="0">
            </dxi-column>
            <dxi-column
                dataField="number"
                dataType="number"
                caption="Points reçus">
            </dxi-column>
            <dxi-column
                dataField="behavior_points_until_then"
                dataType="number"
                caption="Total points jusque-là">
            </dxi-column>
            <dxi-column
                dataField="typeof"
                dataType="number"
                [filterValue]="2"
                [width]="0">
            </dxi-column>
        </dx-data-grid>
    </div>
</div>


<br><br>
<dx-chart [dataSource]="chartsData">
    <!--<dxi-series
        color="#79cac4"
        type="line"
        argumentField="date"
        valueField="work_points_until_then"
        name="Travail">
        <dxo-label [visible]="true" backgroundColor="#79cac4"></dxo-label>
    </dxi-series>-->
    <dxi-series
        color="#79cac4"
        type="bar"
        argumentField="date"
        valueField="point"
        name="Points travail">
        <dxo-label [visible]="true" backgroundColor="#79cac4"></dxo-label>
    </dxi-series>
    <dxi-series
        color="#c18e92"
        type="bar"
        argumentField="date"
        valueField="behavior_point"
        name="Points comportement">
        <dxo-label [visible]="true" backgroundColor="#c18e92"></dxo-label>
    </dxi-series>
    <!--<dxi-series
        color="#c18e92"
        type="line"
        argumentField="date"
        valueField="behavior_points_until_then"
        name="Comportement">
        <dxo-label [visible]="true" backgroundColor="#c18e92"></dxo-label>
    </dxi-series>-->
    <dxi-series
        color="#76A9D6"
        type="line"
        argumentField="date"
        valueField="average_of_points_until_then"
        name="Moyenne travail">
        <dxo-label [visible]="true" backgroundColor="#76A9D6"></dxo-label>
    </dxi-series>
    <dxi-series
        color="#D996BD"
        type="line"
        argumentField="date"
        valueField="average_of_behavior_points_until_then"
        name="Moyenne comportement">
        <dxo-label [visible]="true" backgroundColor="#D996BD"></dxo-label>
    </dxi-series>
    
</dx-chart>