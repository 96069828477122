<div>
    <h2>Création d'un devoir</h2>
    <mat-stepper orientation="horizontal" [linear]="false" #stepper>
        <mat-step>
            <ng-template matStepLabel>Données générales du devoir</ng-template>
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Fill out your name</ng-template>
                <br>
                <mat-form-field appearance="fill">
                  <mat-label>Date limite</mat-label>
                  <input matInput
                         type="datetime-local"
                         placeholder="Date..."
                         formControlName="dueDate"
                         required>
                </mat-form-field>
            
                &nbsp;&nbsp;&nbsp;
            
                <mat-form-field appearance="fill">
                    <mat-label>Pourcentage minimum requis</mat-label>
                    <input matInput type="number" placeholder="60, 80..." formControlName="minPercentage" required>
                </mat-form-field>

                &nbsp;&nbsp;&nbsp;

                <mat-form-field appearance="fill" *ngIf="schoolclasses">
                    <mat-label>Classes / Groupes d'élèves</mat-label>
                    <mat-select formControlName="schoolclasses" multiple>
                      <mat-option *ngFor="let schoolclass of schoolclasses" [value]="schoolclass">
                          {{schoolclass.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <div>
                    <button mat-flat-button color="primary" matStepperNext>Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Exercices du devoir</ng-template>

            <mat-card>
                <mat-form-field appearance="fill" class="full-width">
                    <mat-label>Exercices sélectionnés</mat-label>
                    <mat-chip-list #chipList aria-label="Exercices sélectionnés">
                        <mat-chip
                        *ngFor="let exercise of selectedExercises"
                        [selectable]="selectable"
                        [removable]="removable">
                        {{exercise.name}}
                            <mat-icon matChipRemove
                                    (click)="removeExercise(exercise)">
                                cancel
                            </mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </mat-form-field>
                <div *ngIf="exerciseHomeworkChecks&&exerciseHomeworkChecks.length>0">
                    <mat-card class="warning" *ngFor="let match of exerciseHomeworkChecks">
                        Attention : {{match}}
                    </mat-card>
                </div>
            </mat-card>

            <div class="table-container">
                <exercises-list [showTakeButton]="true"
                                [showLinkButton]="false"
                                [showOverviewButton]="true"
                                (exerciseTaken)="addExercise($event)">
                </exercises-list>
            </div>

            <div>
                <button mat-flat-button color="primary" matStepperPrevious>Back</button>
                &nbsp;&nbsp;&nbsp;
                <button mat-flat-button color="primary" matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Enregistrer le devoir</ng-template>

            <div>
                Cliquez sur "Enregistrer" si vous avez renseigné tous les champs. Un devoir sera créé pour chaque Exercices
                sélectionné, et pour chacun des élèves des classes sélectionnées.
            </div>
            <br>
            <div>
                <button mat-flat-button color="primary" matStepperPrevious>Back</button>
                &nbsp;&nbsp;&nbsp;
                <button mat-flat-button color="warn" (click)="createHomework()">Enregistrer</button>
            </div>
        </mat-step>
      </mat-stepper> 
</div>