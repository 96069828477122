<div *ngIf="exercise" class="left-title">
    <h1>Édition de l'exercice : {{exercise.name}}</h1>
    <h2>{{exercise.yearsubject_details.name}} - {{exercise.theme_details.name}}</h2>
    <h2>Étape 1 - Choisissez la question à éditer / supprimer</h2>
    <div>
        <button
            mat-flat-button (click)="openDialog()"
            color="accent">Générer des questions avec ChatGpt
        </button>
    </div>
</div>
<div class="right-button">
    <button
        mat-flat-button 
        color="primary"
        (click)="createQuestion()">
            ajouter
    </button>
    &nbsp;
    <button
        mat-flat-button 
        color="primary"
        [routerLink]="['/admin','exercises']">
            retour
    </button>
</div>
<br><br>
<table>
    <thead>
        <tr>
            <!--<th>id</th>-->
            <th>contenu</th>
            <th>actions</th>
        </tr>
    </thead>
    <tbody *ngIf="exercise&&exercise.questions_details">
        <tr *ngFor="let question of exercise.questions">
            <!--<td class="id-cell">{{ question.id }}</td>-->
            <td class="content-cell">
                <question
                    [question]="question"
                    [admin]="true">
                </question>
            </td>
            <td class="actions-cell">
                <mat-icon
                    aria-hidden="false"
                    aria-label="edit"
                    class="pointer"
                    [routerLink]="['/admin','questions',question.id,'edit-content']">edit</mat-icon>
                <mat-icon
                    aria-hidden="false"
                    aria-label="delete"
                    class="pointer"
                    (click)="deleteQuestion(question.id)">
                        delete
                </mat-icon>
            </td>
        </tr>
    </tbody>
</table>
