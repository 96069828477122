import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-edit-question-detail',
  templateUrl: './edit-question-detail.component.html',
  styleUrls: ['./edit-question-detail.component.scss']
})
export class EditQuestionDetailComponent implements OnInit {

  public questionId: string;
  public question: any;

  constructor(private _route: ActivatedRoute,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.questionId = this._route.snapshot.paramMap.get('questionId');
    this.loadQuestion();
  }

  loadQuestion(){
    this._apiService.getObjectDetail(`api/dx-questions/${this.questionId}/`).subscribe(
      data => {
        this.question = data;
      }
    )
  }
}
