import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { StudentCommentsComponent } from '../../_dialogs/student-comments/student-comments.component';
import { ApiService } from 'src/app/services/api-service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'competitors-list',
  templateUrl: './competitors-list.component.html',
  styleUrls: ['./competitors-list.component.scss']
})
export class CompetitorsListComponent implements OnInit {

  @Input()
  public schoolclass: any;

  @ViewChild(DxDataGridComponent)
  public dataGrid: DxDataGridComponent;

  public competitors: any = {};
  public competitorsTeams: any = {};
  public selectedCompetitor: any = {};
  public competitorPopupVisible: boolean = false;
  public competitorPointsTitle: string = '';
  public selectedRows: number[] = [];

  public selectedItemKeys: any[] = [];

  constructor(private _dG:DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.competitors  = this._dG.createStore(`/api/schoolapp/dx-competitors/`);
    // this.competitorsTeams = this._dG.createStore(`/api/schoolapp/dx-competitors-teams/`);
  }

  openStudentCommentsDialog(student: any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = student;

    let dialogRef = this.dialog.open(StudentCommentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.dataGrid.instance.refresh();
    });
  }

  showCompetitorPoints(competitor:any): void {
    this.selectedCompetitor = competitor;
    this.competitorPointsTitle = `Liste des points pour ${this.selectedCompetitor.user_detail.username}`;
    // console.log('SELECTED COMPETITOR :', this.selectedCompetitor);
    this.competitorPopupVisible = true;
  }

  schoolclassFilterExpression(filterValue, selectedFilterOperation){
    return ["schoolclass__id","=",filterValue];
  }

  userFilterExpression(filterValue, selectedFilterOperation){
    return ["user__username","contains",filterValue];
  }

  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

  addBonusPoint(competitor: any){
    this._apiService.createObject(`/api/schoolapp/competitor/add-bonus-point/${competitor.id}/`,{}).subscribe(
      data => {
        console.log(data);
        // this.course.schoolclass.bonus_points = data['bonus_points'];
      },
      err => {
        this._snackBar.open("Vous avez déjà ajouté un point bonus à cet élève aujourd'hui.", "Fermer");
      }
    );
  }

  addReward(competitor: any){
    this._apiService.createObject(`/api/schoolapp/competitor/add-reward/${competitor.id}/`,{}).subscribe(
      data => {
        console.log(data);
        // this.course.schoolclass.rewards = data['rewards'];
        // this.course.schoolclass.bonus_points = data['bonus_points'];
      },
      erro => {
        this._snackBar.open("Cet élève n'a pas de point bonus. Vous ne pouvez lui attribuer une récompense.", 'Fermer')
      }
    );
  }

  addBonusPoints(){
    this.selectedItemKeys.forEach((key) => {
      this.addBonusPoint(key);
    });
    this.dataGrid.instance.refresh();
  }

  addRewards(){
    if(confirm("Voulez-vous vraiment ajouter une récompense à ces élèves ? Cela remettra leurs points bonus à zéro.")){
      this.selectedItemKeys.forEach((key) => {
        this.addReward(key);
      });
      this.dataGrid.instance.refresh();
    }
  }
}
