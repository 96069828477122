<h1 *ngIf="student">Fiche de l'élève {{student.username}}</h1>

<mat-tab-group *ngIf="student">
    <mat-tab label="Données générales">
        <div class="content"
             fxLayout="row"
             fxLayoutAlign="space-between"
             fxLayoutGap="2%">
            <mat-card fxFlex="20">
                <mat-card-title>
                    Elève
                    <span class="material-icons pointer accent"
                          (click)="openEditStudentDialog()">
                        edit
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <p>
                        Nom / Prénom : {{student.name}}
                    </p>
                    <p>
                        Date de naissance : {{student.birthdate | date:'dd.MM.yyyy'}}
                    </p>
                    <p>
                        Mesures particulières : {{student.special_measures}}
                    </p>
                </mat-card-content>
            </mat-card>
            <br>
            <mat-card fxFlex="78">
                <mat-card-title>
                    Parents de l'élève
                </mat-card-title>
                <mat-card-content>
                    <parents-list *ngIf="student" [student]="student"></parents-list>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
    <mat-tab label="Hobbies / Projets"> 
        <div *ngIf="student" class="row">
            <div class="column">
                <mat-card>
                    <mat-card-title>
                        Hobbies
                    </mat-card-title>
                    <mat-card-content>
                        <form [formGroup]="hobbiesProjectsForm">
                            <angular-editor [placeholder]="'Enter text here...'"
                                            formControlName="hobbiesCtrl"
                                            [config]="config">
                            </angular-editor>
                            <br>
                            <button mat-raised-button color="accent" (click)="saveHobbiesProjects()">Enregistrer</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="column">
                <mat-card>
                    <mat-card-title>
                        Projets
                    </mat-card-title>
                    <mat-card-content>
                        <form [formGroup]="hobbiesProjectsForm">
                            <angular-editor [placeholder]="'Enter text here...'"
                                            formControlName="projectsCtrl"
                                            [config]="config">
                            </angular-editor>
                            <br>
                            <button mat-raised-button color="accent" (click)="saveHobbiesProjects()">Enregistrer</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Commentaires">
        <div class="content">
             <mat-card>
                <mat-card-title>
                    Commentaires sur l'élève
                </mat-card-title>
                <mat-card-content>
                    <commentaries-list [student]="student"></commentaries-list>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab label="Punitions">
        <div class="content">
             <mat-card>
                <mat-card-title>
                    Punitions de l'élève
                </mat-card-title>
                <mat-card-content>
                    <dx-sanctions-list [userId]="pk"></dx-sanctions-list>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>