<ng-container *ngIf="!admin">
    <ng-container [ngSwitch]="questionDetailObj.question_detail_type">
        <ng-container *ngSwitchCase="'questions réponses'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="answerGiven">
            </mat-form-field>
            <ng-container *ngIf="isCorrected">
                <mat-icon *ngIf="isCorrect"
                          class="material-icons color_green">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!isCorrect"
                          matTooltip="{{questionDetailObj.correction}}"
                          class="material-icons color_red">highlight_off</mat-icon>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'textes à trous'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="answerGiven">
            </mat-form-field>
            <ng-container *ngIf="isCorrected">
                <mat-icon *ngIf="isCorrect"
                          class="material-icons color_green">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!isCorrect"
                          matTooltip="{{questionDetailObj.correction}}"
                          class="material-icons color_red">highlight_off</mat-icon>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'choix multiples'">
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="answerGiven">
                    <mat-option *ngFor="let choice of questionDetailObj.choices" [value]="choice">
                    {{choice}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="isCorrected">
                <mat-icon *ngIf="isCorrect"
                          class="material-icons color_green">check_circle_outline</mat-icon>
                <mat-icon *ngIf="!isCorrect"
                          matTooltip="{{questionDetailObj.correction}}"
                          class="material-icons color_red">highlight_off</mat-icon>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span [innerHTML]="chunk.content | safe: 'html'"></span>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="admin">
    <ng-container [ngSwitch]="questionDetailObj.question_detail_type">
        <ng-container *ngSwitchCase="'questions réponses'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="questionDetailObj.correction" (keyup.enter)="save()">
            </mat-form-field>
            <mat-icon class="material-icons color_green pointer"
                      (click)="save()">
                        check_circle_outline
            </mat-icon>

        </ng-container>
        <ng-container *ngSwitchCase="'textes à trous'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="questionDetailObj.correction" (keyup.enter)="save()">
            </mat-form-field>
            <mat-icon class="material-icons color_green pointer"
                      (click)="save()">
                        check_circle_outline
            </mat-icon>

        </ng-container>
        <ng-container *ngSwitchCase="'choix multiples'">
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="questionDetailObj.correction">
                    <mat-option *ngFor="let choice of questionDetailObj.choices" [value]="choice">
                    {{choice}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-icon class="material-icons color_green pointer"
                      (click)="save()">
                        check_circle_outline
            </mat-icon>
            <mat-icon class="material-icons color_yellow pointer"
                      (click)="edit()">
                        edit
            </mat-icon>

        </ng-container>
        <ng-container *ngSwitchDefault>
            <span [innerHTML]="chunk.content | safe: 'html'"></span>
        </ng-container>
    </ng-container>
</ng-container>