import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'aa',
  templateUrl: './aa.component.html',
  styleUrls: ['./aa.component.scss']
})
export class AaComponent implements OnInit {

  @Input() url: string;
  @Input() text: string;


  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  /**
   * Opening new tab
   */
  goto() {
    window.open(this.url, '_blank');
  }

  /**
   * Function that similate standard navigation with angular
   * @param e 
   */
  onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    // if middle click or CTRL+Left click
    if(e.button == 1 || (e.button == 0 && e.ctrlKey)) {
      // Open new tab
      this.goto();
    }
    // if left click navigation with angular routing
    if(e.button == 0 && !e.ctrlKey) {
      this.router.navigate([this.url]);
    }
    return false;
  }

}
