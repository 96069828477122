<div class="container">
    <div class="word_split wrapper">
    <span class="word word1">{{resume.name}}</span>
    <span class="word word2">{{resume.first_name}}</span>
    <span class="word word3">{{resume.birthdate}}</span>
    <span class="word word5"> &nbsp; </span>
    <!--<span class="word word5"> Compétences </span>
    <span *ngFor="let skill of resume.skills_details" class="word word5">
        {{skill}}
    </span>-->
    <span class="word word5"> &nbsp; </span>
    <span class="word word5">{{resume.description}}</span>
      
    <div class="bubble1">
       <resume-picture [pictureDetail]="resume.picture_detail" [width]="120"></resume-picture>
     </div>
    <div class="">
       <span class=""></span>
       
     </div>
    <div class="bubble3">
        <span class="the-arrow3"></span>
        <h2>Contact</h2>
        <p>{{resume.address}}</p>
        <p>{{resume.phonenumber}}</p>
        <p>{{resume.email}}</p>
     </div>
    <div class="bubble4">
        <h2>Parcours scolaire</h2>
        <div *ngFor="let path of resume.school_paths_details" class="row">
            <div class="col-100">
                {{path.start_year}} - {{path.end_year}} : {{path.content}}
            </div>
        </div>
    </div>
    <div class="bubble5">
        <h2>Stages</h2>
        <div *ngFor="let internship of resume.internships_details">
            {{internship.period}}
        </div>
    </div>
    <div class="bubble6">
        <h2>Qualités</h2>
            <p *ngFor="let quality of resume.personal_qualities_details">
                {{quality}}
            </p>
    </div>
    <div class="bubble7">
        <span class="the-arrow7"></span>
        <h2>Langues</h2>
        <p *ngFor="let language of resume.languages_details">
           {{language}}
        </p>
     </div>
    <div class="bubble8">
       <span class="the-arrow8"></span>
       <h2>Références</h2>
       <div *ngFor="let reference of resume.references_details">
            {{reference}}
        </div>
     </div>   
  </div>
     
  </div><!--end container-->