import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from './services/user-service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public authService: UserService,
                public snackBar: MatSnackBar) { }
                
    intercept(req: HttpRequest<any>,
              next: HttpHandler):Observable<HttpEvent<any>> {   
            req = req.clone({ withCredentials: true });
            
            let token = localStorage.getItem('access');

            if (token) {
                req = req.clone({
                    setHeaders: {
                        'Authorization': 'Bearer ' + token
                    }
                } );
            }
            // todo : remove this => only for debug purpose.
            req = req.clone({
                withCredentials: true
              });
        
        return next.handle(req);
    }
}