import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'resume-picture',
  templateUrl: './resume-picture.component.html',
  styleUrls: ['./resume-picture.component.scss']
})
export class ResumePictureComponent implements OnInit {

  @Input()
  public pictureDetail: any;

  @Input()
  public width: number = 200;

  public src: string = "";

  constructor() { }

  ngOnInit(): void {
    this.src = `data:${this.pictureDetail.mime_type};base64,${this.pictureDetail.b64_encoded}`;
  }

  ngOnChanges(changes: SimpleChanges){
    console.log('CHANGES :', changes);
    if(changes.pictureDetail && changes.pictureDetail.previousValue && changes.pictureDetail.previousValue != changes.pictureDetail.currentValue){
      this.src = `data:${changes.pictureDetail.currentValue.mime_type};base64,${changes.pictureDetail.currentValue.b64_encoded}`;
    }
  }
}
