import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminIndexComponent } from './admin/admin-index/admin-index.component';
import { AdminLinksComponent } from './admin/admin-links/admin-links.component';
import { AdminSchoolComponent } from './admin/admin-school/admin-school.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AuthComponent } from './auth/auth.component';
import { GeneralIndexComponent } from './general/general-index/general-index.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard/auth-guard.service';
import { TeacherGuardService as TeacherGuard } from './services/teacher-guard/teacher-guard.service';
import { ExerciseComponent } from './exercise/exercise.component';
import { ExercisesListComponent } from './exercises-list/exercises-list.component';
import { StudentworksHomeComponent } from './studentworks-home/studentworks-home.component';
import { HomeworksComponent } from './admin/homeworks/homeworks.component';
import { SchoolclassesComponent } from './admin/schoolclasses/schoolclasses.component';
import { EditSchoolclassComponent } from './admin/edit-schoolclass/edit-schoolclass.component';
import { CreateHomeworkComponent } from './admin/create-homework/create-homework.component';
import { HomeworksSummaryComponent } from './admin/homeworks-summary/homeworks-summary.component';
import { JourneysComponent } from './admin/journeys/journeys.component';
import { StudentJourneysComponent } from './admin/student-journeys/student-journeys.component';
import { SchoolclassJourneysComponent } from './admin/schoolclass-journeys/schoolclass-journeys.component';
import { JourneysHomeComponent } from './journeys-home/journeys-home.component';
import { ExercisesComponent } from './admin/exercises/exercises.component';
import { LinksHomeComponent } from './links-home/links-home.component';
import { AboutComponent } from './about/about.component';
import { AttemptComponent } from './attempt/attempt.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { EditQuestionContentComponent } from './admin/edit-question-content/edit-question-content.component';
import { EditQuestionDetailComponent } from './admin/edit-question-detail/edit-question-detail.component';
import { MyExercisesListComponent } from './my-exercises-list/my-exercises-list.component';
import { EditResumeComponent } from './edit-resume/edit-resume.component';
import { Resume1Component } from './_resumes/resume1/resume1.component';
import { Resume2Component } from './_resumes/resume2/resume2.component';
import { Resume3Component } from './_resumes/resume3/resume3.component';
import { CoursesListComponent } from './school/courses-list/courses-list.component';
import { StudentsListComponent } from './school/students-list/students-list.component';
import { CourseProgramsListComponent } from './school/course-programs-list/course-programs-list.component';
import { WeekTemplateComponent } from './school/week-template/week-template.component';
import { SchoolclassComponent } from './school/schoolclass/schoolclass.component';
import { EditCourseComponent } from './school/edit-course/edit-course.component';
import { ExamDetailComponent } from './school/exam-detail/exam-detail.component';
import { NotesListComponent } from './school/notes-list/notes-list.component';
import { ParentDetailComponent } from './school/parent-detail/parent-detail.component';
import { SanctionsAdminComponent } from './school/sanctions-admin/sanctions-admin.component';
import { StudentDetailComponent } from './school/student-detail/student-detail.component';
import { TasksAdminComponent } from './school/tasks-admin/tasks-admin.component';
import { UploadDataComponent } from './school/upload-data/upload-data.component';
import { MyStudentsComponent } from './school/my-students/my-students.component';
import { StudentworksMessagesListComponent } from './_partials/studentworks-messages-list/studentworks-messages-list.component';
import { TodoTasksComponent } from './school/todo-tasks/todo-tasks.component';
import { ExaminationsComponent } from './admin/examinations/examinations.component';

const routes: Routes = [
  { path:'', component: SiteLayoutComponent, children:[
      // for logged in users only
      { path:'', component: GeneralIndexComponent, pathMatch:'full' },
      { path:'login', component: AuthComponent, pathMatch:'full' },
      { path:'exercise/:exerciseId/:studentworkId', component: ExerciseComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'exercise/:exerciseId', component: ExerciseComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'exercises', component: ExercisesListComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'studentworks', component: StudentworksHomeComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'student-journeys', component: JourneysHomeComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'attempt/:studentStepId', component: AttemptComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'my-exercises-list', component: MyExercisesListComponent, pathMatch:'full', canActivate: [AuthGuard] },
      { path:'edit-resume', component: EditResumeComponent, pathMatch:'full', canActivate: [AuthGuard] },

      // for admin users only
      { path:'admin', component: AdminComponent, canActivate: [AuthGuard, TeacherGuard], children:[
          { path:'', component: AdminIndexComponent, pathMatch:'full' },
          { path:'homeworks', component: HomeworksComponent, pathMatch:'full' },
          { path:'create-homework', component: CreateHomeworkComponent, pathMatch:'full' },
          { path:'schoolclasses', component: SchoolclassesComponent, pathMatch:'full' },
          { path:'edit-schoolclass/:schoolclassId', component: EditSchoolclassComponent, pathMatch:'full' },
          { path:'school', component: AdminSchoolComponent, pathMatch:'full' },
          { path:'links', component: AdminLinksComponent, pathMatch:'full' },
          { path:'exercises', component: ExercisesComponent, pathMatch:'full' },
          { path:'questions/:exerciseId', component: QuestionsComponent, pathMatch:'full' },
          { path:'questions/:questionId/edit-content', component: EditQuestionContentComponent, pathMatch:'full' },
          { path:'questions/:questionId/edit-detail', component: EditQuestionDetailComponent, pathMatch:'full' },
          { path:'homeworks-summary', component: HomeworksSummaryComponent, pathMatch:'full' },
          { path:'journeys', component: JourneysComponent, pathMatch:'full' },
          { path:'schoolclass-journeys', component: SchoolclassJourneysComponent, pathMatch:'full' },
          { path:'studentworks-messages-list', component: StudentworksMessagesListComponent, pathMatch:'full' },
          { path:'todo-tasks', component: TodoTasksComponent, pathMatch:'full' },
          { path:'examinations', component: ExaminationsComponent, pathMatch:'full' },
      ]},

      // for everyone
      { path:'links', component: LinksHomeComponent, pathMatch:'full' },
      { path:'about', component: AboutComponent, pathMatch:'full' },
    ]},

    { 
      path: 'schoolapp', component: SiteLayoutComponent, canActivate: [AuthGuard, TeacherGuard], children: [
        { path: '', component: CoursesListComponent, pathMatch: 'full'},
        { path: 'my-students', component: MyStudentsComponent, pathMatch: 'full' },
        { path: 'students', component: StudentsListComponent, pathMatch: 'full'},
        { path: 'course-programs-list', component: CourseProgramsListComponent, pathMatch: 'full' },
        { path: 'week-template', component: WeekTemplateComponent, pathMatch: 'full'},
        { path: 'schoolclass/:pk', component: SchoolclassComponent, pathMatch: 'full'},
        { path: 'courses-list', component: CoursesListComponent, pathMatch: 'full'},
        { path: 'edit-course/:pk', component: EditCourseComponent, pathMatch: 'full' },
        { path: 'sanctions-admin', component: SanctionsAdminComponent, pathMatch: 'full' },
        { path: 'tasks-admin', component: TasksAdminComponent, pathMatch: 'full' },
        { path: 'upload-data', component: UploadDataComponent, pathMatch: 'full'},
        { path: 'student-detail/:pk', component: StudentDetailComponent, pathMatch: 'full' },
        { path: 'parent-detail/:pk', component: ParentDetailComponent, pathMatch: 'full' },
        { path: 'notes-list', component: NotesListComponent, pathMatch: 'full' },
        { path: 'exam-detail/:pk', component: ExamDetailComponent, pathMatch: 'full' }
      ]
    },

    { path:'resumes/1', component: Resume1Component, pathMatch:'full', canActivate: [AuthGuard] },
    { path:'resumes/2', component: Resume2Component, pathMatch:'full', canActivate: [AuthGuard] },
    { path:'resumes/3', component: Resume3Component, pathMatch:'full', canActivate: [AuthGuard] },

    { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
