import { Component, OnInit, Input } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditObjectDialogComponent } from 'src/app/school/_dialogs/edit-object-dialog/edit-object-dialog.component';
import { Models } from 'src/app/school/models/api-models';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ShowObjectDetailDialogComponent } from 'src/app/school/show-object-detail-dialog/show-object-detail-dialog.component';

@Component({
  selector: 'update-delete',
  templateUrl: './update-delete.component.html',
  styleUrls: ['./update-delete.component.scss']
})
export class UpdateDeleteComponent implements OnInit {

  @Input()
  public url: string;

  @Input()
  public text: string;

  @Input()
  public parentComponent: any;

  @Input()
  public elemId: number;

  @Input()
  public model: object;

  @Input()
  public modelName: string;

  @Input()
  public showOverview: boolean = false;

  constructor(public snackBar: MatSnackBar,
              private dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private _apiService: ApiService) { }

  ngOnInit(): void {
  }

  openEditDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'pk': this.elemId, 'endPoint': this.url, 'modelName': this.modelName}

    let dialogRef = this.dialog.open(EditObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.parentComponent.parentComponent.refresh();
    });
  }

  openDeleteDialog(){
    if(confirm("Êtes-vous certain(e) de vouloir supprimer cette entrée ? Cette action est irréversible.")){
      this._apiService.deleteObject(this.url).subscribe(
        data => {
          this.parentComponent.parentComponent.refresh();
        }
      );
    }
  }

  openShowDetailDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'pk': this.elemId, 'modelName': this.modelName}

    let dialogRef = this.dialog.open(ShowObjectDetailDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      // this.parentComponent.parentComponent.refresh();
    });
  }
}
