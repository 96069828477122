<h2 mat-dialog-title *ngIf="studentStep">
    Tentative de réussite de l'étape sélectionnée - Nombre d'exercices corrigés dans cette étape : {{correctedExercisesCount}} / {{selectedExercises.length}}
</h2>

<mat-stepper 
        *ngIf="selectedExercises"
        orientation="vertical"
        #stepper>
    <mat-step *ngFor="let exercise of selectedExercises; let i = index">
        <ng-template matStepLabel>{{exercise.name}} - Progression :</ng-template>
        <exercise
            [exerciseId]="exercise.id"
            [isInAStep]="true"
            (correctedValue)="getCorrectedValue($event)">
        </exercise>
    </mat-step>
</mat-stepper>
