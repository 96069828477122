
<mat-card>
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>Choisissez une période</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input formControlName="startDate" matStartDate placeholder="Date de début" (dateChange)="loadSummaries()">
              <input formControlName="endDate" matEndDate placeholder="Date de fin" (dateChange)="loadSummaries()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        &nbsp;&nbsp;&nbsp;

        <mat-form-field appearance="outline">
            <mat-label>Classe</mat-label>
            <mat-select formControlName="schoolclass" (selectionChange)="loadSummaries()">
              <mat-option *ngFor="let schoolclass of schoolclasses" [value]="schoolclass.id">
                {{schoolclass.name}} ({{schoolclass.id}})
              </mat-option>
            </mat-select>
          </mat-form-field>
    </form>

    <!--<button
        mat-flat-button
        color="primary"
        (click)="loadSummaries()">
            Charger les données
    </button>-->
</mat-card>

<br>
<hr>
<br>

<ng-container *ngIf="summaries">
  <ng-container *ngIf="summaries.length>0; else noData">
    <mat-card *ngFor="let summary of summaries" class="summary black">
      <mat-card-content>
        <div>
          <h3>Résumé des devoirs pour l'élève {{summary.student}} (classe {{selectedSchoolclass}})</h3>
          <div>Entre le {{datetimeService.fromDateToStringCH(startDate)}} et le {{datetimeService.fromDateToStringCH(endDate)}}, l'élève a terminé <u><strong>{{summary.finished_studentworks}} devoirs</strong></u> sur un <u><strong>total de {{summary.total_studentworks_count}}</strong></u> (temps passé : {{datetimeService.fromSecondsToMinutes(summary.total_time_spent)}}).</div>
          <div class="footer">
            <div class="footer-left">
              L'enseignant(e) : {{teacher}}
            </div>
            <div class="footer-right">
              Signature des parents : ...................................................
            </div>
          </div>
          
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>

<ng-template #nodata>
  Aucune donnée trouvée
</ng-template>