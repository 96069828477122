<h1>Editer un objet</h1>

<mat-dialog-content *ngIf="objectForm">
    <form [formGroup]="objectForm" (submit)="save()">
        <p *ngFor="let field of objectForm.controls | keyvalue">
            <ng-container [ngSwitch]="model.getAttributeType(field.key)">
                <ng-container *ngSwitchCase="'text'">
                    <mat-form-field class="full-width" *ngIf="field.key != 'id'">
                        <mat-label>{{ field.key }}</mat-label>
                        <input matInput formControlName="{{field.key}}" value="{{field.value}}" (keyup.enter)="save()">
                    </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                    <mat-form-field class="full-width" *ngIf="field.key != 'id'">
                        <mat-label>{{ field.key }}</mat-label>
                        <mat-select formControlName="{{field.key}}">
                            <mat-option *ngFor="let option of selectChoices[field.key]"
                                        [value]="option[0]">
                                        {{option[1]}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'rich-text'">
                    <label>{{ field.key }} (rich-text)</label>
                    <angular-editor [placeholder]="'Enter text here...'"
                                    formControlName="{{field.key}}">
                    </angular-editor>
                </ng-container>
                <ng-container *ngSwitchCase="'color'">
                    <mat-form-field class="full-width" *ngIf="field.key != 'id'">
                        <mat-label>{{ field.key }}</mat-label>
                        <input matInput type="color" formControlName="{{field.key}}" value="{{field.value}}" (keyup.enter)="save()">
                    </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                    <mat-form-field class="full-width" *ngIf="field.key != 'id'">
                        <mat-label>{{ field.key }}</mat-label>
                        <input matInput type="date" formControlName="{{field.key}}" value="{{field.value}}" (keyup.enter)="save()">
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </p>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()"> Annuler</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="save()" (keyup.enter)="save()">
        Enregistrer
    </button>
</mat-dialog-actions>