<ng-container *ngIf="showEditChoices">
    <div *ngIf="questionDetailId&&choices">
        <u>Edition pour le détail de question n°{{questionDetailId}}.</u>
    </div>
    
    <br *ngIf="choices">
    
    <dx-data-grid
        *ngIf="choices"
        id="gridContainer"
        [dataSource]="choices"
        [showBorders]="true"
        [remoteOperations]="true"
        (onRowInserting)="insertQuestionDetailId($event)"
        (onRowUpdated)="updateChoice($event)"
        (onRowRemoved)="deleteChoice($event)">
        
        <dxo-editing 
            mode="row"
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true">
        </dxo-editing>

        <dxi-column
            dataField="content"
            dataType="string">
        </dxi-column>
    </dx-data-grid>

    <br *ngIf="choices">

    <button
    mat-flat-button
    color="warn"
    (click)="copyChoices()">
    Appliquer à tous
    </button>

    &nbsp;

    <button
    mat-flat-button
    color="primary"
    (click)="closeChoicesEdition()">
    Fermer
    </button>

</ng-container>