import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'team-points-list',
  templateUrl: './team-points-list.component.html',
  styleUrls: ['./team-points-list.component.scss']
})
export class TeamPointsListComponent implements OnInit {

  @Input()
  public schoolclass: any;

  @Input()
  public team: any;

  @Output()
  public onPointsModified: any = new EventEmitter();

  @ViewChild(DxDataGridComponent)
  public workPointsDatagrid: DxDataGridComponent;

  public workPoints: any = {};
  public series: any[];
  public chartsData: any = {};
  public teams: any[] = [];

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.series = [
      { value: 'number', name: 'Nombre' },
    ];

    this.loadTeams();
    this.loadCompetitorPoints();
    // this.loadGraphData();
    // this.chartsData = this._dG.createStore(`/api/schoolapp/dx-competitors-teams-points/`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.team = changes.team.currentValue;
    // if (changes.team.previousValue && changes.team.previousValue != changes.team.currentValue){
    //   this.workPointsDatagrid.instance.refresh();
    //   this.loadGraphData();
    // }
  }

  loadTeams(){
    this._apiService.getObjectsList(`/api/schoolapp/dx-competitors-teams/?filter=["schoolclass__id","=","${this.schoolclass.id}"]`).subscribe({
      next: (data:any) => {
        this.teams = data.data;
        console.log('TEAMS :', this.teams);
        this.loadGraphData();
      }
    })
  }

  loadCompetitorPoints(){
    this.workPoints = this._dG.createStore(`/api/schoolapp/dx-competitors-teams-points/`);
  }

  loadGraphData(){
    this.chartsData = [];

    this._apiService.getObjectsList(`/api/schoolapp/schoolclass-teams-points-for-charts/${this.schoolclass.id}/`).subscribe({
      next: (data:any) => {
        this.chartsData = data.data;
        this.onPointsModified.emit('points modified');
        // console.log('CHARTS DATA :', this.chartsData);
      }
    });    
  }

  insertRow(event:any,pointType:number){
    // event['data']['team'] = this.team.id;
    event['data']['typeof'] = pointType;
  }

  updateRow(event:any){
    // event['newData']['team'] = this.team.id;
  }

  teamFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;

    if (selectedFilterOperation === "=") {
        const filterExpression = ["team__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }

  schoolclassFilterExpression (filterValue, selectedFilterOperation) {
    const column = this as any;

    if (selectedFilterOperation === "=") {
        const filterExpression = ["team__schoolclass__id", "=", filterValue];
        return filterExpression;
    }
    // Invoke the default implementation for other filter operations
    return column.defaultCalculateFilterExpression.apply(column, arguments);
  }
}
