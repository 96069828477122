import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ApiService } from '../../services/api-service/api.service';
import { DatagridService } from '../../services/datagrid-service/datagrid.service';

@Component({
  selector: 'app-journeys',
  templateUrl: './journeys.component.html',
  styleUrls: ['./journeys.component.scss']
})
export class JourneysComponent implements OnInit {

  public dataSource: any;

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.dataSource = this._dG.createStore(`api/dx-journeys/`,'data');
  }
}
