import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './../user-service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {

  constructor(public _userService: UserService,
              public router: Router,
              private matSnackBar: MatSnackBar,) {}

  canActivate(): boolean {
    if (!this._userService.isAuthenticated()) {
      this.matSnackBar.open('Veuillez vous connecter pour continuer','Fermer');
      this.router.navigate(['login']);
      window.location.reload();
      return false;
    }
    return true;
  }
}