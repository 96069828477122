<h2>Liste de tes parcours</h2>

<div *ngIf="studentJourneys&&studentJourneys.length>0; else noContent">
    <student-journey
        *ngFor="let studentJourney of studentJourneys"
        [studentJourney]="studentJourney"
        (studentStepIsFinished)="getStudentJourneyValue($event)">
    </student-journey>
</div>

<ng-template #noContent>
    Tu n'as aucun parcours à faire pour le moment.
</ng-template>