import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }

  fromTimestampToDate(timestamp:number){
    return new Date(timestamp * 1e3).toLocaleDateString("fr-CH");
  }

  fromTimestampToDateTime(timestamp:number){
    // return new Date(timestamp * 1e3).toLocaleDateString("fr-CH") + ' à ' + new Date(timestamp * 1e3).toLocaleTimeString("fr-CH");
    return new Date(timestamp * 1e3);
  }

  fromTimestampToDateUS(timestamp:number){
    return new Date(timestamp * 1e3).toLocaleDateString("en-US");
  }

  fromDateToStringCH(d:Date){
    if(d){
      return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear();
    }
    return '';
  }

  fromDateToStringUS(d:Date){
    return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
  }

  fromSecondsToMinutes(seconds:number){
    return new Date(seconds * 1000).toISOString().substr(11, 8)
  }

  fromSecondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d == 1 ? " jour, " : " jours, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " heure, " : " heures, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " seconde" : " secondes") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  /**
   * Calculate difference between 2 date objects
   * @param a Date
   * @param b Date
   */
  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
}
