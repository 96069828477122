import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-exam-detail',
  templateUrl: './exam-detail.component.html',
  styleUrls: ['./exam-detail.component.scss']
})
export class ExamDetailComponent implements OnInit {

  public pk: number;
  public exam: any;
  public students: any[];

  public types: any = {
    short_exam:'Contrôle de leçon',
    oral:'Oral',
    dictation:'Dictée',
    vocabulary:'Vocabulaire'
  }

  public formGroup: FormGroup;
  public filteredOptions: Observable<string[]>;

  public displayedColumns: any[] = [
    {name: 'student', dataBinding: 'student_detail.name', title: 'Elève', type: 'text',},
    {name: 'score', dataBinding: 'value', title: 'Note', type: 'text',},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ];

  public dataSource: any = new MatTableDataSource();

  constructor(private activatedRoute: ActivatedRoute,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.pk = this.activatedRoute.snapshot.params.pk;
    this.formGroup = new FormGroup({
      'scoreCtrl': new FormControl(''),
      'studentCtrl': new FormControl('')
    });
    this.getExam(this.pk);
    this.getScores(this.pk);
  }

  getExam(pk: number){
    this._apiService.getObjectDetail(`exams/${this.pk}`).subscribe(
      data => {
        this.exam = data;
        this.students = this.exam.course_detail.course_program.students_details;
        this.initForm();
      }
    )
  }

  getScores(examPk: number){
    this._apiService.getObjectsList(`scores/?exam=${examPk}`).subscribe(
      data => {
        this.dataSource.data = data;
        console.log('NOTES : ', data);
      }
    )
  }

  initForm(){
    this.filteredOptions = this.formGroup.controls.studentCtrl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.students.slice())
    );
  }

  public displayFn(student: any): string {
    return student && student.name ? student.name : '';
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.students.filter(student => student.name.toLowerCase().indexOf(filterValue) === 0);
  }

  save(){
    let student = this.formGroup.controls.studentCtrl.value.id;
    let score   = this.formGroup.controls.scoreCtrl.value;

    let data = {
      "value":score,
      "student":student,
      "exam":this.exam.id
    }

    this._apiService.createObject('scores/',data).subscribe(
      data => {
        this._snackBar.open('Note ajoutée','Fermer');
        this.refresh();
        this.initForm();
      },
      err => {
        console.log(err);
        this._snackBar.open('Une erreur est survenue. Peut-être qu\'une note a déjà été attribuée à cet élève pour ce cours.','Fermer');
      }
    )
  }

  refresh(){
    this.getScores(this.pk);
  }

  updateDeleteUrl(pk: number){
    return `scores/${pk}`;
  }
}
