<div *ngIf="dataSource" class="content-block">
    <h2>
        Liste des Parcours d'élèves en cours
    </h2>

    <dx-data-grid 
        #gridContainer
        id="gridContainer"
        [dataSource]="dataSource"
        [showBorders]="true"
        [remoteOperations]="false"
        dateSerializationFormat="yyyy-MM-dd"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [rowAlternationEnabled]="false">
        <dxo-header-filter 
        [visible]="true"></dxo-header-filter>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager 
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[5, 10, 20]"
            [showInfo]="true"
            [visible]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>
        <!--<dxo-editing
            [allowUpdating]="false"
            [allowAdding]="true"
            [allowDeleting]="true"
            mode="row">
        </dxo-editing>-->
        <!--<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>-->
        <dxi-column
            dataField="student"
            dataType="string"
            caption="Élève"
            [allowHeaderFiltering]="false"
            [allowFiltering]="true"
            cellTemplate="studentCellTemplate">
        </dxi-column>
        <dxi-column
            dataField="id"
            dataType="number"
            caption="Avancement"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="progressCellTemplate">
        </dxi-column>

        <!--<dxi-column 
            dataField="id"
            dataType="number"
            caption="Actions"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="actionsTemplate">
        </dxi-column>-->
        
        <div *dxTemplate="let data of 'actionsTemplate'">
            <mat-icon aria-hidden="false"
                      aria-label="Example home icon"
                      class="pointer">edit</mat-icon>
        </div>

        <div *dxTemplate="let data of 'teacherCellTemplate'">
            <ng-container *ngIf="data.data.teacher">
               {{data.data.teacher_details.username}}
            </ng-container>
        </div>

        <div *dxTemplate="let data of 'studentCellTemplate'">
            <ng-container *ngIf="data.data.student">
                {{data.data.student_details.username}}
            </ng-container>
        </div>

        <div *dxTemplate="let data of 'progressCellTemplate'">
            <mat-icon
                *ngFor="let item of fakeArray(data.data.finished_steps_count)" 
                aria-hidden="false"
                aria-label="Finished steps">
                    star
            </mat-icon>
            <mat-icon
                *ngFor="let item of fakeArray(data.data.in_progress_steps_count)" 
                aria-hidden="false"
                aria-label="In progress steps">
                    star_half
            </mat-icon>
            <mat-icon
                *ngFor="let item of fakeArray(data.data.not_started_steps_count)" 
                aria-hidden="false"
                aria-label="Not started steps">
                    star_border
            </mat-icon>
        </div>

        <div *dxTemplate="let data of 'detail'">
            NADA
            <!--<steps [journeyId]="data.data.id"></steps>-->
        </div>
    </dx-data-grid>
</div>
