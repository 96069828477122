import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'exercise-infos',
  templateUrl: './exercise-infos.component.html',
  styleUrls: ['./exercise-infos.component.scss']
})
export class ExerciseInfosComponent implements OnInit {

  @Input()
  public exerciseId: number;

  public exercise: any;

  constructor(private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.loadExercise();
  }

  loadExercise(){
    this._apiService.getObjectDetail(`/api/dx-exercises/${this.exerciseId}/`).subscribe({
      next: (data:any) => {
        this.exercise = data;
      },
      error: (err:any) => {
        console.log(err);
      }
    })
  }
}
