import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'question-correction',
  templateUrl: './question-correction.component.html',
  styleUrls: ['./question-correction.component.scss']
})
export class QuestionCorrectionComponent implements OnInit {

  @Input()
  public question: any;

  @Input()
  public exerciseCorrectionId: number;

  constructor() { }

  ngOnInit(): void {
    console.log(this.question);
  }

}
