import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'app-edit-resume',
  templateUrl: './edit-resume.component.html',
  styleUrls: ['./edit-resume.component.scss']
})
export class EditResumeComponent implements OnInit {

  public resume: any;
  public pictureSrc: string;

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              private _http: HttpClient,) { }

  ngOnInit(): void {
    this.loadResume();
  }

  loadResume(){
    this._apiService.getObjectDetail('api/get-or-create-resume/').subscribe(
      data => {
        this.resume = data;
        if(this.resume.picture_detail){
          console.log('PICTURE DETAIL :', this.resume.picture_detail);
          this.pictureSrc = `data:${this.resume.picture_detail.mime_type};base64,${this.resume.picture_detail.b64_encoded}`;
        }
      }
    );
  }

  uploadFile(event){
    if(confirm("Voulez-vous vraiment changer l'image de profile ?")){
      this._apiService.patchFile(`api/dx-files-resumes/${this.resume.id}/`, event.target.files[0]).subscribe(
        data => {
          this.loadResume();
          this._snackBar.open("Image changée avec succès","Fermer");
        },
        error => {
          this._snackBar.open("Une erreur est survenue","Fermer");
        }
      );
    }
  }

  generateDocx(templateName:string){
    // window.location.href=`api/generate-resume-docx/${this.resume.id}/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Access-Control-Allow-Origin': '*',
    });

    this._http.get(`api/generate-resume-docx/${this.resume.id}/${templateName}/`,{headers:headers, responseType:"blob" as "json"}).subscribe({
      next: (data:any) => {
        let a      = document.createElement("a");
        a.href     = URL.createObjectURL(data);
        a.download = "cv";
        a.click();
      }
    });
    // this._apiService.getObjectDetail(`api/generate-resume-docx/${this.resume.id}/`).subscribe({
    //   next: (data:any) => {
    //     console.log('done');
    //   }
    // })
  }
}
