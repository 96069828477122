<div class="content-block">
    <h2>
        Liste des exercices que j'ai noté comme difficiles
    </h2>
    <div>
        <dx-data-grid 
            #gridContainer
            id="gridContainer"
            [dataSource]="dataSource"
            [showBorders]="true"
            [remoteOperations]="false">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-paging [pageSize]="10"></dxo-paging>

            <dxo-pager 
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5, 10, 20]"
                [showInfo]="true"
                [visible]="true">
            </dxo-pager>

            <dxi-column
                dataField="yearsubject"
                dataType="object"
                caption="Branche/Année"
                cellTemplate="yearsubjectCellTemplate">
            </dxi-column>
            <dxi-column
                dataField="theme"
                dataType="object"
                caption="Thème"
                cellTemplate="themeCellTemplate">
            </dxi-column>
            <dxi-column
                dataField="name"
                dataType="string"
                caption="NOM"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="statement"
                dataType="string"
                caption="Consigne"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="id"
                dataType="number"
                caption="Actions"
                [allowHeaderFiltering]="false"
                cellTemplate="actionsTemplate">
            </dxi-column>
            
            <div *dxTemplate="let data of 'actionsTemplate'">
                <mat-icon
                    aria-hidden="false"
                    class="link"
                    aria-label="Retirer de ma liste"
                    matTooltip="Retirer de ma liste"
                    (click)="removeExercise(data.data.id)">
                        delete
                </mat-icon>
                <mat-icon
                    aria-hidden="false"
                    class="link"
                    aria-label="Lien vers l'exercice"
                    matTooltip="Lien vers l'exercice"
                    [routerLink]="['/exercise',data.data.id,]">
                        double_arrow
                </mat-icon>
            </div>

            <div *dxTemplate="let data of 'yearsubjectCellTemplate'">
                <ng-container *ngIf="data.data.yearsubject">
                    {{data.data.yearsubject_details.name}}
                </ng-container>
            </div>

            <div *dxTemplate="let data of 'themeCellTemplate'">
                <ng-container *ngIf="data.data.theme_details">
                    {{data.data.theme_details.name}} - {{data.data.yearsubject_details.name}}
                </ng-container>
            </div>

            <div *dxTemplate="let data of 'questionsCellTemplate'">
                <mat-icon
                    aria-hidden="false"
                    aria-label="Vers les questions"
                    [routerLink]="['/admin','questions',data.data.id]">
                        help
                </mat-icon>
            </div>
        </dx-data-grid>
    </div>
</div>
