<h1 *ngIf="course"
    fxLayout="row"
    fxLayoutAlign="space-between">
    <div>
        {{course.schoolclass.name}} - {{course.date | date:'dd.MM.yyyy'}} 
        <button
            *ngIf="course.status==0"
            mat-raised-button color="primary"
            (click)="closeCourse(course.id)">
            Terminer le cours
        </button>
    </div>
    <div (mouseover)="showBonusBtn=true" (mouseout)="showBonusBtn=false">
        <div matBadge="{{ course.schoolclass.bonus_points }}" matBadgeOverlap="false" class="small" matBadgeColor="accent">
            Points de classe
            <!--<br>
            <button mat-mini-fab color="warn" matTooltip="Ajouter un point à la classe" aria-label="Ajouter un point à la classe" (click)="addBonusPoint()">
                <mat-icon>plus_one</mat-icon>
            </button>-->
        </div>
    </div>
    <div>
        <div matBadge="{{ course.schoolclass.rewards }}" matBadgeOverlap="false" class="small" matBadgeColor="accent">
            Récompenses
            <br>
            <button mat-mini-fab color="warn" matTooltip="Ajouter une récompense à la classe" aria-label="Ajouter un point à la classe" (click)="addReward()">
                <mat-icon>plus_one</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-raised-button (click)="redirect(previousPk)"><</button>
        <button mat-raised-button (click)="redirect(nextPk)">></button>
    </div>
</h1>

<div class="content"
     fxLayout="column"
     fxLayoutAlign="space-between stretch"
     fxLayoutGap="10px">
    <mat-card>
        <mat-card-title>
            Préparation
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="courseForm">
                <angular-editor [placeholder]="'Enter text here...'"
                                formControlName="coursePreparation">
                </angular-editor>
                <br>
                <button mat-raised-button color="accent" (click)="onSubmit()">Enregistrer</button>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-accordion *ngIf="course">
        <mat-expansion-panel hideToggle [expanded]="step === 0" (opened)="setStep(0)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Punitions
                </mat-panel-title>
                <mat-panel-description>
                    {{course.sanctions_count}} punition(s) non terminée(s)
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="column">
                <mat-card>
                    <mat-card-title fxLayout="row"
                                    fxLayoutAlign="space-between stretch">
                        <div>
                            <button mat-raised-button
                                    color="accent"
                                    matTooltip="Donner une punition à un élève"
                                    matTooltipPosition="left"
                                    (click)="openAddSanctionDialog()">
                                <span class="material-icons">
                                    add
                                </span>
                            </button>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <dx-sanctions-list [courseId]="pk"></dx-sanctions-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle [expanded]="step === 1" (opened)="setStep(1)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Tâches
                </mat-panel-title>
                <mat-panel-description>
                    {{course.tasks_count}} tâche(s) non terminée(s)
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="column">
                <mat-card>
                    <mat-card-title fxLayout="row"
                                    fxLayoutAlign="space-between stretch">
                        <div>
                            <button mat-raised-button
                                    color="accent"
                                    matTooltip="Ajouter une tâche à toute la classe"
                                    matTooltipPosition="left"
                                    (click)="openAddTaskDialog()">
                                <span class="material-icons">
                                    add
                                </span>
                            </button>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <dx-tasks-list [coursePk]="pk"></dx-tasks-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-expansion-panel>
        <!--<mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Liste des élèves de cette classe
                </mat-panel-title>
                <mat-panel-description>
                    
                </mat-panel-description>
            </mat-expansion-panel-header>
            <competitors-list *ngIf="course&&course.schoolclass" [schoolclass]="course.schoolclass"></competitors-list>
        </mat-expansion-panel>-->
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Points de comportement pour ce cours
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <competitor-behaviour-course-points-list *ngIf="course" [course]="course"></competitor-behaviour-course-points-list>
        </mat-expansion-panel>
        <!--<mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Liste des équipes de cette classe
                </mat-panel-title>
                <mat-panel-description>
                    
                </mat-panel-description>
            </mat-expansion-panel-header>
            <competitors-teams-list *ngIf="course&&course.schoolclass" [schoolclass]="course.schoolclass"></competitors-teams-list>
        </mat-expansion-panel>-->
    </mat-accordion>
</div>