import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './../user-service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class TeacherGuardService implements CanActivate {

  constructor(private _userService: UserService,
              private _matSnackBar: MatSnackBar,
              private _router: Router,) { }

  canActivate(): boolean {
    if (!this._userService.isTeacher()) {
      this._matSnackBar.open("Vous n'avez pas l'autorisation d'accéder à cette page.",'Fermer');
      this._router.navigate(["/",]);
      return false;
    }
    return true;
  }
}
