import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AttemptComponent } from '../attempt/attempt.component';
import { ApiService } from '../services/api-service/api.service';
import { DatetimeService } from '../services/datetime.service';

@Component({
  selector: 'student-journey',
  templateUrl: './student-journey.component.html',
  styleUrls: ['./student-journey.component.scss']
})
export class StudentJourneyComponent implements OnInit {

  @Input()
  public studentJourney:any;

  public j: any;

  @Output()
  public studentStepIsFinished = new EventEmitter();

  @ViewChild('stepper') private _stepper: MatStepper;

  public studentSteps: any[] = [];
  public now: Date;

  public attempts_diff: number;
  public attempts_diff_str: string;
  
  public steps_diff: number;
  public steps_diff_str: string;

  public next_allowed_attempt: any;
  public next_allowed_step: any;

  private _attemptsInterval: any;
  private _nextStepInterval: any;

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              public datetimeService: DatetimeService,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadStudentSteps();
    this.setNow();
    this.next_allowed_attempt = this.datetimeService.fromTimestampToDateTime(Date.parse(this.studentJourney.next_allowed_attempt)/1000);
    this.next_allowed_step    = this.datetimeService.fromTimestampToDateTime(Date.parse(this.studentJourney.next_allowed_step)/1000);

    if(this.now<this.next_allowed_attempt){
      this._attemptsInterval = setInterval(() => {
        this.calcAttemptsDiff(this.next_allowed_attempt);
      }, 1000);
    }

    if(this.now<this.next_allowed_step){
      this._nextStepInterval = setInterval(() => {
        this.calcStepsDiff(this.next_allowed_step);
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this._attemptsInterval);
    clearInterval(this._nextStepInterval);
  }

  loadStudentSteps(){
    this.studentSteps = [];
    this.loadStudentStep();
    setTimeout(() => 
      {
          this.moveToStepToContinue();
      },
      1000
    );
  }

  setNow(){
    this.now = new Date();
  }

  calcAttemptsDiff(next_allowed_attempt:any){
    this.setNow();
    this.attempts_diff = this.now.getTime() - next_allowed_attempt.getTime();
    this.attempts_diff = this.attempts_diff > 0 ? this.attempts_diff: -this.attempts_diff;
    this.attempts_diff_str = this.datetimeService.fromSecondsToDhms(this.attempts_diff/1000);
  }

  calcStepsDiff(next_allowed_step:any){
    this.steps_diff = this.now.getTime() - next_allowed_step.getTime();
    this.steps_diff = this.steps_diff > 0 ? this.steps_diff: -this.steps_diff;
    this.steps_diff_str = this.datetimeService.fromSecondsToDhms(this.steps_diff/1000);
  }

  moveToStepToContinue(){
    this.studentSteps.forEach(studentStep => {
      if (studentStep.status === 2){
        this.goForward();
      }
    })
  }

  goForward(){
    this._stepper.next();
  }

  loadStudentStep(nextStepId?:number){
    let endPoint = `api/dx-student-steps/`;
    let filter   = '';

    if (!nextStepId){
      filter   = `[["student_journey","=",${this.studentJourney.id}],"and",["is_first_student_step","=",true]]`;
    } else {
      filter   = `[["student_journey","=",${this.studentJourney.id}],"and",["is_first_student_step","=",false],"and",["step","=",${nextStepId}]]`;
    }

    const url    = `${endPoint}?filter=${filter}`;

    this._apiService.getObjectDetail(`${endPoint}?filter=${filter}`).subscribe(
      data => {
        if (data['totalCount'] === 1) {
          this.studentSteps.push(data['data'][0]);
          if (data['data'][0].next_step) {
            this.loadStudentStep(data['data'][0].next_step);
          }
        } else if (data['totalCount'] === 0) {
          this._snackBar.open("Ce parcours n'a pas de point de départ valide. Veuillez en informer votre enseignant(e).","Fermer");
        } else {
          this._snackBar.open("Ce parcours possède plusieurs points de départ, ce qui est incorrect. Veuillez en informer votre enseignant(e).","Fermer");
        }
      }
    );
  }

  openAttemptDialog(studentStep: any){

    // implémenter ici la logique pour le contrôle de la date la dernière fois où l'élève a
    // essayé de passer le step (studentJourney.last_student_attempt à opposer à studentJourney.min_interval_between_attemps)
    // et studentJourney.min_interval_between_steps

      const dialogRef = this.dialog.open(AttemptComponent, {
        width: '100%',
        height: '500px',
        data: {studentStep:studentStep}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.loadStudentSteps();
      });
  }
}
