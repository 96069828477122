import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ExerciseDialogComponent } from 'src/app/_dialogs/exercise-dialog/exercise-dialog.component';

@Component({
  selector: 'studentworks-messages-list',
  templateUrl: './studentworks-messages-list.component.html',
  styleUrls: ['./studentworks-messages-list.component.scss']
})
export class StudentworksMessagesListComponent implements OnInit {

  @ViewChild(DxDataGridComponent)
  public datagrid: DxDataGridComponent;

  public studentworks: any = {};
  public exercisePopupVisible: boolean = false;
  public selectedExerciseId: number;

  public statusLookup: any[] = [
    {'status':true, 'name':'déjà traité'},
    {'status':false, 'name':'à traiter'},
  ];

  constructor(private _apiService: ApiService,
              public matDialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadStudentworks();
  }

  loadStudentworks(){
    this._apiService.getObjectsList('/api/studentworks-messages-list/').subscribe({
      next: (data:any) => {
        this.studentworks = data;
        console.log('STUDENTWORKS :', this.studentworks);
      }
    });
  }

  showExercise(exerciseId:number){
    this.exercisePopupVisible = true;
    this.selectedExerciseId = exerciseId;
  }

  /**
  * Open a dialog with exercise overview in it
  */
  openExerciseDialog(exerciseId:any): void {
    const dialogRef = this.matDialog.open(ExerciseDialogComponent, {
      width: '100%',
      data: exerciseId
    });

    dialogRef.afterClosed().subscribe(result => {
      // what to do after dialog is closed
    });
  }

  finish(studentWorkId:number){
    const data = {
      "message_is_read":true
    };
 
    this._apiService.patchObject(`/api/dx-all-studentworks/${studentWorkId}/`,data).subscribe({
      next: (data:any) => {
        this.loadStudentworks();
      }
    });
  }
}
