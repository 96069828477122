<div class="left-title">
    <h1>Édition d'exercices</h1>
    <h2>Étape 2 - Éditez le contenu de la question</h2>
</div>
<div class="right-actions">
    <button
        *ngIf="question"
        mat-flat-button
        color="primary"
        [routerLink]="['/admin','questions',question.exercise]">retour</button>
</div>

<mat-card class="help-content">
    <div>Entrez le texte et mettez-le en forme.</div>
    <div>Ne touchez pas aux textes sous la forme '!!123!!' éventuels.</div>
    <div>Pour ajouter un trou (blank) à un endroit, entrez 'WW'.</div>
    <div>Pour ajouter un QCM à un endroit, entrez 'CC'.</div>
</mat-card>

<br><br>

<dx-html-editor
    *ngIf="question"
    height="300px"
    (valueChange)="valueChange($event)">
    <dxo-toolbar [multiline]="true">
        <dxi-item name="undo"></dxi-item>
        <dxi-item name="redo"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
        <dxi-item name="font" [acceptedValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="bold"></dxi-item>
        <dxi-item name="italic"></dxi-item>
        <dxi-item name="strike"></dxi-item>
        <dxi-item name="underline"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="alignLeft"></dxi-item>
        <dxi-item name="alignCenter"></dxi-item>
        <dxi-item name="alignRight"></dxi-item>
        <dxi-item name="alignJustify"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="orderedList"></dxi-item>
        <dxi-item name="bulletList"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="color"></dxi-item>
        <dxi-item name="background"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="link"></dxi-item>
        <dxi-item name="image"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="clear"></dxi-item>
        <dxi-item name="codeBlock"></dxi-item>
        <dxi-item name="blockquote"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="insertTable"></dxi-item>
        <dxi-item name="deleteTable"></dxi-item>
        <dxi-item name="insertRowAbove"></dxi-item>
        <dxi-item name="insertRowBelow"></dxi-item>
        <dxi-item name="deleteRow"></dxi-item>
        <dxi-item name="insertColumnLeft"></dxi-item>
        <dxi-item name="insertColumnRight"></dxi-item>
        <dxi-item name="deleteColumn"></dxi-item>
    </dxo-toolbar>
    <dxo-media-resizing
        [enabled]=true>
    </dxo-media-resizing>
    <div [innerHTML]="question.content | safe: 'html'"></div>
</dx-html-editor>

<br><br>

<button
    mat-flat-button
    color="primary"
    (click)="nextStep()">
    Suivant
</button>