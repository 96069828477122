<h1 mat-dialog-title>Editer le thème</h1>

<div [formGroup]="editForm"
     mat-dialog-content>
  <mat-form-field>
    <mat-label>Nom</mat-label>
    <input matInput (keydown)="keyDownFunction($event)"
           formControlName="themeCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Annuler</button>
  <button mat-button (click)="save()" cdkFocusInitial>Enregistrer</button>
</div>