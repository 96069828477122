import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';

@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit {

  @Input()
  public dataSource: any;

  @Input()
  public displayedColumns: any[];

  @Input()
  public parentComponent: any;

  @ViewChild(MatSort, {static: true})
  public sort: MatSort;

  public columnsNames: string[];

  constructor() { }

  ngOnInit(): void {
    /* Extract columns names from displayColumns */
    this.columnsNames = this.displayedColumns.map(column => column.name);

    /** Add children objects to the filtering */
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    }

    /** Add children objects to the sorting */
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    this.dataSource.sort = this.sort;
  }

  /* Apply filter to data table */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProperty(obj, property){
    /* Uses lodash magic power. Nearly the same as getattr() python native method. */
    return _.get(obj, property);
  }
}
