import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from '../services/api-service/api.service';
import { QuestionComponent } from '../question/question.component';
import { QuestionItem } from '../question/question-item';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ExerciseFeedbackComponent } from '../_dialogs/exercise-feedback/exercise-feedback.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit {
  
  @Input()
  public exercise: any = undefined;

  @Output()
  public correctedValue = new EventEmitter();

  @Input()
  public exerciseId: number;
  public studentworkId: number;

  @Input()
  public isInAStep: boolean = false;

  @ViewChildren(QuestionComponent) questions: QueryList<QuestionComponent>;

  public exerciseCorrection: any;
  public chunks: any[];
  // public questionDetailsCorrections: any;
  public correctedQuestionDetails: any;
  public isCorrected: boolean;
  public startDatetime: string;

  private _totalQuestionDetailsCount: number = 0;
  private _rightQuestionDetailsCount: number = 0;
  private _rightQuestionDetailsCountPercentage: number = 0;

  constructor(private _apiService: ApiService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    if(this._route.snapshot.params.exerciseId && !this.exerciseId){
      this.exerciseId  = this._route.snapshot.params.exerciseId;
    }

    if(this._route.snapshot.params.studentworkId){
      this.studentworkId = this._route.snapshot.params.studentworkId;

      this._apiService.getObjectDetail(`api/dx-studentworks/${this.studentworkId}/`).subscribe(
        data => {
          if (data['status'] === 2 || data['status'] === 3){
            // if the studentwork is already completed, no possibility to do it again.
            this._router.navigate(['/studentworks',]);
            this._snackBar.open("Ce devoir est terminé.","Fermer");
          }
        }
      )
    }

    this.chunks      = [];
    this.isCorrected = false;
    // this.questionDetailsCorrections = {};
    this.correctedQuestionDetails   = [];

    if(!this.exercise){
      this._apiService.getObjectDetail(`api/exercises/${this.exerciseId}/`).subscribe(
        data  => {
          this.exercise = data;
  
          const exerciseCorrection = {
            "total_answers_count": 0,
            "right_answers_count": 0,
            "right_answers_percentage": 0,
            "end_of_work": undefined,
            "exercise": this.exercise.id,
          }
  
          this._apiService.createObject(`api/dx-exercisecorrections/`,exerciseCorrection).subscribe(
            data => {
              this.exerciseCorrection = data;
            }
          )
        },
        error => this._snackBar.open("Une erreur est survenue","Fermer")
      );
    }
  }

  /**
   * Correct all questions/question_details of current exercise
   */
  correctExercise(){
    const currentTimestamp = new Date().getTime() / 1000 | 0 // the "| 0" is for rounding
    this.correctedQuestionDetails = [];

    this.questions.forEach(question => {
      // push corrected QuestionDetail in this.correctedQuestionDetails
      question.correctQuestion();
    });

    this.isCorrected = true;

    this.correctedQuestionDetails.forEach(correctedQuestionDetail => {
      this._totalQuestionDetailsCount++;
      if(correctedQuestionDetail.is_correct === true){
        this._rightQuestionDetailsCount++;
      }
    });

    if(this._totalQuestionDetailsCount > 0 && this._rightQuestionDetailsCount > 0){
      this._rightQuestionDetailsCountPercentage = Math.floor(this._rightQuestionDetailsCount / this._totalQuestionDetailsCount * 100);
    }

    const patchData = {"end_of_work":new Date(),
                       "total_answers_count":this._totalQuestionDetailsCount,
                       "right_answers_count":this._rightQuestionDetailsCount,
                       "right_answers_percentage":this._rightQuestionDetailsCountPercentage,};

    this._apiService.patchObject(`api/dx-exercisecorrections/${this.exerciseCorrection.id}/`, patchData).subscribe(
      data => {
        // send data to parent component if needed
        this.correctedValue.emit(data);

        if(this.studentworkId){
          this._apiService.patchObject(`api/correct-studentwork/${this.studentworkId}/exercise/${data['id']}/`, {}).subscribe(
            studentwork => {
              const percentage = `(${this._rightQuestionDetailsCountPercentage}% sur ${studentwork['min_percentage']}% demandés)`
              
              let message = '';
              
              if(studentwork['status'] === 2 || studentwork['status'] === 3){
                message = `Bravo ! Le devoir est validé ! ${percentage}`;
              } else {
                message = `Encore un effort ! Tu n'as pas assez de réponses justes ! ${percentage}`;
              }
              this.openRatingDialog(message, studentwork);
            }
          );
        }
      }
    )
  }

  correctionFromQuestion(obj){
    this.correctedQuestionDetails.push(obj);
  }

  /**
   * Reload current page/component (has to use the "change page and back" hack to work properly)
   */
  startAgain(){
    if(!this.isInAStep){
      this._router.navigate(['/'], {skipLocationChange: false}).then(
        ()=>{
          if (this.studentworkId) {
            this._router.navigate(['/exercise',this.exerciseId,this.studentworkId,])  
          } else {
            this._router.navigate(['/exercise',this.exerciseId,])
          }
        }
      );
    } else {
      this._snackBar.open("Tu ne peux pas refaire cet exercice maintenant, il fait partie d'un parcours. Termine tous les exercices de l'étape de parcours.","Fermer");
    }
  }

  openRatingDialog(message: string, studentwork: any): void {
    const dialogRef = this.dialog.open(ExerciseFeedbackComponent, {
      width: '50%',
      height: '500px',
      disableClose: true, // oblige à cliquer sur 'Fermer' pour quitter le dialog (donc à sauvegarder ce qui doit l'être)
      data: {studentwork:studentwork,
             message:message,}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
