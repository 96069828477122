<ng-container *ngIf="isLoaded">
    <ng-container [ngSwitch]="questionDetail.question_detail_type">
        <ng-container *ngSwitchCase="'questions réponses'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="questionDetail.questionDetailCorrection.answer_given">
            </mat-form-field>
            <mat-icon *ngIf="questionDetail.questionDetailCorrection.is_correct"
                    class="material-icons color_green">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!questionDetail.questionDetailCorrection.is_correct"
                    matTooltip="{{questionDetail.correction}}"
                    class="material-icons color_red">highlight_off</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'textes à trous'">
            <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="questionDetail.questionDetailCorrection.answer_given">
            </mat-form-field>
            <mat-icon *ngIf="questionDetail.questionDetailCorrection.is_correct"
                    class="material-icons color_green">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!questionDetail.questionDetailCorrection.is_correct"
                    matTooltip="{{questionDetail.correction}}"
                    class="material-icons color_red">highlight_off</mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'choix multiples'">
            <mat-form-field appearance="fill">
                <mat-select [(ngModel)]="questionDetail.questionDetailCorrection.answer_given">
                    <mat-option *ngFor="let choice of questionDetail.choices" [value]="choice">
                    {{choice}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-icon *ngIf="questionDetail.questionDetailCorrection.is_correct"
                    class="material-icons color_green">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!questionDetail.questionDetailCorrection.is_correct"
                    matTooltip="{{questionDetail.correction}}"
                    class="material-icons color_red">highlight_off</mat-icon>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span [innerHTML]="chunk.content | safe: 'html'"></span>
        </ng-container>
    </ng-container>
</ng-container>
