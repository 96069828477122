<div class="row">
    <div class="column">
        <strong>Points attribués pour le travail effectué</strong>
        <dx-data-grid
            id="workPointsDatagrid"
            class="points-list"
            [dataSource]="workPoints"
            [remoteOperations]="true"
            [filterValue]="['schoolclass','=',schoolclass.id]"
            (onRowInserting)="insertRow($event,1)"
            (onRowUpdating)="updateRow($event)"
            (onRowInserted)="loadGraphData()"
            (onRowRemoved)="loadGraphData()"
            (onRowUpdated)="loadGraphData()">
            <dxo-paging [pageSize]="5"> </dxo-paging>
            <dxo-pager
                [visible]="true"
                [allowedPageSizes]="[5]"
                [showPageSizeSelector]="false"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-editing
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true">
            </dxo-editing>
            <dxo-filter-row
                [visible]="true">
            </dxo-filter-row>
    
            <dxi-column
                dataField="created_at"
                dataType="date"
                format="dd.MM.yyyy"
                caption="Date"
                [allowEditing]="false">
            </dxi-column>
            <dxi-column
                dataField="team"
                [allowEditing]="true"
                [calculateFilterExpression]="teamFilterExpression">
                <dxo-lookup
                    [dataSource]="teams"
                    valueExpr="id"
                    displayExpr="name">
                </dxo-lookup>
            </dxi-column>
            <dxi-column
                dataField="number"
                dataType="number"
                caption="Points reçus"
                [allowFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="work_points_until_then"
                dataType="number"
                caption="Total points jusque-là"
                [allowFiltering]="false">
            </dxi-column>
            <dxi-column
                dataField="typeof"
                dataType="number"
                [filterValue]="1"
                [width]="0">
            </dxi-column>
            <dxi-column
                dataField="schoolclass"
                [calculateFilterExpression]="schoolclassFilterExpression"
                [width]="0">
            </dxi-column>
        </dx-data-grid>
    </div>
</div>