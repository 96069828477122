<div [formGroup]="getFinishedTasksForm"
     matTooltip="Charger les données"
     (ngChange)="refresh()">
     Masquer les tâches terminées :
    <mat-slide-toggle formControlName="finishedTasks"></mat-slide-toggle>
</div>

<mat-form-field *ngIf="filtering">
    <mat-label>Filtrer</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="">
</mat-form-field>

<table mat-table
       [dataSource]="dataSource"
       matSort
       class="mat-elevation-z8">

    <!-- Student Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.course_detail.date | date:'dd.MM.yyyy'}}</td>
      </ng-container>

    <!-- Student Column -->
    <ng-container matColumnDef="course">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cours </th>
        <td mat-cell *matCellDef="let element"> {{element.course_detail.name}} </td>
      </ng-container>

    <!-- Student Column -->
    <ng-container matColumnDef="student">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Elève </th>
      <td mat-cell *matCellDef="let element"> {{element.student_detail.username}} </td>
    </ng-container>
  
    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
      <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status==2; else finished">
                <span matTooltip="La tâche est en cours"
                      class="material-icons warning">
                    autorenew
                </span>
            </ng-container>
            <ng-template #finished>
                <span matTooltip="La tâche est terminée"
                      class="material-icons success">
                    check_circle_outline
                </span>
            </ng-template>
        </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element" style="width:20%;">
            <ng-container *ngIf="element.status==2">
                <span matTooltip="Passer la tâche à 'terminée'" 
                      class="material-icons action success"
                      (click)="finish(element.id)">
                    done_outline
                </span>
                <span matTooltip="Remettre la tâche au prochain cours"
                      class="material-icons action warning"
                      (click)="postpone(element.course, element.id)">
                    double_arrow
                    </span>
                <span matTooltip="Supprimer la tâche"
                      class="material-icons action danger"
                      (click)="delete(element.id)">
                    delete
                </span>
            </ng-container> 
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-template #noData>
      <div>Aucune tâche trouvée pour ce cours.</div>
  </ng-template>