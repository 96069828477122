<h2 mat-dialog-title>
    Titre du dialogue
</h2>

<mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Résultat">
            <div class="info">
                {{ data.message }}
            </div>
            <div class="info">
                Tu peux cliquer sur "Difficulté" pour indiquer si tu as trouvé l'exercice difficile ou non. Tu peux également laisser un message pour ton enseignant(e) si tu as une question ou a remarqué un bug dans l'exercice.
            </div>
        </mat-tab>
        <mat-tab label="Difficulté">
            <form [formGroup]="ratingForm">
                <div class="cont">
                  <div class="stars">
                    <form class="example-form">
                        <mat-form-field>
                          <mat-label>Difficulté</mat-label>
                          <input name="rating" type="number" min="1" max="5" matInput [(ngModel)]="rating" (change)="saveDifficulty($event)" onkeydown="return false">
                        </mat-form-field>
                    </form>
                  </div>
                  <div>Note la difficulté de l'exercice 
                      <br>
                      (1=très facile, 5=très difficile): {{ratingForm.value.rating}}
                  </div>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="Laisser un message pour l'enseignant(e)">
            <h3>
                Tu peux laisser un message pour ton enseignant(e) si tu as une question ou a remarqué un bug dans l'exercice.
            </h3>
            <form [formGroup]="messageForm">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Laisse un commentaire ici</mat-label>
                    <textarea matInput
                              formControlName="feedback"
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="4"
                              cdkAutosizeMaxRows="12"
                              placeholder="Le devoir était difficile parce que ..."
                              class="full-width"
                              cols="120">
                    </textarea>
                </mat-form-field>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button (click)="closeDialog()" color="primary">Fermer</button>
</mat-dialog-actions>