import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DxDataGridComponent } from 'devextreme-angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';
import { StepExercisesDialogComponent } from 'src/app/_dialogs/step-exercises-dialog/step-exercises-dialog.component';

@Component({
  selector: 'steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  @Input()
  public journeyId: number;

  public journeys: any[] = [];
  public nextSteps: any[] = [];
  public dataSource: any;

  constructor(private _dG: DatagridService,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadJourneys();
    this.loadNextSteps();
    // this.dataSource = this._dG.createStore(`api/dx-steps/?filter=["journey","=",${this.journeyId}]`,'data');
    this.dataSource = this._dG.createStore(`api/dx-steps/`,'data');
    this.checkStepsIntegrity();
  }

  openDialog(stepId: number): void {
    const dialogRef = this.dialog.open(StepExercisesDialogComponent, {
      width: '80%',
      data: {stepId:stepId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dataGrid.instance.refresh();
    });
  }

  checkStepsIntegrity(){
    // check here if there is exactly 1 'is_first_step' Step.
    this._apiService.getObjectsList(`api/dx-steps/?filter=[["journey","=",${this.journeyId}],"and",["is_first_step","=",true]]`).subscribe(
      data => {
        if(data['totalCount'] === 0){
          this._snackBar.open("Attention : cette étape n'a pas de point de départ. Veuillez en choisir un (case 'Est la 1ère étape').", "Fermer");
        } else if (data['totalCount'] > 1){
          this._snackBar.open("Attention : cette étape a plusieurs points de départ ! Cela risque de créer des problèmes.", "Fermer");
        }
      }
    )
  }

  setDefaultValues(event){
    event.data.journey = this.journeyId;
  }

  loadJourneys(){
    this._apiService.getObjectsList(`api/dx-journeys/`).subscribe(
      data => {
        this.journeys = data['data'];
      }
    )
  }

  loadNextSteps(){
    this._apiService.getObjectsList(`api/dx-steps/?filter=["journey","=",${this.journeyId}]`).subscribe(
      data => {
        this.nextSteps = data['data'];
      }
    )
  }
}
