<dx-data-grid
    id="competitorsGridContainer"
    [dataSource]="students"
    [remoteOperations]="true">
    <dxo-paging [pageSize]="10"> </dxo-paging>
    <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[5,10,20,]"
        [displayMode]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
    </dxo-pager>
    <dxo-filter-row
        [visible]="true">
    </dxo-filter-row>

    <dxi-column
        dataField="username"
        sortOrder="asc"
        dataType="string">
    </dxi-column>
    <dxi-column
        dataField="student_detail"
        dataType="string"
        cellTemplate="studentCellTemplate"
        [allowFiltering]="false"
        [allowSorting]="false">
    </dxi-column>
    <dxi-column
        dataField="id"
        [calculateFilterExpression]="calculateFilter"
        [filterValues]="schoolclassesIds"
        [width]="0">
    </dxi-column>

    <div *dxTemplate="let data of 'studentCellTemplate'">
        <button
            mat-raised-button
            color="accent"
            matTooltip="Vers la fiche de l'élève"
            matTooltipPosition="left"
            [routerLink]="['/schoolapp', 'student-detail',data.data.id]">
            <span class="material-icons">
                double_arrow
            </span>
        </button>
    </div>
</dx-data-grid>