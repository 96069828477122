<h1>Gestion des élèves de la classe</h1>

<p>
  Editez ci-dessous votre programme de cours. Vous pouvez ajouter ou supprimer des élèves concernés par ce programme de cours.
</p>

<h2 *ngIf="schoolClass">Programme de cours : {{ schoolClass.name }}</h2>

<p>
    <mat-form-field>
      <mat-select [formControl]="studentCtrl" placeholder="Elève" #singleSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="studentFilterCtrl"></ngx-mat-select-search>
        </mat-option>
  
        <mat-option *ngFor="let student of filteredStudents | async" [value]="student">
          {{student.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
  <p>
    <button mat-raised-button color="primary"
            (click)="addStudent(studentCtrl.value.id)"
            matTooltip="Ajouter l'élève au programme de cours">
        Ajouter
    </button>
  </p>

  <hr>

<h2>Liste des élèves de ce programme de cours</h2>

<mat-chip-list *ngIf="schoolClass" aria-label="Fish selection">
    <mat-chip *ngFor="let student of schoolClass.students_details" 
              color="accent"
              selected
              (removed)="remove(student.id)">
        {{student.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
</mat-chip-list>