import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../services/api-service/api.service';

@Component({
  selector: 'question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit {

  @Input()
  public questionDetailObj: any;

  @Input()
  public exerciseCorrection: any;

  @Input()
  public admin: boolean = false;

  @Output()
  public correctedQuestionDetailObj = new EventEmitter;

  @Output()
  public editChoices = new EventEmitter;

  public answerGiven: string;
  public isCorrected: boolean = false;
  public isCorrect: boolean = false;

  constructor(private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
  }

  /**
   * Check if answer given is correct or not.
   */
  correctAnswerGiven(){
    let answerGiven = '';
    this.isCorrected = true;

    if(this.answerGiven){
      answerGiven = this.answerGiven.trim().toLowerCase();
    }

    if(this.questionDetailObj.correction){
      this.questionDetailObj.correction = this.questionDetailObj.correction.trim().toLowerCase();
    }

    this.isCorrect = answerGiven == this.questionDetailObj.correction;

    let obj = {'question_detail_id':this.questionDetailObj.question_detail_id,
               'answer_given':this.answerGiven,
               'is_correct':this.isCorrect,}
    
    this.correctedQuestionDetailObj.emit(obj);

    const data = {
      "answer_given":this.answerGiven,
      "is_correct":this.isCorrect,
      "exercise":this.exerciseCorrection.exercise,
      "question":this.questionDetailObj.question_id,
      "question_detail":this.questionDetailObj.question_detail_id,
      "exercisecorrection":this.exerciseCorrection.id,
    }

    this._apiService.createObject(`api/dx-questiondetailcorrections/`, data).subscribe(
      data => {
        
      }
    )
  }

  /**
   * Save QuestionDetail correction in db.
   */
  save(){
    const data = {"correction":this.questionDetailObj.correction}

    this._apiService.patchObject(`api/dx-question-detail/${this.questionDetailObj.question_detail_id}/`,data).subscribe(
      data => {
        this._snackBar.open("Donnée enregistrée","Fermer");
      },
      err => this._snackBar.open("Une erreur est survenue","Fermer")
    )
  }

  edit(){
    this.editChoices.emit(this.questionDetailObj.question_detail_id);
  }
}
