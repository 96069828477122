import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-step-exercises-dialog',
  templateUrl: './step-exercises-dialog.component.html',
  styleUrls: ['./step-exercises-dialog.component.scss']
})
export class StepExercisesDialogComponent implements OnInit {

  public stepId: number;
  public step: any;
  public selectedExercises: any[] = [];

  constructor(public dialogRef: MatDialogRef<StepExercisesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,) { }

  ngOnInit(): void {
    this.stepId = this.data.stepId;
    this.loadStep();
  }

  addExercise(event){
    this._apiService.createObject(`api/steps/${this.stepId}/exercises/${event.id}/`,{}).subscribe(
      data => {
        this.step = data;
        this.selectedExercises = data['exercises_details'];
      }
    );
  }

  loadStep(){
    this._apiService.getObjectDetail(`api/dx-steps/${this.stepId}/`).subscribe(
      data => {
        this.step = data;
        this.selectedExercises = data['exercises_details'];
      }
    )
  }

  removeExercise(exerciseId){
    this._apiService.deleteObject(`api/steps/${this.stepId}/exercises/${exerciseId}/`).subscribe(
      data => {
        this.step = data;
        this.selectedExercises = data['exercises_details'];
      }
    );
  }
}
