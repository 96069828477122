import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExercisesListComponent } from 'src/app/exercises-list/exercises-list.component';

@Component({
  selector: 'app-exercise-dialog',
  templateUrl: './exercise-dialog.component.html',
  styleUrls: ['./exercise-dialog.component.scss']
})
export class ExerciseDialogComponent implements OnInit {

  public exercise: any = undefined;
  public exerciseId: number = undefined;

  constructor(public dialogRef: MatDialogRef<ExercisesListComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(isNaN(this.data)){
      this.exercise = this.data;
    } else {
      this.exerciseId = this.data;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
