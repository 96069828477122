import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'parent-detail',
  templateUrl: './parent-detail.component.html',
  styleUrls: ['./parent-detail.component.scss']
})
export class ParentDetailComponent implements OnInit {

  @Input()
  public parentId: number;

  public parent: any;

  public displayedColumns: any = [
    {name: 'type_of', dataBinding: 'type_of', title: 'Type', type: 'text',},
    {name: 'value', dataBinding: 'value', title: 'Valeur', type: 'text',},
  ]

  constructor(private activatedRoute: ActivatedRoute,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    let pk = this.activatedRoute.snapshot.params.pk;

    this.getParent(pk);
  }

  getParent(pk: number){
    this._apiService.getObjectDetail(`parents/${pk}`).subscribe(
      data => {
        this.parent = data;
        console.log('CE PARENT : ', this.parent);
      }
    )
  }

  refresh(){
    let pk = this.activatedRoute.snapshot.params.pk;

    this.getParent(pk);
  }
}
