<dx-chart *ngIf="teams" [dataSource]="chartsData" id="team-points-chart">
    <dxo-common-series-settings
        argumentField="created_at">
    </dxo-common-series-settings>
    <dxo-size
        [height]="500"
        [width]="1080">
    </dxo-size>
    <dxi-series
        *ngFor="let team of teams"
        [color]="team.color"
        type="line"
        [valueField]="team.name"
        [name]="team.name">
        <dxo-label
            [visible]="true"
            [backgroundColor]="team.color">
        </dxo-label>
    </dxi-series>
</dx-chart>