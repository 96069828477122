import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-exercise-feedback',
  templateUrl: './exercise-feedback.component.html',
  styleUrls: ['./exercise-feedback.component.scss']
})
export class ExerciseFeedbackComponent implements OnInit {

  public studentwork: any;
  public ratingForm: FormGroup;
  public rating: number = 1;
  public messageForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ExerciseFeedbackComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.studentwork = this.data['studentwork'];

    this.ratingForm = this.fb.group({
      rating: [this.studentwork.difficulty,],
    });

    this.messageForm = this.fb.group({
      feedback: [this.studentwork.message,],
    })
  }

  /**
   * Save Studentwork's (Exercise) difficulty in db for the teacher
   * @param event 
   */
  saveDifficulty(event?: any){
    // const difficulty = event['rating'];
    console.log(event);
    const difficulty = this.rating;
    const data = {"difficulty":difficulty};

    if(difficulty >= 3){
      this._apiService.createObject(`api/exercises-list/${this.studentwork.exercise.id}/`,{}).subscribe(
        data => {
          console.log(data);
        }
      )
    } else {
      this._apiService.deleteObject(`api/exercises-list/${this.studentwork.exercise.id}/`).subscribe(
        data => {
          console.log(data);
        }
      )
    }

    this._apiService.patchObject(`api/dx-studentworks/${this.studentwork.id}/`,data).subscribe(
      data => {
        this._snackBar.open("Difficulté enregistrée.","Fermer");
      }
    )
  }

  /**
   * Save Studentwork's (Exercise) difficulty in db for the teacher
   * @param event 
   */
  saveMessage(){
    const data = {"message":this.messageForm.get('feedback').value};

    this._apiService.patchObject(`api/dx-studentworks/${this.studentwork.id}/`,data).subscribe(
      data => {
        this._snackBar.open("Feedback enregistré.","Fermer");
      }
    )
  }

  /**
   * Close the dialog window
   */
  closeDialog(): void {
    this.saveMessage();
    this.dialogRef.close();
  }

}
