import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ExaminationsListComponent } from 'src/app/_datagrids/examinations-list/examinations-list.component';

@Component({
  selector: 'examinations',
  templateUrl: './examinations.component.html',
  styleUrls: ['./examinations.component.scss']
})
export class ExaminationsComponent implements OnInit {

  @ViewChild(ExaminationsListComponent)
  public examinationsList: ExaminationsListComponent;

  public exercisesIds: any[] = [];

  constructor(private _apiService: ApiService,
              private _snackbar: MatSnackBar,) { }

  ngOnInit(): void {
    this.loadExercisesInStorage();
  }

  loadExercisesInStorage(){
    this.exercisesIds = JSON.parse(localStorage.getItem('exercisesForExam'));
  }

  createExam(){
    const obj = {
      "name":"new exam",
      "exercises":this.exercisesIds
    }

    this._apiService.createObject(`/api/dx-exams/`,obj).subscribe({
      next: (data:any) => {
        this._snackbar.open("Examen créé","Fermer");
        this.examinationsList.loadExams();
        this.flushStorage();
      },
      error: (err:any) => {
        this._snackbar.open("Une erreur est survenue","Fermer");
      }
    })
  }
  
  flushStorage(){
    localStorage.setItem('exercisesForExam','[]');
    this._snackbar.open("La liste a été vidée");
    this.loadExercisesInStorage();
  }
}
