import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Models } from 'src/app/school/models/api-models';

@Component({
  selector: 'app-edit-object-dialog',
  templateUrl: './edit-object-dialog.component.html',
  styleUrls: ['./edit-object-dialog.component.scss']
})
export class EditObjectDialogComponent implements OnInit {

  public objectKeys = Object.keys;
  public objectForm: FormGroup;

  public endPoint: string;
  public pk: string;
  public elem: any;
  public fields: any;
  public model: any;
  public selectChoices: any = {};

  constructor(public dialogRef: MatDialogRef<EditObjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.endPoint = this.data['endPoint'];
    this.model    = new Models[this.data['modelName']]();

    if(this.model.getSelectChoices()){
      this.selectChoices = this.model.getSelectChoices();
    }
    
    this._apiService.getObjectDetail(this.endPoint).subscribe(
      data => {
        let keys      = Object.keys(this.model);

        for (let key of keys){
          if(data[key]){
            this.model[key]=data[key];
          }
        }
        this.objectForm = this.formBuilder.group(this.model);
      }
    )
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let jsonObj = {};
    let keys = Object.keys(this.objectForm.controls);

    for (let key of keys){
      if (key != 'id'){
        jsonObj[key] = this.objectForm.value[key];
      }
    }

    this._apiService.patchObject(this.endPoint, jsonObj).subscribe(
      data => {
        this.dialogRef.close({"message":"Données enregistrées"});
      },
      err => {
        this.dialogRef.close({"message":err.message});
      }
    );
  }
}
