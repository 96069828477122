import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-edit-reward-dialog',
  templateUrl: './edit-reward-dialog.component.html',
  styleUrls: ['./edit-reward-dialog.component.scss']
})
export class EditRewardDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditRewardDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  }

}
