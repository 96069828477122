import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DatagridService } from 'src/app/services/datagrid-service/datagrid.service';

@Component({
  selector: 'examinations-list',
  templateUrl: './examinations-list.component.html',
  styleUrls: ['./examinations-list.component.scss']
})
export class ExaminationsListComponent implements OnInit {

  public dataSource: any = {};

  constructor(private _dg: DatagridService,
              private _http: HttpClient,) { }

  ngOnInit(): void {
    this.loadExams();
  }

  loadExams(){
    this.dataSource = this._dg.createStore('/api/dx-exams/');
  }

  generateExamDocx(examId:string){
    // window.location.href=`api/generate-resume-docx/${this.resume.id}/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      'Access-Control-Allow-Origin': '*',
    });

    this._http.get(`api/generate-exam-docx/${examId}/`,{headers:headers, responseType:"blob" as "json"}).subscribe({
      next: (data:any) => {
        let a      = document.createElement("a");
        a.href     = URL.createObjectURL(data);
        a.download = "exam";
        a.click();
      }
    });    
  }

}
