import { Component, OnInit } from '@angular/core';
import { DatetimeService } from '../services/datetime.service';

@Component({
  selector: 'app-studentworks-home',
  templateUrl: './studentworks-home.component.html',
  styleUrls: ['./studentworks-home.component.scss']
})
export class StudentworksHomeComponent implements OnInit {

  public notStartedStudentworksFilter: string;
  public startedStudentworksFilter: string;
  public lastChanceStudentworksFilter: string;
  public finishedStudentworksFilter: string;
  public finishedTooLateStudentsworksFilter: string;

  public now: Date;
  public twoMonthAgo: Date;
  public nowToStr: string;
  public twoMonthAgoStr: string;

  constructor(private _datetimeService: DatetimeService,) { }

  ngOnInit(): void {
    this.now         = new Date();
    this.twoMonthAgo = new Date();
    this.twoMonthAgo.setDate(this.twoMonthAgo.getDate() - 60);

    this.nowToStr       = this._datetimeService.fromDateToStringUS(this.now);
    this.twoMonthAgoStr = this._datetimeService.fromDateToStringUS(this.twoMonthAgo);

    this.notStartedStudentworksFilter       = `?filter=[["status","=",0],"and",["due_date",">=","${this.nowToStr}"]]`;
    this.startedStudentworksFilter          = `?filter=[["status","=",1],"and",["due_date",">=","${this.nowToStr}"]]`;
    this.lastChanceStudentworksFilter       = `?filter=[[["status","=",0],"or",["status","=",1]],"and",["due_date","<","${this.nowToStr}"],"and",["due_date",">=","${this.twoMonthAgoStr}"]]`;
    this.finishedStudentworksFilter         = `?filter=["status","=",2]`;
    this.finishedTooLateStudentsworksFilter = `?filter=["status","=",3]`;
  }
}
