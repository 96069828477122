import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit {

  public dataSource: any = new MatTableDataSource();

  public modelName: string = "Student";

  public displayedColumns: any[] = [
    // {name: 'id', dataBinding: 'id', title: 'Id', type: 'text',},
    {name: 'name', dataBinding: 'name', title: 'Nom et Prénom', type: 'text',},
    // {name: 'first_name', dataBinding: 'first_name', title: 'Prénom', type: 'text',},
    // {name: 'last_name', dataBinding: 'last_name', title: 'Nom', type: 'text',},
    {name: 'birthdate', dataBinding: 'birthdate', title: 'Date de naissance', type: 'date',},
    // {name: 'address', dataBinding: 'address', title: 'Adresse', type: 'text',},
    {name: 'student-detail', dataBinding: 'id', title: 'Détail', type: 'r-link', callback: 'studentDetailUrl'},
    {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'updateDeleteUrl'},
  ]

  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
    this.refresh();
  }

  updateDeleteUrl(pk: string) {
    return '/api/schoolapp/students/'+pk;
  }

  refresh() {
    this._apiService.getObjectsList('/api/schoolapp/students/').subscribe(
      data => {
        this.dataSource.data = data;
      }
    )
  }

  studentDetailUrl(pk: number){
    return `/schoolapp/student-detail/${pk}`;
  }
}