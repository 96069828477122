<div>
    <h2>Liste de tes devoirs</h2>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Devoirs pas commencés</span>
              </ng-template>
            <studentworks-list
              [showYearSubject]="false"
                [status]="[0]"
                [startDueDate]="twoMonthAgo"
                [endDueDate]="null">
            </studentworks-list>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Devoirs commencés</span>
            </ng-template>
            <studentworks-list
                [showYearSubject]="false"
                [status]="[1]"
                [startDueDate]="twoMonthAgo"
                [endDueDate]="null">
            </studentworks-list>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Devoirs à faire rapidement : dernier délai</span>
            </ng-template>
            <studentworks-list
                [showYearSubject]="false"
                [status]="[0,1]"
                [startDueDate]="twoMonthAgo"
                [endDueDate]="now">
            </studentworks-list>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Devoirs terminés dans les temps</span>
            </ng-template>
            <studentworks-list
                [showYearSubject]="false"
                [status]="[2]"
                [startDueDate]="null"
                [endDueDate]="null">
            </studentworks-list>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tab-label">Devoirs terminés en retard</span>
            </ng-template>
            <studentworks-list
                [showYearSubject]="false"
                [status]="[3]"
                [startDueDate]="null"
                [endDueDate]="null">
            </studentworks-list>
        </mat-tab>
    </mat-tab-group>
</div>
