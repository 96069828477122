import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})
export class UrlComponent implements OnInit {

  @Input()
  public url: string;

  @Input()
  public text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
