<mat-card
    *ngIf="studentJourney"
    class="journey-container">
    <mat-card-header>
        <mat-card-title>
            {{studentJourney.name}}
        </mat-card-title>
        <mat-card-subtitle>
            Donné par {{studentJourney.teacher_details.username}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="studentSteps">
        <mat-stepper #stepper>
            <mat-step *ngFor="let studentStep of studentSteps; let i = index">
                <ng-template matStepLabel>
                    {{studentStep.name}}
                </ng-template>
                
                <div *ngIf="i === 0">
                    <ng-container
                        [ngTemplateOutlet]="proceed"
                        [ngTemplateOutletContext]="i">
                    </ng-container>
                </div>
                <div *ngIf="i > 0">
                    <ng-container *ngIf="studentSteps[i-1].status !== 2; else proceed">
                        Tu dois terminer l'étape précédente pour avoir accès à celle-ci.
                    </ng-container>
                </div>

                <ng-template #proceed let-number='i'>  
                    <ng-container [ngSwitch]="studentStep.status">
                        <ng-container *ngSwitchCase="0">
                            <button *ngIf="i==0"
                                mat-flat-button
                                color="primary"
                                [routerLink]="['/attempt',studentStep.id]">
                                    Commencer (1er step) status : {{studentStep.status}}
                            </button>
                            <ng-container *ngIf="i>0">
                                <button
                                    *ngIf="now>next_allowed_step; else waitForNextStep"
                                    mat-flat-button
                                    color="primary"
                                    [routerLink]="['/attempt',studentStep.id]">
                                        Continuer
                                </button>
                                <!--<button
                                    mat-flat-button
                                    color="primary"
                                    [routerLink]="['/attempt',studentStep.id]">
                                        Commencer
                                </button>-->
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <button
                                *ngIf="now>next_allowed_attempt; else waitForNewAttempt"
                                mat-flat-button
                                color="primary"
                                [routerLink]="['/attempt',studentStep.id]">
                                    Continuer
                            </button>
                            <!--<button
                                mat-flat-button
                                color="primary"
                                [routerLink]="['/attempt',studentStep.id]">
                                    Continuer
                            </button>-->
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <button
                                mat-flat-button
                                color="warn">
                                    Déjà terminé
                            </button>
                        </ng-container>
                    </ng-container>                  
                </ng-template>
            </mat-step>
        </mat-stepper>
      </mat-card-content>
</mat-card>

<ng-template #waitForNewAttempt>
    <ng-container *ngIf="attempts_diff">
        Tu dois encore attendre {{attempts_diff_str}} pour pouvoir refaire une tentative de cette étape.
    </ng-container>
</ng-template>

<ng-template #waitForNextStep>
    <ng-container *ngIf="attempts_diff">
        Tu dois encore attendre {{steps_diff_str}} pour pouvoir passer à cette étape.
    </ng-container>
</ng-template>
