import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Models } from 'src/app/school/models/api-models';

@Component({
  selector: 'app-show-object-detail-dialog',
  templateUrl: './show-object-detail-dialog.component.html',
  styleUrls: ['./show-object-detail-dialog.component.scss']
})
export class ShowObjectDetailDialogComponent implements OnInit {

  public objectKeys = Object.keys;
  public objectForm: FormGroup;

  public endPoint: string;
  public pk: string;
  public elem: any;
  public fields: any;
  public model: any;
  public loadIsComplete: boolean = false;

  constructor(public dialogRef: MatDialogRef<ShowObjectDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService) { }

  ngOnInit(): void {
    this.model    = new Models[this.data['modelName']](this.data['pk']);
    this.endPoint = this.model.getDetailEndPoint();

    this._apiService.getObjectDetail(this.endPoint).subscribe(
      data => {
        let keys      = Object.keys(this.model);

        for (let key of keys){
          if(data[key]){
            this.model[key]=data[key];
          }
        }
        this.loadIsComplete = true;
      }
    )
  }

  close(){
    this.dialogRef.close();
  }
}
