<div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Semaine de cours type</h1>
    <div [formGroup]="isLockedForm"
         matTooltip="Attention : si vous créez des cours sur une plage de temps où il en existe déjà, les anciens seront supprimés."
         (ngChange)="toggleLock()">
        Autoriser les modifications :
        <mat-slide-toggle formControlName="isLocked"></mat-slide-toggle>
    </div>
</div>


<br>

<p>
    Créez ci-dessous des cours pour une période donnée. Si vous sélectionnez une plage de temps où il existe déjà des cours, les anciens seront supprimés.
    Par défaut, cette page est verrouillée pour éviter les fausses manipulations. Déverrouillez-la au moyen du bouton en haut à droite.
</p>

<br>

<form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">

    <mat-form-field appearance="fill">
        <mat-label>Choisissez une date de début</mat-label>
        <input matInput
               [matDatepicker]="startDate" 
               formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Choisissez une date de fin</mat-label>
        <input matInput
               [matDatepicker]="endDate"
               formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <!-- <button mat-raised-button color="accent">Valider</button> -->

</form>

<table style="background-color: grey;">
    <thead>
        <th *ngFor="let day of daysOfWeek">
            {{ day }}
        </th>
    </thead>
    <tbody [formGroup]="coursesFormGroup" (ngSubmit)="onSubmit()">
        <tr *ngFor="let courseHour of nbrOfCourseHours; index as i">
            <td *ngFor="let day of daysOfWeek; index as j">
                <div *ngIf="courseHour==4; else normalCourse">
                    PAUSE
                </div>
                <ng-template #normalCourse>
                    <mat-select *ngIf="schoolClasses"
                                formControlName="day_{{j}}_course_{{i}}"
                                #controlName>
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let schoolClass of schoolClasses"
                                    [value]="j+'||'+i+'||'+schoolClass.id">
                                        {{ schoolClass.name }}
                        </mat-option>
                    </mat-select>
                </ng-template>
            </td>
        </tr>
    </tbody>
    <br>
    <button [disabled]="locked"
            mat-raised-button
            color="accent"
            (click)="onSubmit()">
        Créer mon planning
    </button>
</table>
