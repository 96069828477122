<div
    *ngIf="popupExercisesCorrections&&popupExercisesCorrections.length>0"
    width="100%"
    height="100%">
    <mat-tab-group>
        <mat-tab
            *ngFor="let correction of popupExercisesCorrections; let index = index"
            backgroundColor="blue"
            label="Tentative {{index+1}}">
                <exercise-correction
                    [exerciseCorrectionId]="correction">
                </exercise-correction>
        </mat-tab>
    </mat-tab-group>
</div>