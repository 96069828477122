<span *ngIf="showOverview"
      class="material-icons pointer show"
      matTooltip="afficher l'aperçu"
      matTooltipPosition="above"
      (click)="openShowDetailDialog()">
    remove_red_eye
</span>

<span class="material-icons pointer edit"
      matTooltip="édition rapide"
      matTooltipPosition="above"
      (click)="openEditDialog()">
    edit
</span>

<span class="material-icons pointer delete"
      matTooltip="supprimer"
      matTooltipPosition="above"
      (click)="openDeleteDialog()">
    delete
</span>