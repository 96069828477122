<h1>Administration du contenu (classes, liens, exercices, parcours)</h1>

<div fxLayout="row"
     fxLayoutAlign="space-between stretch"
     fxLayoutGap="10px">
    <div *ngFor="let link of adminContentLinks"
         routerLink="{{link.url}}"
         class="pointer link"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center">
            <mat-icon [matTooltip]="link.tooltip">{{link.icon}}</mat-icon>
    </div>
</div>

<br>
<br>

<h1>Administration des tâches (devoirs, progressions de parcours)</h1>

<div fxLayout="row"
     fxLayoutAlign="space-between stretch"
     fxLayoutGap="10px">
    <div *ngFor="let link of adminWorksLinks"
         routerLink="{{link.url}}"
         class="pointer link"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center">
            <mat-icon [matTooltip]="link.tooltip">{{link.icon}}</mat-icon>
    </div>
</div>

<h1>Administration des cours</h1>

<div fxLayout="row"
     fxLayoutAlign="space-between stretch"
     fxLayoutGap="10px">
    <div *ngFor="let link of coursesLinks"
         routerLink="{{link.url}}"
         class="pointer link"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center">
            <mat-icon [matTooltip]="link.tooltip">{{link.icon}}</mat-icon>
    </div>
</div>