export namespace Models {
    /**
     * Model representing a Student
     */
    export class Student {
      constructor(
        private id?: number,
        public name: string = '',
        public birthdate: string = '2005-01-01',
        public special_measures: string = ''
      ) { }

      /**
       * Method returning the fields required to create
       * a new instance of a Student object.
       */
      public createEditFields(){
        return ['name',]
      }
    
      /**
       * Method returning the api endpoint for :
       * - students list (GET)
       * - students creation (POST)
       */
      public getListEndPoint(){
        return '/api/schoolapp/students/';
      }
  
      /**
       * Method returning the api endpoint for :
       * - students detail (GET)
       * - students editing (PATCH/PUT)
       * - students deleting (DELETE)
       */
      public getDetailEndPoint(){
        return `/api/schoolapp/students/${this.id}`
      }

      /**
       * Method returning a type of html field,
       * depending of the object attribute given.
       * @param attr attribute of Student obj
       */
      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
            break;
          }
          case 'birthdate': {
            return 'date';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }

      public getSelectChoices(){
        return [];
      }
    }

    /**
     * Same documentation as object above
     */
    export class CourseProgram {
      constructor(
        private id?: number,
        public name: string = '',
        public short_name: string = '',
        public description: string = '',
        public color: string = ''
      ) { }

      public createEditFields(){
        return ['name','short_name','description','color',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/courses-programs/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/courses-programs/${this.id}`;
      }

      public getSelectChoices(){
        return [];
      }

      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }
    }

    /**
     * Same documentation as object above
     */
    export class Parent {
      constructor(
        private id?: number,
        public name: string = '',
        // public first_name: string = '',
        // public last_name: string = '',
        // public birthdate: string = '2005-01-01',
        // public special_measures: string = ''
        // public address: string = ''
      ) { }

      public createEditFields(){
        return ['name',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/parents/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/parents/${this.id}`
      }

      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
            break;
          }
          case 'birthdate': {
            return 'date';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }

      public getSelectChoices(){
        return [];
      }
    }

    /**
     * Same documentation as object above
     */
    export class CommunicationMedium {
      constructor(
        private id?: number,
        public type_of: string = '',
        public value: string = '',
        public parent_id?: number
      ) { }

      public createEditFields(){
        return ['value','parent_id',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/communication-mediums/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/communication-mediums/${this.id}`;
      }

      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
            break;
          }
          case 'birthdate': {
            return 'date';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }
    }

    /**
     * Same documentation as object above
     */
    export class Commentary {
      constructor(
        private id?: number,
        public content: string = ''
      ) { }

      public createEditFields(){
        return ['content',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/commentaries/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/commentaries/${this.id}`;
      }

      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
            break;
          }
          case 'birthdate': {
            return 'date';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }
    }

    /**
     * Same documentation as object above
     */
    export class Note {
      constructor(
        private id?: number,
        public name: string = '',
        public content: string = ''
      ) { }

      public createEditFields(){
        return ['name','content',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/notes/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/notes/${this.id}`;
      }

      public getAttributeType(attr){
        switch(attr){
          case 'content': {
            return 'rich-text';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }
    }

    /**
     * Same documentation as object above
     */
    export class Exam {
      constructor(
        private id?: number,
        public name: string = '',
        public type_of: string = ''
      ) { }

      public createEditFields(){
        return ['name','type_of',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/exams/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/exams/${this.id}`;
      }

      public getAttributeType(attr){
        switch(attr){
          case 'type_of': {
            return 'select';
            break;
          }
          default: {
            return 'text';
            break;
          }
        }
      }

      /**
       * Method returning a list of choices for
       * fields that require one (here, only 'type_of'
       * field). These fields will be used to generate
       * select options.
       */
      public getSelectChoices(){
        return {
          'type_of': [
            ['short_exam','Contrôle de leçon',],
            ['oral','Oral',],
            ['dictation','Dictée',],
            ['vocabulary','Vocabulaire',],
          ]
        }
      }
    }

    /**
     * Same documentation as object above
     */
    export class Score {
      constructor(
        private id?: number,
        public value: number = 0,
        // public student: string = ''
      ) { }

      public createEditFields(){
        return ['value',]
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/scores/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/scores/${this.id}`;
      }

      public getAttributeType(attr){
        return 'text';
      }
    }

    /**
     * Same documentation as object above
     */
    export class CompetitorReward {
      constructor(
        public color: string,
        public size: string,
        public used: string,
        private id?: number,
      ) { }

      public createEditFields(){
        return ['color','size','used',];
      }
    
      public getListEndPoint(){
        return '/api/schoolapp/competitor-rewards/';
      }
  
      public getDetailEndPoint(){
        return `/api/schoolapp/competitor-rewards/${this.id}`;
      }

      public getAttributeType(attr){
        switch(attr){
          case 'color': {
            return 'color';
          }
          case 'used': {
            return 'select';
          }
          case 'size': {
            return 'select';
          }
          default: {
            return 'text';
          }
        }
      }

      /**
       * Method returning a list of choices for
       * fields that require one (here, only 'type_of'
       * field). These fields will be used to generate
       * select options.
       */
      public getSelectChoices(){
        return {
          'used': [
            ['oui','oui',],
            ['non','non',],
          ],
          'size': [
            ['10','small',],
            ['20','medium',],
            ['30','big',],
          ],
        }
      }
    }
  }