import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditYearsubjectDialogComponent } from '../edit-yearsubject-dialog/edit-yearsubject-dialog.component';

@Component({
  selector: 'app-edit-link-dialog',
  templateUrl: './edit-link-dialog.component.html',
  styleUrls: ['./edit-link-dialog.component.scss']
})
export class EditLinkDialogComponent implements OnInit {

  public editForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditYearsubjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.editForm = new FormGroup({
      'linkCtrl': new FormControl(this.data.url)
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    this.dialogRef.close(this.editForm.get('linkCtrl').value);
  }
}
