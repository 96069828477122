<h1>Gestion des liens</h1>

<form [formGroup]="form">
    <mat-card>
        <mat-form-field appearance="outline" *ngIf="yearSubjects">
            <mat-label>Branche / Année</mat-label>
            <mat-select formControlName="yearSubjectCtrl"
                        (selectionChange)="onYearSubjectChange($event)">
                <mat-option *ngFor="let yearSubject of yearSubjects"
                            [value]="yearSubject.id">{{yearSubject.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="themes">
            <mat-label>Thème</mat-label>
            <mat-select formControlName="themeCtrl"
                        (selectionChange)="onThemeChange($event)">
                <mat-option *ngFor="let theme of themes"
                            [value]="theme.id">{{theme.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="newLinkCategories">
            <mat-label>Catégorie</mat-label>
            <mat-select formControlName="newLinkCategoryCtrl"
                        (selectionChange)="onNewLinkCategoryChange($event)">
                <mat-option *ngFor="let category of linkCategories"
                            [value]="category.id">{{category.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card>
</form>
<br><br>
<mat-card *ngIf="links">
    <mat-list role="list">
        <mat-list-item
            *ngFor="let link of links"
            role="listitem">
                <a href="{{link.url}}" target=_blank>
                    {{link.url}}
                </a>
        </mat-list-item>
    </mat-list>
</mat-card>