import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../../services/user-service/user.service';


@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {

  public user: any;
  public url: string;
  public isTeacher: boolean = false;

  constructor(private router: Router,
              public _userService: UserService) { }

  ngOnInit() {
    this.user = {
      username: '',
      password: ''
    };

    this.url = this.router.url;
    this._userService.refreshToken();
    this.isTeacher = this._userService.isTeacher();
  }

  login() {
    this._userService.login({'username': this.user.username, 'password': this.user.password});
  }
 
  refreshToken() {
    this._userService.refreshToken();
  }
 
  logout() {
    this._userService.logout();
  }
}