<div *ngIf="dataSource" class="content-block">
    <dx-data-grid 
        #gridContainer2
        id="gridContainer2"
        [dataSource]="dataSource"
        [showBorders]="true"
        [remoteOperations]="true"
        dateSerializationFormat="yyyy-MM-dd"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [rowAlternationEnabled]="false"
        (onInitNewRow)="setDefaultValues($event)"
        (onRowInserted)="checkStepsIntegrity()"
        (onRowInserted)="loadNextSteps()"
        (onRowUpdated)="checkStepsIntegrity()"
        (onRowUpdated)="loadNextSteps()"
        (onRowRemoved)="checkStepsIntegrity()"
        (onRowRemoved)="loadNextSteps()">
        <!--<dxo-header-filter 
        [visible]="true"></dxo-header-filter>-->
        <!--<dxo-paging [pageSize]="10"></dxo-paging>-->
        <dxo-pager 
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[5, 10, 20]"
            [showInfo]="true"
            [visible]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing
            [allowUpdating]="true"
            [allowAdding]="true"
            [allowDeleting]="true"
            mode="row"> <!-- 'row' | 'batch' | 'cell' | 'form' | 'popup' -->
        </dxo-editing>
        <!--<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>-->
        <dxi-column
            dataField="name"
            dataType="string"
            caption="Nom"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column
            dataField="is_first_step"
            dataType="boolean"
            caption="Est la 1ère étape"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
        </dxi-column>
        <dxi-column
            dataField="next_step"
            dataType="number"
            caption="Prochaine étape"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
            <dxo-lookup
                [dataSource]="nextSteps"
                displayExpr="name"
                valueExpr="id"
                [allowClearing]="true">
            </dxo-lookup>
        </dxi-column>
        <dxi-column
            dataField="description"
            dataType="string"
            caption="Description"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
        </dxi-column>
        <dxi-column 
            dataField="journey"
            dataType="number"
            caption="Parcours"
            [allowHeaderFiltering]="false"
            [allowFiltering]="true"
            [filterValue]="journeyId">
            <dxo-lookup
                [dataSource]="journeys"
                displayExpr="name"
                valueExpr="id">
            </dxo-lookup>
        </dxi-column>
        <dxi-column 
            dataField="min_correct_count_to_complete"
            dataType="number"
            caption="Nbre min d'exercices juste"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
        </dxi-column>
        <dxi-column 
            dataField="min_percentage"
            dataType="number"
            caption="% min de juste"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false">
        </dxi-column>
        <dxi-column 
            dataField="exercices"
            dataType="number"
            caption="Exercices"
            alignment="center"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="exercisesTemplate">
        </dxi-column>
        <dxi-column
            dataField="exercises_integrity_check"
            dataType="boolean"
            caption="Est valide"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="integrityCellTemplate">
        </dxi-column>
        
        <div *dxTemplate="let data of 'exercisesTemplate'">
            <mat-icon aria-hidden="false"
                      aria-label="Edit exercises"
                      class="pointer"
                      (click)="openDialog(data.data.id)">
                edit
            </mat-icon>
            &nbsp;&nbsp;
            <span matBadge="{{data.data.exercises_count}}"></span>
        </div>

        <div *dxTemplate="let data of 'integrityCellTemplate'">
            <mat-icon
                *ngIf="data.data.exercises_integrity_check; else integrityFail"
                aria-hidden="false"
                aria-label="Step's integrity"
                matTooltip="Cette étape est valide. Le 'nombre min d'exercices justes' à faire pour la compléter est plus petit ou égal que le nombre d'exercices.">
                    check_circle_outline
            </mat-icon>

            <ng-template #integrityFail>
                <mat-icon
                    aria-hidden="false"
                    aria-label="Step's integrity"
                    matTooltip="Cette étape n'est pas valide. Le 'nombre min d'exercices justes' à faire pour la compléter est plus grand que le nombre d'exercices.">
                        highlight_off
                </mat-icon>
            </ng-template>
        </div>
    </dx-data-grid>
</div>
