import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateObjectDialogComponent } from 'src/app/school/_dialogs/create-object-dialog/create-object-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'crud-table',
  templateUrl: './crud-table.component.html',
  styleUrls: ['./crud-table.component.scss']
})
export class CrudTableComponent implements OnInit {

  @Input()
  public dataSource: any; // source of data used by MatTable - array of objects

  @Input()
  public displayedColumns: any[]; // list of displayed columns in MatTable
  /**
   * // Exemple of displayedColumns attribute :
   * [
   *  {name: 'id', dataBinding: 'id', title: 'Id', type: 'text',},
   *  {name: 'first_name', dataBinding: 'first_name', title: 'Prénom', type: 'text',},
   *  {name: 'user', dataBinding: 'user.username', title: 'Utilisateur', type: 'text',},
   *  {name: 'birthdate', dataBinding: 'birthdate', title: 'Date de naissance', type: 'text',},
   *  {name: 'address', dataBinding: 'address', title: 'Adresse', type: 'text',},
   *  {name: 'update-delete', dataBinding: 'id', title: 'Actions', type: 'update-delete', callback: 'exampleCallback'},
   * ]
   * 
   * // Data types actually supported :
   * 'text'          : the most simple data type
   * 'url'           : external url
   * 'r-link'        : internal url
   * 'update-delete' : update and delete actions
   * 
   * // Exemple of callback method for parent component. Will render and endPoint API url here
   * exampleCallback(pk: string) {
   *    return 'students/'+pk;
   * }
   */
  @Input()
  public showFilter: boolean = true; // manage filter display

  @Input()
  public showCreateButton: boolean = true; // manage create button display

  @Input()
  public parentComponent: any; // parent object component, used to call parent methods

  @Input()
  public modelName: string; // model name of displayed data

  @Input()
  public additionalData: any; // additional data necessary to create an object

  @ViewChild(MatSort, {static: true})
  public sort: MatSort; // MatTable attribute used to sort data in MatTable

  public columnsNames: string[]; // empty array of column names

  constructor(private dialog: MatDialog,) { }

  ngOnInit(): void {
    // Extract columns names from displayColumns
    this.columnsNames = this.displayedColumns.map(column => column.name);

    // Add children objects to the filtering
    this.dataSource.filterPredicate = (data: any, filter) => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    }

    // Add children objects to the sorting
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    this.dataSource.sort = this.sort;
  }

  /**
   * Apply filter on dataSource attribute
   * @param event 
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Uses lodash magic power. Nearly the same as getattr() python native method.
   * Get object's property's value.
   * @param obj 
   * @param property 
   */
  getProperty(obj: object, property: string){
    return _.get(obj, property);
  }


  /**
   * Open generic dialog for creating new object.
   */
  openCreateDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '60%';
    dialogConfig.data = {'modelName': this.modelName, 'additionalData': this.additionalData}

    let dialogRef = this.dialog.open(CreateObjectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.parentComponent.refresh();
    });
  }
}
