import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StepExercisesDialogComponent } from 'src/app/_dialogs/step-exercises-dialog/step-exercises-dialog.component';

@Component({
  selector: 'app-openai-exercise-form',
  templateUrl: './openai-exercise-form.component.html',
  styleUrls: ['./openai-exercise-form.component.scss']
})
export class OpenaiExerciseFormComponent implements OnInit {

  public openaiForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<StepExercisesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.openaiForm = new FormGroup({
      prompt: new FormControl('', Validators.required),
      typeof: new FormControl('', Validators.required)
    });
  }

  sendPrompt() {
    this.dialogRef.close(this.openaiForm.value);
    // You can add code here to submit the form data to a server or do something else with it
  }
}
