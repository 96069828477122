import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'add-sanction-dialog',
  templateUrl: './add-sanction-dialog.component.html',
  styleUrls: ['./add-sanction-dialog.component.scss']
})
export class AddSanctionDialogComponent implements OnInit {

  public days: any = {
    'Monday':'Lundi',
    'Tuesday':'Mardi',
    'Wednesday':'Mercredi',
    'Thursday':'Jeudi',
    'Friday':'Vendredi'
  }

  public coursePk: number;
  public course: any;
  public nextCourses: any[] = [];
  public courseCtrl: FormControl = new FormControl();
  public commentCtrl: FormControl = new FormControl();
  public students: any[];

  // for mat-select feature
  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public allStudents: string[] = [];

  public studentCtrl: FormControl = new FormControl();
  public studentFilterCtrl: FormControl = new FormControl();
  public filteredStudents: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<AddSanctionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _apiService: ApiService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.coursePk = this.data;

    this.getCourse(this.coursePk);
    this.getNextCourses(this.coursePk);
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCourse(coursePk: number){
    this._apiService.getObjectDetail(`/api/schoolapp/courses/${coursePk}/`).subscribe(
      data => {
        this.course = data;
        this.students = data['schoolclass']['students_details'];
        this.initMatSelect();
      }
    )
  }

  getNextCourses(coursePk: number){
    this._apiService.getObjectsList(`/api/schoolapp/courses/?n=${coursePk}`).subscribe(
      data => {
        for(let i=0; i<data.length; i++){
          if(i>0){
            if(data[i]['date']!=data[i-1]['date']){
              this.nextCourses.push(data[i]);
            }
          } else {
            this.nextCourses.push(data[i]);
          }
        }
      }
    )
  }

  save(){
    let courseId  = this.courseCtrl.value;
    let studentId = this.studentCtrl.value;
    let comment   = this.commentCtrl.value;
    
    this._apiService.createObject(`/api/schoolapp/sanctions/`,
                                  {"comment":comment,"status":2,"student":studentId,"course":courseId}).subscribe(
                                    data => {
                                      this.dialogRef.close();
                                      this._snackBar.open('La punition a été ajoutée.', 'Fermer');
                                    },
                                    error => this._snackBar.open('Une erreur est survenue. Avez-vous bien renseigné les 3 champs ? (élève, cours, commentaire)', 'Fermer')
                                  )
  }

  protected setInitialValue() {
    this.filteredStudents
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterStudents() {
    if (!this.students) {
      return;
    }
    // get the search keyword
    let search = this.studentFilterCtrl.value;
    if (!search) {
      this.filteredStudents.next(this.students.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStudents.next(
      this.students.filter(student => {
        const fullName = student.first_name + student.last_name;
        return fullName.toLowerCase().indexOf(search) > -1
      })
    );
  }

  initMatSelect(){
    this.studentCtrl.setValue(this.students[0]);

    // load the initial bank list
    this.filteredStudents.next(this.students.slice());

    // listen for search field value changes
    this.studentFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStudents();
      });
  }
}
