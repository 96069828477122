<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1>Liste des cours</h1>
    
    <p>
        <button mat-raised-button (click)="previousWeek()"><</button>
        <button mat-raised-button (click)="resetWeek()">today</button>
        <button mat-raised-button (click)="nextWeek()">></button>
    </p>
</div>

<table>
    <thead>
        <th *ngFor="let day of daysOfWeek; index as i">
            {{ frenchDays[i] }} <br> {{ day }}
        </th>
    </thead>
    <tbody>
        <tr *ngFor="let courseHour of nbrOfCourseHours; index as i">
            <ng-container *ngFor="let day of daysOfWeek; index as j">
                <td *ngIf="courseHour==4; else normalCourse" [ngStyle]="{'background-color':'beige'}">
                    PAUSE
                </td>
                <ng-template #normalCourse>
                    <ng-container *ngIf="courses[j][courseHour]; else emptyCourse">
                        <td class="course"
                             [ngStyle]="{'background-color':courses[j][i].schoolclass_detail.color}"
                             routerLink="/schoolapp/edit-course/{{courses[j][i].id}}">
                            {{courses[j][i].schoolclass_detail.name}}
                        </td>
                    </ng-container>
                    <ng-template #emptyCourse>
                        <td></td>
                    </ng-template>
                </ng-template>
            </ng-container>
        </tr>
        <tr>
            <td class="no-border"></td>
            <td class="no-border"></td>
            <td class="no-border">Rendez-vous</td>
            <td class="no-border"></td>
            <td class="no-border"></td>
        </tr>
        <tr>
            <td *ngFor="let day of daysOfWeek; index as j" class="meetings">
                <mat-card class="meeting-card">
                    <mat-card-content>
                        <meetings-list [courseDate]="day"></meetings-list>
                    </mat-card-content>
                  </mat-card>
            </td>
        </tr>
    </tbody>
</table>
