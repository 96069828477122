import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';

@Component({
  selector: 'upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})
export class UploadDataComponent implements OnInit {

  public fileToUpload: File = null;
  public previewData: any;
  public numberOfColumns: number;
  constructor(private _apiService: ApiService) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // console.log(this.fileToUpload);
    this._apiService.postFileForPreview(this.fileToUpload).subscribe(data => {
        this.previewData = data.data;
        this.numberOfColumns = data.data[0].length;
      }, error => {
        console.log(error);
      });
  }
}
