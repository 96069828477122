<h2>A propos de teraro.ch</h2>

<mat-card>
<h3>Historique</h3>
Ce site a été créé par Térèse Arona, avec l'aide de son mari. Il a pour unique vocation de favoriser l'apprentissage des élèves du Secondaire dans diverses branches, principalement le français, l'allemand et l'anglais.
<br/><br/>
<ul>
    <li>La première version du site, réalisée en 2008 avec le logiciel <a href="http://fr.wikipedia.org/wiki/RapidWeaver" class="text-danger" target="_blank">Rapidweaver</a>, a permis de mettre en ligne des séries de liens vers d'autres sites d'enseignement.</li>
    <li>La deuxième version, créée en 2011, proposait déjà des exercices de type questions-réponses dans le "coin des élèves".</li>
    <li>La troisième version, créée en 2013, a été conçue avec <a href="https://laravel.com/">Laravel 4</a> et maintenue péniblement durant 2 ans.</li>
    <li>La quatrième version a été conçue avec la version 5.1 de <a href="https://laravel.com/">Laravel</a> (LTS), et a permis de maintenir le site durant plusieurs années.</li>
    <li>Début 2019, le code a dû être réorganisé une nouvelle fois, pour gagner en performances. Cela a marqué le passage à <a href="https://www.djangoproject.com/">Django</a>, qui évolue plus lentement que <a href="https://laravel.com/">Laravel</a>.</li>
    <li>Durant l'été 2021, la partie visible (frontend) du site a été entièrement refaite, au moyen du framework <a href="https://angular.io">Angular</a>. Cette partie est hébergée sur le domaine teraro.net et plus teraro.ch.</li>
</ul>

<br/>

<h3>Technologies utilisées</h3>
Le site a été développé au moyen de technologies pour la plupart gratuites.
<br/><br/>
<ul>
    <li>Le <a href="http://fr.wikipedia.org/wiki/Framework" class="text-danger" target="_blank">framework</a> <a href="http://fr.wikipedia.org/wiki/PHP" class="text-danger" target="_blank">PHP</a> <a href="http://fr.wikipedia.org/wiki/Laravel" class="text-danger" target="_blank">Laravel</a> a d'abord été utilisé pour le "moteur" du site, puis a été remplacé par le framework <a href="https://fr.wikipedia.org/wiki/Python_(langage)" target=_blank>Python</a> <a href="https://fr.wikipedia.org/wiki/Django_(framework)" target=_blank>Django</a>.</li>
    <li>Les données sont stockées dans une <a href="http://fr.wikipedia.org/wiki/Base_de_donn%C3%A9es" class="text-danger" target="_blank">base de données</a> <a href="https://fr.wikipedia.org/wiki/PostgreSQL" class="text-danger" target="_blank">PostgreSQL</a>.</li>
    <li>Le dynamisme côté utilisateur est assuré par <a href="http://fr.wikipedia.org/wiki/JavaScript" class="text-danger" target="_blank">Javascript</a> et son framework <a href="https://fr.wikipedia.org/wiki/Angular#:~:text=Angular%20(commun%C3%A9ment%20appel%C3%A9%20%22Angular%202,de%20particuliers%20et%20de%20soci%C3%A9t%C3%A9s." class="text-danger" target="_blank">Angular</a>.</li>
</ul>

<br/>

<h3>L'avenir</h3>
Nous avons prévu d'ajouter de nouvelles fonctionnalités dans le futur, notamment d'autres types d'exercices et qui sait, selon les suggestions des utilisateurs...
</mat-card>