<div *ngIf="dataSource" class="content-block">
    <h1>
        Liste des devoirs donnés
    </h1>
    <button 
        mat-flat-button
        [routerLink]="['/admin/create-homework']">
            Créer un devoir
    </button>
    &nbsp;&nbsp;&nbsp;
    <button
        mat-flat-button
        [routerLink]="['/admin/homeworks-summary']">
            Imprimer un résumé
    </button>
    &nbsp;&nbsp;&nbsp;
    <button
        mat-flat-button
        [routerLink]="['/admin/examinations']">
            Vers les examens
    </button>
    <br><br>
    <dx-data-grid 
        #gridContainer
        id="gridContainer"
        [dataSource]="dataSource"
        [showBorders]="true"
        [remoteOperations]="true"
        dateSerializationFormat="yyyy-MM-dd"
        [showColumnLines]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [rowAlternationEnabled]="false"
        (onRowRemoved)="refreshDataSource($event)">
        <dxo-header-filter 
        [visible]="true"></dxo-header-filter>
        <dxo-editing
            [allowUpdating]="false"
            [allowAdding]="false"
            [allowDeleting]="true"
            mode="row"> <!-- 'row' | 'batch' | 'cell' | 'form' | 'popup' -->
        </dxo-editing>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager 
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[5, 10, 20]"
            [showInfo]="true"
            [visible]="true">
        </dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>

        <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

        <dxi-column
            dataField="schoolclass"
            dataType="number"
            caption="Classe / Groupe"
            [allowHeaderFiltering]="true"
            [allowFiltering]="false"
            [calculateFilterExpression]="schoolclassesFilterExpression"
            cellTemplate="schoolclassTemplate">
            <dxo-header-filter [dataSource]="schoolclasses"></dxo-header-filter>
        </dxi-column>
        <dxi-column
            dataField="exercise_name"
            dataType="string"
            caption="Exercice"
            [allowHeaderFiltering]="false">
        </dxi-column>
        <dxi-column
            dataField="yearsubject_name"
            dataType="string"
            caption="Branche/Année">
        </dxi-column>
        <dxi-column
            dataField="theme_name"
            caption="Theme">
        </dxi-column>
        <dxi-column
            dataField="due_date"
            dataType="date"
            format="dd.MM.yyyy"
            caption="Echéance"
            [allowHeaderFiltering]="false">
        </dxi-column>
        <dxi-column
            dataField="id"
            name="edit question"
            dataType="number"
            caption="Actions"
            [allowHeaderFiltering]="false"
            cellTemplate="editQuestionTemplate">
        </dxi-column>
        <dxi-column
            dataField="finished_studentworks"
            dataType="number"
            caption="Terminés"
            [allowHeaderFiltering]="false"
            cellTemplate="finishedStudentworksTemplate">
        </dxi-column>
        <!--<dxi-column 
            dataField="id"
            dataType="number"
            caption="Actions"
            [allowHeaderFiltering]="false"
            [allowFiltering]="false"
            cellTemplate="actionsTemplate">
        </dxi-column>-->
        
        <div *dxTemplate="let data of 'dueDateTemplate'">
            {{ datetimeService.fromTimestampToDate(data.data.due_date) }}
        </div>

        <div *dxTemplate="let data of 'editQuestionTemplate'">
            <mat-icon
                class="pointer"
                aria-hidden="false"
                aria-label="Vers les questions"
                matTooltip="Éditer l'exercice"
                [routerLink]="['/admin','questions',data.data.exercise.id]">
                    edit
            </mat-icon>
            <mat-icon
                class="pointer"
                aria-hidden="false"
                aria-label="Reprendre pour un examen"
                matTooltip="Reprendre pour un examen"
                (click)="takeExerciseForExam(data.data.exercise.id)">
                    add
            </mat-icon>
        </div>

        <div *dxTemplate="let data of 'schoolclassTemplate'">
            {{data.data.schoolclass.name}}
        </div>

        <div *dxTemplate="let data of 'actionsTemplate'">
            <mat-icon aria-hidden="false"
                      aria-label="Example home icon"
                      class="pointer"
                      (click)="deleteHomework(data.data.id)">delete</mat-icon>
        </div>

        <div *dxTemplate="let data of 'detail'">
            <studentworks [homeworkId]="data.data.id"></studentworks>
        </div>

        <div *dxTemplate="let data of 'exerciseCellTemplate'">
            {{data.data.exercise.name}}
        </div>

        <div *dxTemplate="let data of 'finishedStudentworksTemplate'">
            <mat-progress-bar
                mode="determinate"
                [value]="data.data.finished_studentworks"
                color="accent"
                [matTooltip]="data.data.finished_studentworks + '% des élèves ont terminé ce devoir.'">
            </mat-progress-bar>
        </div>
    </dx-data-grid>
</div>
