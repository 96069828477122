<mat-tab-group>
    <mat-tab label="Gérer les points">
        <team-points-list (onPointsModified)="refreshPointsGraph($event)" [team]="selectedTeam" [schoolclass]="schoolclass"></team-points-list>
    </mat-tab>

    <mat-tab label="Voir la stat">
        <teams-points-chart [schoolclass]="schoolclass"></teams-points-chart>
    </mat-tab>

    <mat-tab label="Gérer les équipes"> 
        <div class="datagrid-content">
        <dx-data-grid
            [dataSource]="competitorsTeams"
            [remoteOperations]="true"
            (onEditorPreparing)="onEditorPreparing($event)"
            (onRowInserting)="insertRow($event)"
            (onRowInserted)="reloadCompetitorsAndLists($event)"
            (onRowUpdating)="updateRow($event)"
            (onRowUpdated)="reloadCompetitorsAndLists($event)"
            (onRowRemoved)="removedRow($event)">
            <dxo-editing
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true">
            </dxo-editing>

            <dxi-column
                dataField="name">
            </dxi-column>
            <dxi-column
                dataField="color"
                caption="Couleur">
            </dxi-column>
            <dxi-column
                dataField="schoolclass"
                cellTemplate="schoolclassTemplate"
                [filterValue]="schoolclass.id"
                [calculateFilterExpression]="calculateFilterExpression"
                [allowEditing]="false">
            </dxi-column>

            <div *dxTemplate="let data of 'schoolclassTemplate'">
                <ng-container *ngIf="data.data&&data.data.schoolclass">
                    {{data.data.schoolclass_details.name}}
                </ng-container>
            </div>
        </dx-data-grid>
        </div>
    </mat-tab>
    
    <mat-tab label="Gérer les membres de chaque équipe">
        <ng-container *ngIf="competitorsTeamsList">
            <div class="row">
                <div>
                    <strong>Dans aucune équipe</strong>
                    <dx-list
                        id="team0"
                        [dataSource]="noTeamCompetitors"
                        [repaintChangesOnly]="true"
                        [elementAttr]="{ class: 'list-column teams-management' }"
                        keyExpr="id"
                        displayExpr="user_detail.username">
                        <dxo-item-dragging
                            group="competitors"
                            [data]="noTeamCompetitors"
                            [allowReordering]="true"
                            [onDragStart]="onDragStart"
                            [onAdd]="onAdd"
                            [onRemove]="onRemove">
                        </dxo-item-dragging>
                    </dx-list>
                </div>
                <div *ngFor="let team of competitorsTeamsList">
                    <strong>{{team.name}}</strong>
                    <dx-list
                        [id]="'team'+team.id"
                        [dataSource]="team.competitors_details"
                        [repaintChangesOnly]="true"
                        [elementAttr]="{ class: 'list-column teams-management' }"
                        keyExpr="id"
                        displayExpr="username">
                        <dxo-item-dragging
                            group="competitors"
                            [data]="team.competitors"
                            [allowReordering]="true"
                            [onDragStart]="onDragStart"
                            [onAdd]="onAdd"
                            [onRemove]="onRemove">
                        </dxo-item-dragging>
                    </dx-list>
                </div>
            </div>
        </ng-container>
    </mat-tab>
</mat-tab-group>






<!--<dx-popup
    width="80%"
    height="80vh"
    [showTitle]="true"
    [title]="teamPointsTitle"
    [dragEnabled]="false"
    [showCloseButton]="true"
    [(visible)]="teamPopupVisible">

    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view
            #scrollView
            id="scrollview"
            [showScrollbar]="true">
            <team-points-list [team]="selectedTeam" [schoolclass]="schoolclass"></team-points-list>
        </dx-scroll-view>
    </div>
</dx-popup>-->