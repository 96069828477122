import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
 
@Injectable()
export class UserService {
 
  // http options used for making API calls
  private httpOptions: any;
 
  // the actual JWT token
  public access_token: string;
  public refresh_token: string;
 
  // the token expiration date
  public token_expires: Date;
 
  // the username of the logged in user
  public username: string;
 
  // error messages received from the login attempt
  public errors: any = [];
 
  constructor(private http: HttpClient,
              private _router: Router,
              private _snackBar: MatSnackBar,
              public jwtHelper: JwtHelperService) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json',
                                'method': 'POST'})
    };
    setInterval(()=> { this.refreshToken() }, 30000);
  }
 
  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public login(user) {
    this.http.post('/api/api-token-auth/', JSON.stringify(user), this.httpOptions).subscribe(
      data => {
        console.log('LOGIN', data);
        this.updateData(data['access'], data['refresh']);
        this._snackBar.open("Vous êtes connecté(e).","Fermer");
        this._router.navigate(['/']);
      },
      err => {
        this.errors = err['error'];
        this._snackBar.open("Le login ou le mot de passe est incorrect.","Fermer");
      }
    );
  }
 
  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    // if(localStorage.getItem('token')){
    if(this.isAuthenticated){
      this.http.post('/api/api-token-refresh/', JSON.stringify({refresh: localStorage.getItem('refresh')}), this.httpOptions).subscribe(
        data => {
          this.updateData(data['access'], '');
        },
        err => {
          this.errors = err['error'];
          this._router.navigate(['/login']);
        }
      );
    }
  }
 
  public logout() {
    this.access_token = null;
    this.token_expires = null;
    this.username = null;
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  }
 
  private updateData(access_token, refresh_token) {
    this.access_token = access_token;
    this.errors = [];
 
    const access_token_decoded = this.jwtHelper.decodeToken(access_token);

    this.username = access_token_decoded.username;
    
    const refresh_token_decoded = this.jwtHelper.decodeToken(refresh_token);

    // let target = access_token_decoded ? access_token_decoded : refresh_token_decoded;
    this.token_expires = new Date(access_token_decoded.exp * 1000);

    if(access_token) {
      localStorage.setItem('access', access_token);
    }

    if(refresh_token) {
      localStorage.setItem('refresh', refresh_token);
    }
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public isTeacher(): boolean {
    const token = localStorage.getItem('access');
    // Check is_teacher token's property
    return this.jwtHelper.decodeToken(token).is_teacher;
  }
}